import TextWrapper from "@/shared/components/TextWrapper";
import DivWithAnimation from "../common/DivWithAnimation";

const Pricing01 = ({
  content,
  editablePath
 }: any) => {
  const plans = content.plans;
  if (!plans || plans.length < 2) return null;
 
  // 첫 번째 플랜의 features에서 목록명만 추출
  const featureNames = plans[0].features.map((feature: any) => feature.name);
 
  return (
    <>
    <style>{`
      @keyframes backgroundChange {
        0%, 100% { background-color: rgb(255 255 255 / 0.05); }
        50% { background-color: rgb(100 200 255 / 0.15); }
      }
      .animate-bg {
        animation: backgroundChange 1.5s infinite ease-in-out;
      }
    `}</style>

    <div className="w-full max-w-5xl mx-auto">
      <DivWithAnimation>
        {/* 모바일 뷰 */}
        <div className="md:hidden">
          <div className="flex gap-4 relative">
            {/* 배경색 컬럼 */}
            <div className="absolute inset-0 flex">
              {plans.map((plan: any, index: number) => (
                <div 
                  key={`bg-${plan.id}`}
                  className={`flex-1 ${index === 0 ? 'animate-bg' : ''}`}
                />
              ))}
            </div>
  
            {/* 실제 헤더 콘텐츠 */}
            {plans.map((plan: any) => (
              <div key={plan.id} className="flex-1 relative">
                <div className="text-center p-4 pb-6">
                  <h3 className="text-white mb-2 text-xl font-semibold">{plan.name}</h3>
                  <div className="text-blue-500">
                    <span className="text-lg font-semibold">{plan.monthlyPrice.toLocaleString()}</span>
                  </div>
                </div>
              </div>
            ))}
          </div>
  
          {/* 모바일 feature 목록 */}
          <div className="border-t border-white/20 relative">
            {/* 배경색 컬럼 */}
            <div className="absolute inset-0 flex">
              {plans.map((plan: any, index: number) => (
                <div 
                  key={`bg-${plan.id}`}
                  className={`flex-1 ${index === 0 ? 'animate-bg' : ''}`}
                />
              ))}
            </div>
  
            {featureNames.map((name: any, idx: any) => (
              <div key={idx} className="py-4 border-b border-white/20 relative">
                <div className="text-white/60 font-light mb-2 px-4">{name}</div>
                <div className="flex gap-4">
                  {plans.map((plan: any) => (
                    <div key={plan.id} className="flex-1 text-white px-4 text-center">
                      {typeof plan.features[idx].value === 'boolean' 
                        ? (plan.features[idx].value ? '✓' : '✗')
                        : plan.features[idx].value}
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>
        </div>
  
        {/* PC 뷰 */}
        <div className="hidden md:block">
          {/* 헤더 부분 */}
          <div className="flex relative">
            {/* 배경색 컬럼 - 헤더용 */}
            <div className="absolute inset-0 flex">
              <div className="w-1/3" />
              {plans.map((plan: any, index: number) => (
                <div 
                  key={`bg-header-${plan.id}`}
                  className={`flex-1 ${index === 0 ? 'animate-bg' : ''}`}
                />
              ))}
            </div>

            <div className="w-1/3" />
            {plans.map((plan: any) => (
              <div key={plan.id} className="w-1/3 text-center relative py-6">
                <h3 className="text-white text-2xl font-semibold mb-2">{plan.name}</h3>
                <div className="text-blue-500 mb-1">
                  <span className="text-xl font-semibold">{plan.monthlyPrice.toLocaleString()}</span>
                </div>
              </div>
            ))}
          </div>
  
          {/* PC feature 테이블 */}
          <div className="border-t border-white/20 relative text-lg">
            {/* 배경색 컬럼 */}
            <div className="absolute inset-0 flex">
              <div className="w-1/3" />
              {plans.map((plan: any, index: number) => (
                <div 
                  key={`bg-${plan.id}`}
                  className={`flex-1 ${index === 0 ? 'animate-bg' : ''}`}
                />
              ))}
            </div>
  
            {/* 실제 콘텐츠 */}
            {featureNames.map((name: any, idx: any) => (
              <div key={idx} className="flex py-8 border-b border-white/20 relative">
                <div className="w-1/3 px-4 text-white/60">{name}</div>
                {plans.map((plan: any) => (
                  <div key={plan.id} className="w-1/3 text-center text-white px-4">
                    {typeof plan.features[idx].value === 'boolean' 
                      ? (plan.features[idx].value ? '✓' : '✗')
                      : plan.features[idx].value}
                  </div>
                ))}
              </div>
            ))}
          </div>
        </div>
      </DivWithAnimation>

      <TextWrapper
        content={content.caution}
        className= 'mt-8'
        editable={editablePath ? {
          type: 'richText',
          path: `${editablePath}.header.subtitle`
        } : undefined}
      />
    </div>
    </>
  );
 };
 
 export default Pricing01;