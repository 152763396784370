import ImageWrapper from "@/shared/components/ImageWrapper";
import TextWrapper from "@/shared/components/TextWrapper";
import { useGroupAnimation } from "@/shared/hooks/animation/useGroupAnimation";


const Hero03 = ({ 
  content,
  images,
  buttons,
  editablePath
}: any) => {
  const groupId = useGroupAnimation(50);

  return (
    <section className="h-screen relative flex items-center justify-center">
      <ImageWrapper
        src={images.background.src}
        styles={images.background.styles}
        alt="hero"
        className="!absolute inset-0 w-full"
        loading="eager"
        editable={editablePath ? {
          type: 'image',
          path: `${editablePath}.images.background`
        } : undefined}
        animation="none"
      />

      <div className="relative flex flex-col items-center gap-8 md:gap-9 px-4">
        <TextWrapper
          content={content.title}
          editable={editablePath ? {
            type: 'richText',
            path: `${editablePath}.header.title`
          } : undefined}
          animation={{
            type: 'slideUp',
            groupId: groupId,
            duration: 1000,
          }}
        />
        <TextWrapper
          content={content.description}
          editable={editablePath ? {
            type: 'richText',
            path: `${editablePath}.header.title`
          } : undefined}
          animation={{
            type: 'slideUp',
            groupId: groupId,
          }}
        />

        <div className="mt-2 md:mt-4 flex flex-col md:flex-row gap-5 md:gap-6 w-full md:w-auto">
          <a
          href={buttons[0].link}
          className="px-6 py-3 min-w-52 bg-red-600 text-white rounded-md hover:bg-red-900 transition-colors w-full md:w-auto text-center">
            <TextWrapper
              content={buttons[0].text}
              editable={editablePath ? {
                type: 'plainText',
                path: `${editablePath}.header.title`
              } : undefined}
              animation="none"
            />
          </a>
          <a
          href={buttons[1].link}
          className="px-6 py-3 min-w-52 border border-white text-white rounded-md hover:bg-gray-800 transition-colors w-full md:w-auto text-center">
            <TextWrapper
              content={buttons[1].text}
              editable={editablePath ? {
                type: 'plainText',
                path: `${editablePath}.header.title`
              } : undefined}
              animation="none"
            />
          </a>
        </div>
      </div>
    </section>
  );
 };
 
 export default Hero03;