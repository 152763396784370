import { RefObject, useEffect, useState } from 'react';

export const useIntersectionObserver = (
  elementRef: RefObject<HTMLDivElement>,
  threshold = 0
): [boolean] => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const calculateRootMargin = (threshold: number): string => {
      if (threshold === 0) return '0px';

      const viewportHeight = window.innerHeight;
      
      if (threshold > 0) {
        // 양수: 요소로부터 아래로 N vh만큼 지났을 때
        const bottomMargin = threshold * viewportHeight;
        return `0px 0px ${-bottomMargin}px 0px`;
      } else {
        // 음수: 요소로부터 위로 |N| vh만큼 이전에
        // rootMargin을 viewport 높이의 절대값만큼 아래로 확장
        const margin = Math.abs(threshold) * viewportHeight;
        return `-${margin}px 0px ${margin}px 0px`;
      }
    };

    const updateObserver = () => {
      const observer = new IntersectionObserver(
        ([entry]) => {
          if (entry.isIntersecting) {
            setIsVisible(true);
            observer.disconnect();
          }
        },
        { 
          threshold: 0,
          rootMargin: calculateRootMargin(threshold)
        }
      );

      if (elementRef.current) {
        observer.observe(elementRef.current);
      }

      return observer;
    };

    let observer = updateObserver();

    const handleResize = () => {
      observer.disconnect();
      observer = updateObserver();
    };

    window.addEventListener('resize', handleResize);

    return () => {
      observer.disconnect();
      window.removeEventListener('resize', handleResize);
    };
  }, [elementRef, threshold]);

  return [isVisible];
};

// import { RefObject, useEffect, useState } from 'react';

// export const useIntersectionObserver = (
//   elementRef: RefObject<HTMLDivElement>,
//   threshold = 0
// ): [boolean] => {
//   const [isVisible, setIsVisible] = useState(false);

//   useEffect(() => {
//     const calculateRootMargin = (threshold: number): string => {
//       // threshold가 0인 경우 기본 동작 (요소가 화면에 보이는 순간)
//       if (threshold === 0) return '0px';

//       const viewportHeight = window.innerHeight;
      
//       if (threshold > 0) {
//         // 양수: 요소로부터 아래로 N vh만큼 지났을 때
//         const bottomMargin = threshold * viewportHeight;
//         return `0px 0px ${-bottomMargin}px 0px`;
//       } else {
//         // 음수: 요소로부터 위로 N vh만큼 이전에
//         const topMargin = -threshold * viewportHeight;
//         return `${-topMargin}px 0px 0px 0px`;
//       }
//     };

//     const updateObserver = () => {
//       const observer = new IntersectionObserver(
//         ([entry]) => {
//           if (entry.isIntersecting) {
//             setIsVisible(true);
//             observer.disconnect();
//           }
//         },
//         { 
//           threshold: 0,
//           rootMargin: calculateRootMargin(threshold)
//         }
//       );

//       if (elementRef.current) {
//         observer.observe(elementRef.current);
//       }

//       return observer;
//     };

//     // 초기 옵저버 설정
//     let observer = updateObserver();

//     // viewport 크기 변경시 재계산
//     const handleResize = () => {
//       observer.disconnect();
//       observer = updateObserver();
//     };

//     window.addEventListener('resize', handleResize);

//     return () => {
//       observer.disconnect();
//       window.removeEventListener('resize', handleResize);
//     };
//   }, [elementRef, threshold]);

//   return [isVisible];
// };