import TextWrapper from '@/shared/components/TextWrapper';
import { useState, useEffect } from 'react';

export interface TextProps {
  data: string[];
  className?: string;
 }

const TextAnimation = ({ data, className = '' }: TextProps) => {
 const [isVisible, setIsVisible] = useState(false);

 useEffect(() => {
   const timer = setTimeout(() => setIsVisible(true), 500);
   return () => clearTimeout(timer);
 }, []);

 const renderAnimatedText = (text: string, baseDelay: number) => (
   <div className="overflow-hidden">
     {text.split('').map((char, idx) => (
       <span
         key={idx}
         className="inline-block transition-transform duration-700 !leading-normal"
         style={{
           transform: isVisible ? 'translateY(0)' : 'translateY(100%)',
           transitionDelay: `${baseDelay + idx * 50}ms`,
         }}
       >
         {char === ' ' ? '\u00A0' : char}
       </span>
     ))}
   </div>
 );

 return (
   <div className={className}>
     {data.map((text, idx) => (
       <div
         key={idx}
         className={`
          ${idx === 0
            ? 'text-3xl md:text-5xl'
            : 'text-[40px] md:text-7xl'}
           font-medium overflow-hidden tracking-tight h-[1.4em] md:h-[1.5em]
         `}
       >
         {renderAnimatedText(text, idx * 500)}
       </div>
     ))}
   </div>
 );
};

export default TextAnimation;