import ImageWrapper from "@/shared/components/ImageWrapper";
import TextWrapper from "@/shared/components/TextWrapper";
import { useGroupAnimation } from "@/shared/hooks/animation/useGroupAnimation";

 

const Bubble01 = ({
  content,
  images,
  editablePath
 }: any) => {

  const groupId = useGroupAnimation(100);


  return (
    <section className="h-screen relative flex items-center justify-center">
      <ImageWrapper
        src={images.items[0].src}
        styles={images.items[0].styles}
        alt="bubble image"
        className="!absolute w-[300px] md:w-[600px] aspect-[1/1] !rounded-full object-cover top-[70%] md:top-[60%] left-[50%] md:left-[65%] overflow-hidden shadow-custom-xl"
        loading="lazy"
        editable={editablePath ? {
          type: 'image',
          path: `${editablePath}.images.items[0]`
        } : undefined}
        animation={{
          type: 'ripple',
          groupId: groupId,
          duration: 1000,
          threshold: -0.5
        }}
      />

      <ImageWrapper
        src={images.items[1].src}
        styles={images.items[1].styles}
        alt="bubble image"
        className="!absolute w-[200px] md:w-[350px] aspect-[1/1] !rounded-full object-cover top-[1%] left-[10%] overflow-hidden shadow-custom-xl"
        loading="lazy"
        editable={editablePath ? {
          type: 'image',
          path: `${editablePath}.images.items[1]`
        } : undefined}
        animation={{
          type: 'ripple',
          groupId: groupId,
          duration: 1000,
        }}
      />

      <ImageWrapper
        src={images.items[2].src}
        styles={images.items[2].styles}
        alt="bubble image"
        className="!absolute w-[100px] md:w-[200px] aspect-[1/1] !rounded-full object-cover top-[70%] left-[5%] overflow-hidden shadow-custom-xl"
        loading="lazy"
        editable={editablePath ? {
          type: 'image',
          path: `${editablePath}.images.items[2]`
        } : undefined}
        animation={{
          type: 'ripple',
          groupId: groupId,
          duration: 1000,
        }}
      />
 
      <div className="relative flex flex-col items-center gap-6 text-center px-4">
        <TextWrapper
          content={content.title}
          className="!text-shadow-lg"
          editable={editablePath ? {
            type: 'richText',
            path: `${editablePath}.content.title`
          } : undefined}
          animation={{
            type: 'slideUp',
            groupId: groupId,
            duration: 1500,
          }}
        />

        <TextWrapper
          content={content.description}
          className="!text-shadow-base"
          editable={editablePath ? {
            type: 'richText',
            path: `${editablePath}.content.description`
          } : undefined}
          animation={{
            type: 'slideUp',
            groupId: groupId,
            duration: 1500,
          }}
        />
      </div>
    </section>
  );
 };
 
 export default Bubble01;