import ImageWrapper from "../../../shared/components/ImageWrapper";
import { ImageAsset } from "@/shared/types/common";

export interface SectionContainerProps {
  children: React.ReactNode;
  className?: string;
  backgroundImage?: ImageAsset;
  containerSize?: 'sm' | 'md' | 'lg' | 'xl' | 'full' | 'none';
  containerClassName?: string;
  editablePath?: string;
}

export const containerSizes = {
  sm: 'max-w-screen-sm',
  md: 'max-w-screen-md',
  lg: 'max-w-screen-lg',
  xl: 'max-w-screen-xl',
  full: 'w-full',
  none: ''
};

const SectionContainer = ({
  children,
  className,
  backgroundImage,
  containerSize = 'xl',
  containerClassName,
  editablePath
}: SectionContainerProps) => {
  const containerStyles = containerSize !== 'none' 
    ? `${containerSizes[containerSize]} mx-auto px-4 md:px-8 py-16 md:py-24 ${containerClassName || ''}`
    : `${containerClassName} mx-auto py-16 md:py-24`;
  

  return (
    <section className={`relative whitespace-pre-line ${backgroundImage || `bg-slate-50/50`} ${className || ''}`}>
      {backgroundImage && (
        <ImageWrapper
          src={backgroundImage.src}
          alt="introduction"
          className="!absolute inset-0 w-full"
          loading="lazy"
          styles={backgroundImage.styles}
          editable={editablePath ? {
            type: 'image',
            path: `${editablePath}.images.background`
          } : undefined}
          animation="none"
        />
      )}
      <div className={`relative ${containerStyles}`}>
        {children}
      </div>
    </section>
);
};

export default SectionContainer;