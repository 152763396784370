import TextWrapper from "@/shared/components/TextWrapper";

interface SummaryProps {
  header: {
    title: string;
    subtitle: string;
  }
  button: {
    text: string;
    link?: string;
  },
  editablePath: string;
}
 

const Summary01 = ({ 
  header,
  button,
  editablePath
 }: SummaryProps) => {
  return (
      <div className='flex flex-col md:flex-row gap-10 items-center md:justify-between whitespace-pre-line'>
        <div
          className=
          'text-center md:text-left'
        >
          <TextWrapper
            content={header.title}
            editable={editablePath ? {
              type: 'richText',
              path: `${editablePath}.header.title`
            } : undefined}
          />

          <TextWrapper
            content={header.subtitle}
            className= 'mt-4'
            editable={editablePath ? {
              type: 'richText',
              path: `${editablePath}.header.subtitle`
            } : undefined}
          />
        </div>

        <a
          href="/contact"
          className='px-8 py-2 text-lg text-white font-light bg-gray-200/20 cursor-pointer'
        >
          <TextWrapper
            content={button.text}
            editable={editablePath ? {
              type: 'plainText',
              path: `${editablePath}.button.text`
            } : undefined}
            animation="none"
          />
        </a>
      </div>
  );
 };
 
 export default Summary01;