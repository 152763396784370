import { useGroupAnimation } from "@/shared/hooks/animation/useGroupAnimation";
import DivWithAnimation from "../../common/DivWithAnimation";

interface HistoryItem {
  year: number;
  events: string[];
}
 
interface HistoryProps {
  content: {
    items: HistoryItem[];
  };
}

export interface HistoryContent {
  items: HistoryItem[];
}



const HistoryList01 = ({ content }: HistoryProps) => {
  const { items } = content;
  const groupId = useGroupAnimation(100);

  return (
    <div className={`px-0 pl-2 md:p-15 w-full [&_ul]:list-none [&_li]:list-none`}>
      <li className={`pl-5 border-gray-200 border-l-2`}>&nbsp;</li>
      {items.map(({ year, events }) => (
        <ul key={year} className={`pl-5 border-gray-300 border-l-2`}>
          <li className={`text-blue-800 text-xl font-medium leading-none relative before:content-[''] 
            before:block before:p-[5px] before:border-2 before:rounded-full 
            before:bg-white before:absolute before:-left-7 before:top-1/2 
            before:-translate-y-1/2 before:border-blue-400`}
          >
            {year}
          </li>
          <ul>
            {events.map((event, index) => (
              <DivWithAnimation groupId={groupId}>
                <li 
                  key={index}
                  className={`py-1 text-sm md:text-base leading-tight tracking-tight text-gray-700
                    ${index === 0 ? 'pt-4' : ''} 
                    ${index === events.length - 1 ? 'pb-8' : ''}`}
                >
                  {event}
                </li>
              </DivWithAnimation>
            ))}
          </ul>
        </ul>
      ))}
      <li className={`pl-5 border-gray-200 border-l-2`}>&nbsp;</li>
    </div>
  );
};

export default HistoryList01;