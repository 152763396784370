
import { useTemplateStore } from '@/shared/stores/templateStore';
import SectionContainer from '@/templates/components/common/SectionContainer';
import SectionHeader from '@/templates/components/common/SectionHeader';
import Features01 from '@/templates/components/features/Features01';
import Hero01 from '@/templates/components/hero/Hero01';
import Introduction01 from '@/templates/components/introduction/Introduction01';
import Summary01 from '@/templates/components/summary/Summary01';


const AboutHome = () => {
  const templateData = useTemplateStore(state => state.templateData);


  return (
    <div>
      <Hero01
        {...(templateData.pages.about.home?.hero)}
        editablePath="pages.about.home.hero"
      />
      
      <SectionContainer>
        <SectionHeader
          {...(templateData.pages.about.home?.introduction)}
          editablePath="pages.about.home.introduction"
        />
        <Introduction01
          {...(templateData.pages.about.home?.introduction)}
          editablePath="pages.about.home.introduction"
        />
      </SectionContainer>

      <SectionContainer
        backgroundImage={templateData.pages.about.home?.summary?.images?.background}
        editablePath="pages.about.home.summary"
      >
        <Summary01
          {...(templateData.pages.about.home?.summary)}
          editablePath="pages.about.home.summary"
        />
      </SectionContainer>

      <SectionContainer>
        <SectionHeader
          {...(templateData.pages.about.home?.features)}
          editablePath="pages.about.home.features"
          styles='border-none !pb-0'
        />
        <Features01
          {...(templateData.pages.about.home?.features)}
          editablePath="pages.about.home.features"
        />
      </SectionContainer>
    </div>
  );
};

export default AboutHome;