import ImageWrapper from "@/shared/components/ImageWrapper";
import TextWrapper from "@/shared/components/TextWrapper";
import { ImageAsset } from "@/shared/types/common";

export interface HeroBannerProps {
  header: {
    title: string;
  }
  images: {
    background: ImageAsset;
  };
}

const Hero01 = ({ 
  header,
  images,
  editablePath
}: any) => {
  return (
    <section className="h-screen relative flex items-center justify-center">
      <ImageWrapper
        src={images.background.src}
        styles={images.background.styles}
        alt="hero"
        className="!absolute inset-0 w-full"
        loading="eager"
        editable={editablePath ? {
          type: 'image',
          path: `${editablePath}.images.background`
        } : undefined}
        animation="none"
      />

      <div className="relative flex flex-col items-center gap-8 md:gap-9 px-4">
        <TextWrapper
          content={header.title}
          editable={editablePath ? {
            type: 'richText',
            path: `${editablePath}.header.title`
          } : undefined}
        />
      </div>
    </section>
  );
 };
 
 export default Hero01;