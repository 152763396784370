import { usePageStore } from '@/shared/stores/pageStore';
import { useTemplateStore } from '@/shared/stores/templateStore';
import ImageWrapper from "@/shared/components/ImageWrapper";
import TextWrapper from "@/shared/components/TextWrapper";
import { PathDisplay } from '@/shared/utils/findCurrentPath';


interface PageBannerProps {
  header: {
    title: string;
    subtitle: string;
  }
  images?: any;
  editablePath?: string;
}

// !!! Caution !!!
// set '.banner-gradient' in index.css

const PageBanner = ({ 
  header,
  images,
  editablePath
}: PageBannerProps) => {
  const currentPage = usePageStore((state) => state.currentPage);
  const menu = useTemplateStore(state => state.templateData.essential.navigation.menu);


  return(
    <div className='relative h-[300px] md:h-[360px]'>
        <ImageWrapper
          src={images.background.src}
          styles={images.background.styles}
          alt="introduction"
          className="!absolute inset-0 w-full"
          loading="eager"
          editable={editablePath ? {
            type: 'image',
            path: `${editablePath}.images.background`
          } : undefined}
          animation="none"
        />
      
      <div className="absolute top-1/2 -translate-y-1/2 w-full">
        <div className="max-w-screen-xl mx-auto  px-4 md:px-8">
          <TextWrapper
            content={header.subtitle}
            editable={editablePath ? {
              type: 'richText',
              path: `${editablePath}.header.subtitle`
            } : undefined}
            animation="none"
          />

          <TextWrapper
            content={header.title}
            className= 'mt-2'
            editable={editablePath ? {
              type: 'richText',
              path: `${editablePath}.header.title`
            } : undefined}
            animation="none"
          />
        </div>
      </div>

        <div className="absolute bottom-0 w-full h-9 md:h-12 flex items-center banner-gradient">
          <p className="max-w-screen-xl mx-auto w-full px-4 md:px-8 text-xs md:text-base text-right text-slate-200/90">
            <PathDisplay menu={menu} currentPage={currentPage} />
          </p>
        </div>
    </div>
  )
};

export default PageBanner;