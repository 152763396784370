import { LucideIcon } from 'lucide-react';
import { Phone, MessageCircle, Youtube, Instagram, MapPin } from 'lucide-react';
import { EDITABLE_HOVER_CLASSES } from "@/shared/constants/styles";
import { usePageStore } from '@/shared/stores/pageStore';
import { useGroupAnimation } from '@/shared/hooks/animation/useGroupAnimation';
import DivWithAnimation from '../common/DivWithAnimation';

const ContactButton = ({ icon: Icon, label, value, color }: any) => {
  if (!value) return null;  // value가 없으면 버튼을 렌더링하지 않음

  return (
    <a
      href={value}
      target="_blank"
      rel="noopener noreferrer"
      className={`
        w-full p-4 flex items-center justify-between
        ${color} text-white rounded-lg
        shadow-lg transition-transform duration-200
        hover:scale-[1.02] hover:shadow-xl
      `}
    >
      <div className="flex items-center gap-4">
        <Icon size={24} className="shrink-0" />
        <span className="text-lg font-medium">{label}</span>
      </div>
      <svg
        className="w-5 h-5"
        fill="none"
        stroke="currentColor"
        viewBox="0 0 24 24"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M9 5l7 7-7 7"
        />
      </svg>
    </a>
  );
};

const CONTACT_BUTTONS = [
  {
    type: 'kakao',
    label: '카카오톡 상담',
    icon: MessageCircle,
    color: 'bg-yellow-400 hover:bg-yellow-500'
  },
  {
    type: 'instagram',
    label: '인스타그램',
    icon: Instagram,
    color: 'bg-pink-500 hover:bg-pink-600'
  },
  {
    type: 'youtube',
    label: '유튜브 보러가기',
    icon: Youtube,
    color: 'bg-red-500 hover:bg-red-600'
  },
  {
    type: 'phone',
    label: '전화하기',
    icon: Phone,
    color: 'bg-green-500 hover:bg-green-600'
  },
  {
    type: 'naver',
    label: '네이버 플레이스',
    icon: MapPin,
    color: 'bg-emerald-500 hover:bg-emerald-600'
  }
] as const;

const generateId = () => {
  return `contact_${Math.random().toString(36).substr(2, 9)}`;
};

const Contact02 = ({
  contacts,
  editablePath
}: any) => {
  const setSelectedComponent = usePageStore(state => state.setSelectedComponent);
  const handleContactsClick = () => {
    if (editablePath) {
      const editorConfig: any = {
        type: 'contact',
        path: `${editablePath}.contacts`,
        content: contacts
      };
  
      setSelectedComponent({
        id: generateId(),
        ...editorConfig
      });
    }
  };

  const groupId = useGroupAnimation(100);


  return (
    <div className="max-w-2xl mx-auto">

      <div 
        className={`space-y-4 ${editablePath ? EDITABLE_HOVER_CLASSES : ''}`}
        onClick={handleContactsClick}
      >
        {CONTACT_BUTTONS.map((button) => (
          <DivWithAnimation groupId={groupId}>
            <ContactButton
              key={button.type}
              icon={button.icon}
              label={button.label}
              value={contacts[button.type]}
              color={button.color}
            />
          </DivWithAnimation>
        ))}
      </div>
    </div>
  );
};

export default Contact02;