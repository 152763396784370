interface GoogleMapProps {
  src: string;
  height?: string;
  editablePath?: string;
  className?: string;
}
 
const GoogleMap = ({
  src,
  height= "600px",
  editablePath,
  className
}: GoogleMapProps) => (
  <iframe
    src={src}
    style={{ height }}
    className={`w-full ${className || ''}`}
    allowFullScreen
    loading="lazy"
    referrerPolicy="no-referrer-when-downgrade"
  />
);

export default GoogleMap;