import { DEFAULT_ANIMATION, EDITABLE_HOVER_CLASSES } from "@/shared/constants/styles";
import { useEditableComponent } from "@/shared/hooks/useEditableComponent";
import { ImageWrapperProps } from "@/shared/types/common";
import { Edit } from "lucide-react";
import { useAnimation } from "../hooks/animation/useAnimation";


const ImageWrapper = ({ 
  src, 
  alt = '',
  className = '',
  loading = 'lazy',
  styles,
  editable,
  animation = DEFAULT_ANIMATION, // 기본값 설정
}: ImageWrapperProps) => {
  const defaultStyles = {
    objectFit: 'cover' as const,
    position: { x: 50, y: 50 },
    borderRadius: 0,
    height: 'auto'
  };
 
  const mergedStyles = {
    ...defaultStyles,
    ...styles
  };

  // animation이 'none'이면 undefined로 설정, 아니면 병합된 설정 사용
  const finalAnimation = animation === 'none' 
    ? undefined 
    : typeof animation === 'object' 
      ? { ...DEFAULT_ANIMATION, ...animation }
      : DEFAULT_ANIMATION;

  const { handleComponentSelect } = useEditableComponent();
  const [ref, animationStyles] = useAnimation(finalAnimation);

  const handleClick = () => {
    if (!editable || !handleComponentSelect) return;

    handleComponentSelect({
      type: editable.type,
      path: editable.path,
      content: src,
      styles
    });
  };

  return (
    <div 
      ref={ref}
      className={`
        relative 
        ${className}
        ${editable ? EDITABLE_HOVER_CLASSES : ''}
      `}
      style={{ 
        height: mergedStyles.height,
        borderRadius: `${mergedStyles.borderRadius}px`,
        ...animationStyles  // 애니메이션 스타일 추가
      }}
      onClick={handleClick}
    >
      {editable &&
        <div className="absolute right-0 top-0 px-4 py-2 bg-white/50">
          <Edit className="w-4 h-4" />
        </div>
      }
      {src && typeof(src) === 'string' && (
        <img
          src={src}
          alt={alt}
          className="w-full h-full"
          loading={loading}
          style={{
            objectFit: mergedStyles.objectFit,
            objectPosition: `${mergedStyles.position.x}% ${mergedStyles.position.y}%`,
            borderRadius: `${mergedStyles.borderRadius}px`
          }}
        />
      )}
      {styles?.overlay && (
        <div 
          className="absolute inset-0"
          style={{
            backgroundColor: styles.overlay.color,
            opacity: styles.overlay.opacity / 100,
            borderRadius: `${mergedStyles.borderRadius}px`
          }}
        />
      )}
    </div>
  );
};

export default ImageWrapper;