import { useState } from 'react';
import TextWrapper from "@/shared/components/TextWrapper";
import { Plus, Minus } from 'lucide-react';
import DOMPurify from "dompurify";
import { useGroupAnimation } from '@/shared/hooks/animation/useGroupAnimation';
import DivWithAnimation from '../common/DivWithAnimation';

const Faq01 = ({
  content,
  editablePath
}: any) => {
  const groupId = useGroupAnimation(100);

  const [openIndex, setOpenIndex] = useState<number | null>(null);

  const toggleFaq = (index: number) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  return (
    <div className="w-full mx-auto">
      <div className="flex flex-col gap-2">
        {content.items.map((item: any, index: number) => (
          <DivWithAnimation groupId={groupId}>
          <div key={item.id} className="">
            {/* 질문 */}
            <button
              onClick={() => toggleFaq(index)}
              className="w-full flex items-center justify-between p-4 text-left bg-white/20"
            >
              <span className="text-white text-base md:text-lg">{item.question}</span>
              {openIndex === index ? (
                <Minus className="w-6 h-6 text-white flex-shrink-0" />
              ) : (
                <Plus className="w-6 h-6 text-white flex-shrink-0" />
              )}
            </button>
            
            {/* 답변 */}
            <div
              className={`grid transition-all duration-300 ease-in-out bg-white/15 ${
                openIndex === index ? 'grid-rows-[1fr] opacity-100 p-4' : 'grid-rows-[0fr] opacity-0'
              }`}
            >
              <div
                className="overflow-hidden text-white/80 text-base md:text-lg"
                dangerouslySetInnerHTML={{ 
                  __html: DOMPurify.sanitize(item.answer) 
                }}>
              </div>
              
            </div>
          </div>
          </DivWithAnimation>
        ))}
      </div>
    </div>
  );
};

export default Faq01;