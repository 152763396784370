import ScrollToTop from '@/shared/components/ScrollToTop';
import Footer01 from '@/templates/components/footer/Footer01';
import { Outlet, useLocation } from 'react-router-dom';
import data from "@/templates/template2/config/data.json"

const RootLayout = () => {
  const location = useLocation();
  const isAdminPath = location.pathname.startsWith('/admin');

  return (
    <div className="min-h-screen">
      <ScrollToTop />
      {/* <Header01
        {...(templateData.essential)}
      /> */}
      <main>
        <Outlet />
      </main>
      
      {!isAdminPath &&
        <Footer01
          {...(data.essential.footer)}
        />
      }
    </div>
  );
};

export default RootLayout;