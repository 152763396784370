import { useEffect } from 'react';
import './DraftDialog.css';  // CSS 파일 import

interface DraftDialogProps {
  isOpen: boolean;
  onConfirm: () => void;
  onReject: () => void;
}

const DraftDialog = ({ isOpen, onConfirm, onReject }: DraftDialogProps) => {
  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, [isOpen]);

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center">
      {/* Animated Backdrop */}
      <div 
        className="fixed inset-0 bg-black/50 transition-opacity duration-300 ease-in-out backdrop-animation"
      />
      
      {/* Animated Dialog */}
      <div 
        className="relative z-50 w-full max-w-md p-6 bg-white rounded-lg shadow-xl transform transition-all duration-300 ease-in-out dialog-animation"
      >
        {/* Header */}
        <div className="mb-6">
          <h2 className="text-lg font-semibold text-gray-900">
            수정 중인 내용이 있습니다
          </h2>
          <p className="mt-2 text-sm text-gray-500">
            이어서 수정하시겠습니까?
          </p>
        </div>
        
        {/* Footer */}
        <div className="flex justify-end gap-3">
          <button
            onClick={onReject}
            className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
          >
            아니오
          </button>
          <button
            onClick={onConfirm}
            className="px-4 py-2 text-sm font-medium text-white bg-blue-600 rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
          >
            예
          </button>
        </div>
      </div>
    </div>
  );
};

export default DraftDialog;