import { useTemplateStore } from "@/shared/stores/templateStore";
import Footer01 from "@/templates/components/footer/Footer01";

const Footer = () => {
  const templateData = useTemplateStore(state => state.templateData);
  
  return (
    <Footer01
      {...(templateData.essential.footer)}
      editablePath="essential.footer"
    />
  );
};

export default Footer;