import { useTemplateData } from '@/builder/queries/templateQueries';
import NavigationMenu from './NavigationMenu';
import { Monitor, Phone, Smartphone } from 'lucide-react';

export type EditableComponent = {
  id: string;
  type: 'text';
  content: string;
 } | {
  id: string;
  type: 'image';
  content: string;
 };
 
 export interface EditorHeaderProps {
  onSave: () => void;
  onExit: () => void;
  isMobilePreview: boolean;
  onChangeView: () => void;
  navigationMenu: {name: string, path: string}[];
  hasChanges?: boolean;
}

const EditorHeader = ({ 
  onSave, 
  onExit,
  isMobilePreview,
  onChangeView,
  navigationMenu,  // 변경: templateData 대신 필요한 데이터만 받기
  hasChanges 
}: EditorHeaderProps) => {

 return (
   <header className="h-16 p-2 border-b flex items-center justify-between px-4">
     <div className="flex items-center">
      <NavigationMenu
        menu={navigationMenu.map((item, index) => ({
          id: `menu-${index}`, // 고유 ID 추가
          name: item.name,
          path: item.path,
          visible: true, // 기본값으로 true 설정
        }))}
      />
      {/* <NavigationMenu menu={navigationMenu || []} /> */}
     </div>

     <div className="flex items-center space-x-6">
      <div>
        <button
          onClick={onChangeView}
          className="flex items-center gap-2 px-3 py-2 rounded hover:bg-gray-100"
        >
          {isMobilePreview ? (
            <>
              <Monitor className="w-5 h-5" />
            </>
          ) : (
            <>
              <Smartphone className="w-5 h-5" />
            </>
          )}
        </button>
      </div>
      <div className='flex space-x-3'>
        <button onClick={onExit} className="px-5 py-2 border rounded">나가기</button>
        <button onClick={onSave} className="px-5 py-2 bg-blue-500 text-white rounded">변경하기</button>
      </div>
     </div>
   </header>
 );
};

export default EditorHeader;