import TextWrapper from "@/shared/components/TextWrapper";
import DOMPurify from "dompurify";
import DivWithAnimation from "../common/DivWithAnimation";
import { useGroupAnimation } from "@/shared/hooks/animation/useGroupAnimation";


interface Feature {
  id: number;
  title: string;
  description: string;
  editablePath?: string;
}

interface FeaturesContent {
  items: Feature[];
}

interface FeaturesProps {
  content: FeaturesContent;
  header?: {
    title: string;
    subtitle: string;
  };
  editablePath?: string;
}


const FeatureItem = ({ id, title, description, editablePath }: Feature) => {
  return (
    <div className="py-7 flex flex-col md:flex-row border-b border-dashed border-gray-400 last:border-none last:mb-12 md:last:mb-0">
      <div className="mr-0 md:mr-10">
        <span className="flex items-center justify-center w-[35px] h-[35px] text-xl font-semibold text-white bg-blue-900 rounded-full">
          {id}
        </span>
      </div>
      <div className="mt-5 md:mt-0">
        <TextWrapper
          content={title}
          editable={editablePath ? {
            type: 'richText',
            path: `${editablePath}.content.items[${ id - 1 }].title`
          } : undefined}
          animation="none"
        />

      <TextWrapper
        content={description}
        className="mt-2.5 md:mt-4"
        editable={editablePath ? {
          type: 'richText',
          path: `${editablePath}.content.items[${ id - 1 }].description`
        } : undefined}
        animation="none"
      />
      </div>
    </div>
  )
};


const Features01 = ({ content, editablePath }: FeaturesProps) => {
  const groupId = useGroupAnimation(300);
  
  return (
    <div className="mt-7 md:mt-8">
      {content.items && content.items.map((item, index) => (
        <DivWithAnimation groupId={groupId}>
        <FeatureItem
          key={index}
          {...item}
          id={index + 1}
          editablePath={editablePath}
          />
        </DivWithAnimation>
        ))}
    </div>
  )
};

export default Features01;