// src/layouts/AdminLayout.tsx
import { Outlet } from 'react-router-dom';
import Sidebar from '@/builder/components/admin/Sidebar';
import { useIsMobile } from '@/shared/hooks/useIsMobile';
import MobileBlocker from '@/builder/components/editor/MobileBlocker';
import { useTemplates } from '@/builder/queries/templateQueries';
import { useTemplateStore } from '@/shared/stores/templateStore';
import { useEffect, useRef } from 'react';
import { useIsAuthenticated } from '../stores/authStore';
import { Footer } from '@/templates/template1/pages';

const AdminLayout = () => {
  const isMobile = useIsMobile();
  const { data: templates } = useTemplates();
  const setTemplates = useTemplateStore((state) => state.setTemplates);

  useEffect(() => {
    if (templates) {
      setTemplates(templates);
    }
  }, [templates]);

  if (isMobile) {
    return <MobileBlocker />;
  }

  return (
    <div className="flex min-h-screen">
      <Sidebar />
      <main className="flex-1 bg-gray-50 p-8">
        <Outlet />
      </main>
    </div>
  );
};

export default AdminLayout;