import { useNavigate, useParams } from 'react-router-dom';
import EditorHeader from '@/builder/components/editor/EditorHeader';
import EditorModal from '@/builder/components/editor/Modal/EditorModal';
import { usePageStore } from '@/shared/stores/pageStore';
import toast from 'react-hot-toast';
import { useTemplateData, useUpdateTemplate } from '@/builder/queries/templateQueries';
import React, { useEffect, useState } from 'react';
import { cloneDeep, set } from 'lodash';
import { useIsMobile } from '@/shared/hooks/useIsMobile';
import MobileBlocker from '@/builder/components/editor/MobileBlocker';
import MobilePreview from '@/builder/components/editor/MobilePreview';
import { useTemplateStore } from '../../shared/stores/templateStore';
import PageRenderer from '../components/editor/PageRenderer';
import { useConfigData } from '@/shared/hooks/useConfigData';
import { useDraftManager } from '../hooks/useDraftManager';
import DraftDialog from '../components/draft/DraftDialog';

const EditorLayout = () => {
  const navigate = useNavigate();
  const { templateId = '' } = useParams();
  const isMobile = useIsMobile();
  const [isMobilePreview, setIsMobilePreview] = useState(false);
  

  // const data = useConfigData();
  const { data } = useTemplateData(templateId);
  const templateData = useTemplateStore(state => state.templateData);
  const setTemplateData = useTemplateStore(state => state.setTemplateData);
  const { mutateAsync: updateTemplate, isPending: isSaving } = useUpdateTemplate(templateId);
  const [isInitialized, setIsInitialized] = useState(false);

  const {
    selectedComponent,
    hasUnsavedChanges,
    setSelectedComponent,
    setHasUnsavedChanges 
  } = usePageStore();

  const draftManager = useDraftManager({
    templateId: templateId || '',  // 여기만 수정
    type: 'template',
    onConfirm: () => {
      // draft 데이터로 복원
      const draft = draftManager.getDraft();
      if (draft?.data) {
        setTemplateData(cloneDeep(draft.data));
        setHasUnsavedChanges(true);
      }
    },
    onReject: () => {
      // 서버 데이터로 초기화
      if (data) {
        setTemplateData(cloneDeep(data));
        setHasUnsavedChanges(false);
      }
    }
  });

  useEffect(() => {
    const initializeData = async () => {
      if (!data || !templateId) return;
      
      try {
        const hasDraft = draftManager.checkDraft();
        const draft = hasDraft ? draftManager.getDraft() : null;

        if (draft?.data && draft.id === templateId) {
          // draft가 있고 현재 templateId와 일치하면 사용자 선택 대기
          setIsInitialized(true);
        } else {
          // draft가 없거나 templateId가 다르면 서버 데이터로 초기화
          setTemplateData(cloneDeep(data));
          setIsInitialized(true);
        }
      } catch (error) {
        console.error('Data initialization error:', error);
        setIsInitialized(true);
      }
    };

    initializeData();
  }, [data, templateId]);


  const handleSave = async () => {
    // try {
      if (!templateData) return;
  
      await updateTemplate({
        type: 'data',
        data: templateData,
      });
  
      setHasUnsavedChanges(false);
      draftManager.clearDraft(); // 저장 성공시 draft 삭제
      toast.success('변경사항이 저장되었습니다');
    // } catch (error) {
    //   toast.error('저장 중 오류가 발생했습니다');
    //   console.error('Save error:', error);
    // }
  };


  const handleExit = () => {
    if (hasUnsavedChanges) {
      const confirmed = window.confirm('저장되지 않은 변경사항이 있습니다. 나가시겠습니까?');
      if (!confirmed) return;
    }
    setTemplateData(null);
    setHasUnsavedChanges(false);
    navigate('/admin');
  };

  const handleComponentChange = (content: any) => {
    if (!selectedComponent || !templateData) return;
    
    const newData = cloneDeep(templateData);
    set(newData, selectedComponent.path, content);
    setTemplateData(newData);
    setHasUnsavedChanges(true);
    draftManager.saveDraft(newData); // 컴포넌트 변경시 자동 draft 저장
  };

  const handleViewPort = () => {
    setIsMobilePreview(prev => !prev)
  }

  // 초기화 전에는 로딩 표시
  if (!isInitialized || !data) {
    return <div>Loading...</div>;
  }

  if (draftManager.showPrompt) {
    return (
      <DraftDialog
        isOpen={true}
        onConfirm={draftManager.handleConfirm}
        onReject={draftManager.handleReject}
      />
    );
  }

  // 초기화는 완료됐지만 데이터가 없는 경우 처리
  if (!templateData) {
    return <div>데이터를 불러오는데 실패했습니다.</div>;
  }

  if (isMobile) {
    return <MobileBlocker />;
  }

  return (
    <div className="h-screen flex flex-col">
      <EditorHeader 
        onSave={() => handleSave()}  // 저장과 동시에 배포
        onExit={handleExit}
        isMobilePreview={isMobilePreview}
        onChangeView={handleViewPort}
        navigationMenu={templateData?.essential?.navigation?.menu || []}
        hasChanges={hasUnsavedChanges}
      />
      
      <main className="flex-1">
        {isMobilePreview ? (
          <MobilePreview>
            <PageRenderer />
          </MobilePreview>
        ) : (
          <PageRenderer />
        )}
      </main>

      {selectedComponent && (
        <EditorModal
          component={selectedComponent}
          onClose={() => setSelectedComponent(null)}
          onChange={handleComponentChange}
        />
      )}
    </div>
  );
};

export default EditorLayout;