import { BatteryFull, Wifi } from 'lucide-react';
import React, { useEffect, useRef, ReactNode, useMemo } from 'react';
import { createRoot } from 'react-dom/client';

interface MobilePreviewProps {
  children: ReactNode;
}

interface PageRendererProps {
  data: any;
  onComponentClick?: (component: any) => void;
}

// 허용된 도메인 및 스타일시트 설정
const ALLOWED_DOMAINS = [
  'https://cdn.tailwindcss.com',
  'https://cdnjs.cloudflare.com',
  window.location.origin
];

// 이벤트 핸들러 비활성화를 위한 null props
const NULL_HANDLERS = {
  onClick: undefined,
  onMouseEnter: undefined,
  onMouseLeave: undefined,
  onKeyPress: undefined,
  onKeyDown: undefined,
  onKeyUp: undefined,
  onFocus: undefined,
  onBlur: undefined,
  onComponentClick: undefined,
  onChange: undefined,
  onSubmit: undefined,
};

const MobilePreview: React.FC<MobilePreviewProps> = ({ children }) => {
  const iframeRef = useRef<HTMLIFrameElement>(null);
  const rootRef = useRef<any>(null);
  
  // 스타일시트 캐싱
  const safeStylesheets = useMemo(() => {
    return Array.from(document.getElementsByTagName('link'))
      .filter(link => {
        try {
          const url = new URL((link as HTMLLinkElement).href);
          return ALLOWED_DOMAINS.some(domain => url.href.startsWith(domain));
        } catch {
          return false;
        }
      })
      .map(link => (link as HTMLLinkElement).href);
  }, []);

  // 인라인 스타일 캐싱
  const inlineStyles = useMemo(() => {
    return Array.from(document.getElementsByTagName('style'))
      .map(style => style.innerHTML)
      .join('\n');
  }, []);

  useEffect(() => {
    const iframe = iframeRef.current;
    if (!iframe) return;

    const iframeDoc = iframe.contentDocument || iframe.contentWindow?.document;
    if (!iframeDoc) return;

    // DOMParser를 사용하여 안전하게 DOM 구성
    const parser = new DOMParser();
    const htmlDoc = parser.parseFromString(`
      <!DOCTYPE html>
      <html>
        <head>
          <meta charset="utf-8">
          <meta name="viewport" content="width=370, initial-scale=1">
          <base href="${window.location.origin}">
          ${safeStylesheets.map(href => 
            `<link rel="stylesheet" href="${href}" crossorigin="anonymous">`
          ).join('\n')}
          <style>
            html, body {
              margin: 0;
              padding: 0;
              width: 370px;
              overflow-x: hidden;
            }
            #root {
              width: 370px;
              overflow-x: hidden;
            }
            ${inlineStyles}
          </style>
        </head>
        <body>
          <div id="root"></div>
        </body>
      </html>
    `, 'text/html');

    // 안전하게 DOM 복사
    iframeDoc.documentElement.replaceWith(htmlDoc.documentElement);

    const container = iframeDoc.getElementById('root');
    if (container && !rootRef.current) {
      rootRef.current = createRoot(container);
    }
    
    if (rootRef.current) {
      rootRef.current.render(
        <div className="mobile-preview-content">
          {React.Children.map(children, child => {
            if (React.isValidElement<PageRendererProps>(child)) {
              // 모든 이벤트 핸들러 비활성화 및 props 복사
              return React.cloneElement(child, {
                ...child.props,
                ...NULL_HANDLERS,
              } as PageRendererProps);
            }
            return child;
          })}
        </div>
      );
    }

    // 클린업
    return () => {
      if (rootRef.current) {
        rootRef.current.unmount();
        rootRef.current = null;
      }
    };
  }, [children, safeStylesheets, inlineStyles]);

  return (
    <div className="flex justify-center bg-gray-100 w-full h-full overflow-auto p-4">
      <div className="flex flex-col h-[740px] w-[370px] bg-white rounded-3xl shadow-xl overflow-hidden">
        <div className="h-6 bg-black text-white flex items-center justify-between px-4 text-sm">
          <span>12:00</span>
          <div className="flex items-center space-x-1">
            <BatteryFull />
          </div>
        </div>
        <iframe
          ref={iframeRef}
          className="flex-1 w-[390px] border-none"
          title="Mobile Preview"
          sandbox="allow-same-origin allow-scripts"
          loading="lazy"
        />
      </div>
    </div>
  );
};

export default MobilePreview;