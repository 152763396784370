import { Layout, Globe, Gauge, Users, Clock } from 'lucide-react';
import { useNavigate } from 'react-router-dom';

interface TemplateCardProps {
  templateId: string;
}

const TemplateCard = ({ templateId }: TemplateCardProps) => {
  const navigate = useNavigate();
  
  const features = [
    { icon: Globe, text: '반응형 디자인' },
    { icon: Clock, text: '10분 내 제작 가능' },
    { icon: Gauge, text: '빠른 로딩 속도' },
    { icon: Users, text: '편리한 사용성' }
  ];

  return (
    <div 
      className="bg-white rounded-xl shadow-lg overflow-hidden hover:shadow-xl transition-shadow duration-300 cursor-pointer group"
      onClick={() => navigate(`/admin/templates/${templateId}`)}
    >
      {/* <div className="relative aspect-[16/9] bg-gray-100 overflow-hidden">
        <img
          src="/images/template-preview.jpg"
          alt="Corporate Template Preview"
          className="object-cover w-full h-full group-hover:scale-105 transition-transform duration-300"
        />
        <div className="absolute inset-0 bg-black bg-opacity-0 group-hover:bg-opacity-10 transition-all duration-300" />
      </div> */}

      <div className="p-6">
        <div className="flex items-start justify-between mb-4">
          <div>
            <h3 className="text-xl font-bold text-gray-900">기업형 템플릿</h3>
            <p className="text-gray-600 mt-1">전문적이고 신뢰감 있는 디자인</p>
          </div>
          <span className="bg-blue-100 text-blue-800 text-xs font-semibold px-2.5 py-0.5 rounded">
            사용 중
          </span>
        </div>

        <div className="grid grid-cols-2 gap-4 mt-6">
          {features.map((feature, index) => (
            <div key={index} className="flex items-center space-x-2">
              <feature.icon className="w-4 h-4 text-blue-500" />
              <span className="text-sm text-gray-600">{feature.text}</span>
            </div>
          ))}
        </div>

        <button
          onClick={() => navigate(`/admin/templates/${templateId}`)}
          className="mt-6 w-full bg-blue-500 text-white py-2 rounded-lg hover:bg-blue-600 transition-colors flex items-center justify-center space-x-2"
        >
          <Layout className="w-4 h-4" />
          <span>템플릿 편집하기</span>
        </button>
      </div>
    </div>
  );
};

export default TemplateCard;