import ImageWrapper from "@/shared/components/ImageWrapper";
import TextWrapper from "@/shared/components/TextWrapper";
import DivWithAnimation from "../common/DivWithAnimation";
import { useGroupAnimation } from "@/shared/hooks/animation/useGroupAnimation";


const CardItem = ({ 
  id,
  title,
  subtitle, 
  description, 
  images,
  editablePath,
  variant = 'white'  // 기본값
 }: any) => {
  const styles: any = {
    white: {
      card: 'bg-white border-gray-100 shadow-custom-xl',
    },
    transparent: {
      card: '!bg-transparent border-gray-400',
    }
  };
 
  const style = styles[variant];
 
  return (
    <div className={`bg-white rounded-xl border-2 overflow-hidden ${style.card}`}>
      <ImageWrapper
        src={images.items[id-1].src}
        styles={images.items[id-1].styles}
        alt="hero"
        className="w-full aspect-[7/5]"
        loading="lazy"
        editable={editablePath ? {
          type: 'image',
          path: `${editablePath}.images.items[${id-1}]`
        } : undefined}
        animation="none"
      />
 
      <div className="p-7 md:p-8 text-center break-keep tracking-tight whitespace-pre-line">
        <TextWrapper
          content={subtitle}
          className="mb-2"
          editable={editablePath ? {
            type: 'richText',
            path: `${editablePath}.content.items[${ id - 1 }].subtitle`
          } : undefined}
          animation="none"
        />

        <TextWrapper
          content={title}
          className="mb-6"
          editable={editablePath ? {
            type: 'richText',
            path: `${editablePath}.content.items[${ id - 1 }].title`
          } : undefined}
          animation="none"
        />

        <TextWrapper
          content={description}
          className="mb-2"
          editable={editablePath ? {
            type: 'richText',
            path: `${editablePath}.content.items[${ id - 1 }].description`
          } : undefined}
          animation="none"
        />
      </div>
    </div>
  )
}


const CardGrid01 = ({
  content,
  images,
  editablePath,
  variant = 'white'
}: any) => {
  const groupId = useGroupAnimation(300);

  return(
    <div className="grid grid-cols-1 md:grid-cols-3 gap-10 lg:gap-12">
      {content.items.map((item : any, index: any) => (
        <DivWithAnimation
          groupId={groupId}
        >
          <CardItem
            key={item.id}
            id={index + 1}
            {...item}
            images={images}
            editablePath={editablePath}
            variant={variant}
          />
        </DivWithAnimation>
      ))}
    </div>
  )
}


export default CardGrid01;