import React from 'react';
import { ChevronLeft, ChevronRight } from 'lucide-react';

interface PaginationProps {
  totalItems: number;
  itemsPerPage: number;
  currentPage: number;
  onPageChange: (page: number) => void;
  maxVisiblePages?: number;
}

const Pagination = ({
  totalItems,
  itemsPerPage,
  currentPage,
  onPageChange,
  maxVisiblePages = 5
}: PaginationProps) => {
  const totalPages = Math.ceil(totalItems / itemsPerPage);
  
  // 현재 페이지 그룹의 시작페이지 계산
  const currentGroup = Math.ceil(currentPage / maxVisiblePages);
  const firstPage = (currentGroup - 1) * maxVisiblePages + 1;
  const lastPage = Math.min(firstPage + maxVisiblePages - 1, totalPages);
  
  // 현재 그룹의 페이지 배열 생성
  const pages = Array.from(
    { length: lastPage - firstPage + 1 }, 
    (_, i) => firstPage + i
  );

  // 그룹 이동 핸들러
  const goToPrevGroup = () => {
    const prevGroupFirstPage = firstPage - maxVisiblePages;
    onPageChange(prevGroupFirstPage);
  };

  const goToNextGroup = () => {
    onPageChange(firstPage + maxVisiblePages);
  };

  return (
    <div className="flex items-center justify-center gap-2">
      <button
        onClick={goToPrevGroup}
        disabled={firstPage === 1}
        className="p-2 rounded hover:bg-gray-100 disabled:opacity-50 disabled:hover:bg-transparent"
      >
        <ChevronLeft className="w-5 h-5" />
      </button>

      {pages.map(pageNum => (
        <button
          key={pageNum}
          onClick={() => onPageChange(pageNum)}
          className={`w-10 h-10 rounded ${
            currentPage === pageNum
              ? 'bg-blue-600 text-white'
              : 'hover:bg-gray-100'
          }`}
        >
          {pageNum}
        </button>
      ))}

      <button
        onClick={goToNextGroup}
        disabled={lastPage === totalPages}
        className="p-2 rounded hover:bg-gray-100 disabled:opacity-50 disabled:hover:bg-transparent"
      >
        <ChevronRight className="w-5 h-5" />
      </button>
    </div>
  );
};


export default Pagination;