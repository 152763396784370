import React, { useState } from 'react';

interface MapEditorProps {
  content?: string;
  onChange?: (updatedContent: string) => void;
}

const MapEditor: React.FC<MapEditorProps> = ({ content = '', onChange }) => {
  const [mapUrl, setMapUrl] = useState<string>(content);

  // URL 업데이트 핸들러
  const handleUrlChange = (newValue: string) => {
    // iframe src URL을 추출하는 정규식
    const srcRegex = /src="(https:\/\/www\.google\.com\/maps\/embed\?[^"]+)"/;
    const match = newValue.match(srcRegex);
    
    // 매칭된 URL이 있으면 해당 URL만 사용, 없으면 입력값 그대로 사용
    const extractedUrl = match ? match[1] : newValue;

    if (onChange) {
      onChange(extractedUrl);
      setMapUrl(extractedUrl);
    }
  };

  return (
    <div className="w-full space-y-4 p-4 border rounded-lg bg-gray-50">
      <div className="space-y-2">
        <div className="bg-white p-4 rounded shadow-sm border">
          <label className="block text-sm font-medium text-gray-700 mb-2">
            구글맵 링크
          </label>
          <input
            type="text"
            value={mapUrl}
            onChange={(e) => handleUrlChange(e.target.value)}
            className="w-full py-2 px-3 border rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
            placeholder="구글맵 iframe 소스를 입력하세요"
          />
        </div>
      </div>

      <div className="mt-4">
        <a
          href="https://husky-coffee-14c.notion.site/14bc3ee6db8d808da31ffa1b6a581093"
          target="_blank"
          rel="noopener noreferrer"
          className="text-blue-600 hover:text-blue-800 text-sm hover:underline"
        >
          구글맵 링크 검색 방법 →
        </a>
      </div>
    </div>
  );
};

export default MapEditor;