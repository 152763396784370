import ImageWrapper from "@/shared/components/ImageWrapper";
import DOMPurify from "dompurify";
import { ImageAsset } from "@/shared/types/common";
import TextWrapper from "@/shared/components/TextWrapper";
import { useGroupAnimation } from "@/shared/hooks/animation/useGroupAnimation";
import DivWithAnimation from "../common/DivWithAnimation";

export interface IntroductionProps {
  content: {
    title: string;
    description: string;
    // content: string;
    ceo: string;
  }
  images: {
    main: ImageAsset;
  };
  editablePath?: string;
}

const Introduction01 = ({ content, images, editablePath } : IntroductionProps) => {
  const groupId = useGroupAnimation(300);

  return (
    <div className="flex flex-col md:flex-row">
        <div className="relative w-[96%] md:w-[95%] flex flex-1">
          <ImageWrapper
            src={images.main.src}
            styles={images.main.styles}
            alt="main"
            className="w-full !aspect-[4/3]"
            loading="lazy"
            editable={editablePath ? {
              type: 'image',
              path: editablePath ? `${editablePath}.images.main` : ''
            } : undefined}
            animation="none"
          />
          <div className="absolute left-[100%] w-2.5 md:w-3 h-40 md:h-64 bg-blue-800"></div>
          <div className="absolute bottom-[100%] -right-2.5 md:-right-3 w-40 md:w-64 h-2.5 md:h-3 bg-blue-300"></div>
        </div>

        <div className="flex-1">
          <TextWrapper
            content={content.title}
            className="pt-10 md:pt-0 pb-6 md:pb-8 mb-6 md:mb-8 pl-0 md:pl-20 border-blue-800 border-b-2"
            editable={editablePath ? {
              type: 'richText',
              path: `${editablePath}.content.title`
            } : undefined}
            animation={{
              type: "slideUp",
              groupId: groupId
            }}
          />

          <TextWrapper
            content={content.description}
            className="pl-0 md:pl-20 break-keep"
            editable={editablePath ? {
              type: 'richText',
              path: `${editablePath}.content.description`
            } : undefined}
            animation={{
              type: "slideUp",
              groupId: groupId
            }}
          />
          {/* <p
            className=
            "pl-0 md:pl-20 break-keep"
            // "pl-0 md:pl-20 text-sm text-slate-800 font-light break-keep whitespace-pre-line"
            dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(content.description)}}
          >
          </p> */}
          <DivWithAnimation
            className=
            "pt-10 w-full flex !justify-end gap-1 text-lg text-slate-600 font-semibold"
            groupId={groupId}
          >
            <span className="text-blue-800">회사이름</span> 대표
            <TextWrapper
              content={content.ceo}
              editable={{
                type: 'plainText',
                path: `${editablePath}.content.ceo`
              }}
              animation="none"
            />
            {/* <span>{content.ceo}</span> */}
          </DivWithAnimation>
        </div>
    </div>
  );
 };
 
 export default Introduction01;