import { useTemplateStore } from "@/shared/stores/templateStore";
import CardGrid01 from "@/templates/components/cardgrid/CardGrid01";
import PageBanner from "@/templates/components/common/PageBanner";
import SectionContainer from "@/templates/components/common/SectionContainer";
import SectionHeader from "@/templates/components/common/SectionHeader";

const ServicesHome = () => {
  const templateData = useTemplateStore(state => state.templateData);

  return (
    <div>
      <PageBanner
        {...(templateData.pages.services.common)}
        editablePath="pages.services.common"
      />

      <SectionContainer>
        <SectionHeader
          {...(templateData.pages.services.home.first)}
          editablePath="pages.services.home.first"
        />
        <CardGrid01
          {...(templateData.pages.services.home.first)}
          editablePath="pages.services.home.first"
        />
      </SectionContainer>
    </div>
  );
};

export default ServicesHome;