import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, FreeMode } from 'swiper/modules';
import 'swiper/css';
import { useIsMobile } from '@/shared/hooks/useIsMobile';
import ImageWrapper from '@/shared/components/ImageWrapper';
 

const Portfolio01 = ({
  images,
  editablePath
 }: any) => {
  const isMobile = useIsMobile();

  // !!! caution !!!
  // add css .swiper-wrapper in index.css

  // 첫 번째 줄 설정 (천천히)
  const firstRowConfig = {
    modules: [Autoplay],
    slidesPerView: isMobile ? 1 : 3,
    spaceBetween: isMobile ? 10 : 20,
    loop: true,
    loopAdditionalSlides: 1,
    autoplay: {
      delay: 0,
      disableOnInteraction: false,
    },
    speed: 8000, // 더 느리게 설정
  };

  // 두 번째 줄 설정 (빠르게)
  const secondRowConfig = {
    modules: [Autoplay],
    slidesPerView: isMobile ? 3 : 6,
    spaceBetween: isMobile ? 10 : 20,
    loop: true,
    loopAdditionalSlides: 1,
    autoplay: {
      delay: 0,
      disableOnInteraction: false,
    },
    speed: 3000, // 더 빠르게 설정
  };
  

  return (
    <div className="flex flex-col gap-3 md:gap-6 overflow-hidden py-3 md:py-6 bg-gradient-to-r from-[#454545]/25 to-[#ffbf00]/25">
    {/* <div className="flex flex-col gap-3 md:gap-6 overflow-hidden py-3 md:py-6 bg-gradient-to-r from-[#454545]/25 to-[#ABABAB]/30"> */}
      {/* 첫 번째 줄 */}
      <div className="w-full">
        <Swiper {...firstRowConfig} className="w-full swiper-wrapper">
        {images.items.map((item: any, index: any) => (
            <SwiperSlide key={`top-${index}`}>
              <div className="relative w-full aspect-video overflow-hidden rounded-md md:rounded-lg">
                  <ImageWrapper
                    src={item.src}
                    styles={item.styles}
                    alt={`portfolio-${index}`}
                    className="w-full"
                    loading="lazy"
                    editable={editablePath ? {
                      type: 'image',
                      path: editablePath ? `${editablePath}.images.items` : ''
                    } : undefined}
                    animation="none"
                  />
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>

      {isMobile &&
        <div className="w-full"> {/* 고정된 높이 설정 */}
          <Swiper
            {...secondRowConfig}
            autoplay={{
              ...secondRowConfig.autoplay,
              reverseDirection: true
            }}
            className="h-full swiper-wrapper"
          >
            {images.items.map((item: any, index: any) => (
              <SwiperSlide key={`bottom-${index}`}>
                <div className="relative w-full h-full overflow-hidden rounded-sm">
                  <ImageWrapper
                    src={item.src}
                    styles={item.styles}
                    alt={`portfolio-${index}`}
                    className="w-full h-full"
                    loading="lazy"
                    editable={editablePath ? {
                      type: 'image',
                      path: editablePath ? `${editablePath}.images.items` : ''
                    } : undefined}
                    animation="none"
                  />
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      }
      
    </div>
  );
};

export default Portfolio01;