import { EDITABLE_HOVER_CLASSES } from "@/shared/constants/styles";
import { usePageStore } from "@/shared/stores/pageStore";

interface FooterProps {
  company: {
    name?: {
      korean?: string;
      english?: string;
    };
    ceo?: string;
    registration?: string;
    privacy_officer?: string;
    address?: string;
    contact?: {
      phone?: string;
      email?: string;
    };
    logo?: {
      white?: string;
      black?: string;
    };
  };
  links?: {
    name: string;
    path: string;
  }[];
  editablePath?: string;
  onEdit?: () => void;
}

const generateId = () => {
  return `company_${Math.random().toString(36).substr(2, 9)}`;
};

export default function Footer01({ company, links, editablePath}: FooterProps) {
  const setSelectedComponent = usePageStore(state => state.setSelectedComponent);

  const handleClick = () => {
    if (editablePath) {
      const editorConfig: any = {
        type: 'footer',
        path: `${editablePath}`,
        content: {
          company
        }
      };
  
      setSelectedComponent({
        id: generateId(),
        ...editorConfig
      });
    }
  };

  return (
    <footer 
      className={`py-12 bg-gray-200 relative ${editablePath ? EDITABLE_HOVER_CLASSES : ''}`}
      onClick={handleClick}
    >
      {company?.logo?.black && (
        <div className="max-w-screen-xl mx-auto px-4 mb-8">
          <img
            src={company?.logo?.black}
            alt="logo"
            className="w-24 object-cover opacity-40"
          />
        </div>
      )}

      {links && links.length > 0 && links.some(link => link.path) && (
        <div className="absolute bottom-4 right-4">
          <div className="flex gap-2">
            {links.map((link, index) => (
              link.path && (
                <a 
                  key={index}
                  href={link.path}
                  target="_blank"
                  className="text-sm !leading-relaxed text-gray-400 hover:text-gray-700 transition-colors"
                >
                  {link.name}
                </a>
              )
            ))}
          </div>
        </div>
      )}

      <div className="my-8 border-[1px] border-gray-300" />

      <div className="max-w-screen-xl mx-auto px-4 text-sm !leading-relaxed text-gray-400">
        {(company?.name?.korean || company?.address) && (
          <p>
            {company?.name?.korean && `상호 : ${company?.name.korean}`}
            {company?.name?.korean && company?.address && ' | '}
            {company?.address && `사업장소재지 : ${company?.address}`}
            {company?.ceo && ` | 대표자명 : ${company?.ceo}`}
          </p>
        )}

        {(company?.registration || company?.privacy_officer) && (
          <p>
            {company?.registration && `사업자등록번호 ${company?.registration}`}
            {company?.registration && company?.privacy_officer && ' | '}
            {company?.privacy_officer && `개인정보 보호 책임자 : ${company?.privacy_officer}`}
          </p>
        )}

        {(company?.contact?.phone || company?.contact?.email) && (
          <p>
            {company?.contact.phone && `전화번호 : ${company?.contact.phone}`}
            {company?.contact.phone && company?.contact.email && ' | '}
            {company?.contact.email && `E-mail : ${company?.contact.email}`}
          </p>
        )}

        {company?.name?.english && (
          <p className="mt-4">{company?.name.english}. All Rights Reserved.</p>
        )}
      </div>
    </footer>
  );
}