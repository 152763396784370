import { useTemplateStore } from '@/shared/stores/templateStore';
import ImageSection from '@/templates/components/common/ImageSection';
import PageBanner from '@/templates/components/common/PageBanner';
import SectionContainer from '@/templates/components/common/SectionContainer';
import SectionHeader from '@/templates/components/common/SectionHeader';
import Location01 from '@/templates/components/location/Location01';


const AboutLocation = () => {
  const templateData = useTemplateStore(state => state.templateData);

  return (
    <div>
      <PageBanner
        {...(templateData.pages.about.common)}
        editablePath="pages.about.common"
      />
      
      <SectionContainer>
        <SectionHeader
          {...(templateData.pages.about.location.first)}
          editablePath="pages.about.location.first"
        />
        <Location01
          {...(templateData.pages.about.location.first)}
          editablePath="pages.about.location.first"
        />
      </SectionContainer>

    </div>
  );
};

export default AboutLocation;