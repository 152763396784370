
interface HedaerProps {
  title: string;
  subtitle: string;
}

const Header = (
  {title, subtitle} : HedaerProps
)  => {
  return (
    <div className="mb-8">
      <h1 className="text-2xl font-bold">{title}</h1>
      <p className="text-gray-600 mt-2">
        {subtitle}
      </p>
    </div>
  );
};

export default Header