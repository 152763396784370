import { useNavigate } from 'react-router-dom';
import { useTemplateStore } from '@/shared/stores/templateStore';
import Header from '@/builder/components/admin/Header';
import PostForm from '@/builder/components/posts/PostForm';
import { useCreatePost } from '@/builder/queries/postQueries';
import DraftDialog from '@/builder/components/draft/DraftDialog';
import { useEffect, useState, useCallback, useRef } from 'react';
import { useDraftManager } from '@/builder/hooks/useDraftManager';
import { PostFormData } from '@/builder/types/post';

const CreatePost = () => {
  const navigate = useNavigate();
  const selectedTemplateId = useTemplateStore(state => state.selectedTemplateId);
  const createPost = useCreatePost(selectedTemplateId as string);
  const [formInitialData, setFormInitialData] = useState<PostFormData>({ 
    title: '', 
    subtitle: '', 
    content: '', 
    thumbnailUrl: '' 
  });
  const [isInitialized, setIsInitialized] = useState(false);
  const [showDraftPrompt, setShowDraftPrompt] = useState(false);
  
  const draftManager = useDraftManager({
    templateId: selectedTemplateId || '',
    type: 'post'
  });

  // 초기화 시 draft 확인
  useEffect(() => {
    const initializeComponent = () => {
      if (!selectedTemplateId) {
        alert('템플릿이 선택되지 않았습니다.');
        navigate('/admin/posts');
        return;
      }

      // draft 확인 및 로그
      const hasDraft = draftManager.checkDraft();
      
      if (hasDraft) {
        setShowDraftPrompt(true);
      }
      
      setIsInitialized(true);
    };

    initializeComponent();
  }, [selectedTemplateId]);

  // 드래프트 복구 핸들러
  const handleConfirmDraft = useCallback(() => {
    const draftData = draftManager.getDraft();
    
    if (draftData?.data) {
      setFormInitialData(draftData.data);
    }
    
    setShowDraftPrompt(false);
  }, [draftManager]);

  // 드래프트 거절 핸들러
  const handleRejectDraft = useCallback(() => {
    draftManager.clearDraft();
    setShowDraftPrompt(false);
  }, [draftManager]);

  const handleSubmit = async (formData: PostFormData) => {
    if (!selectedTemplateId) {
      alert('템플릿이 선택되지 않았습니다.');
      return;
    }

    if (!formData.title || !formData.content) {
      alert('제목과 내용은 필수 입력사항입니다.');
      return;
    }

    try {
      await createPost.mutateAsync({
        ...formData,
        templateId: selectedTemplateId
      });
      
      draftManager.clearDraft();
      navigate('/admin/posts');
    } catch (error) {
      console.error('Error creating post:', error);
    }
  };

  const handleCancel = () => {
    if (draftManager.getDraft()) {
      const confirmed = window.confirm('작성 중인 내용이 저장되지 않았습니다. 나가시겠습니까?');
      if (!confirmed) return;
    }
    navigate('/admin/posts');
  };

  // 폼 데이터 변경 핸들러
  const handleFormChange = useCallback((newData: PostFormData) => {
    try {
      draftManager.saveDraft(newData);
    } catch (error) {
      console.error('Draft save failed:', error);
    }
  }, [draftManager]);

  // PostForm 업데이트 확인을 위한 useEffect
  useEffect(() => {
  }, [formInitialData]);

  if (!isInitialized) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <div className="space-y-6 max-w-5xl mx-auto">
        <Header
          title="새 게시물 작성"
          subtitle="웹사이트에 새로운 컨텐츠를 작성합니다"
        />

        <div className="bg-white rounded-xl shadow-lg p-6">
          <PostForm
            key={JSON.stringify(formInitialData)} // 강제 리렌더링을 위한 key 추가
            initialData={formInitialData}
            isSubmitting={createPost.isPending}
            onSubmit={handleSubmit}
            onCancel={handleCancel}
            onChange={handleFormChange}
          />
        </div>
      </div>

      <DraftDialog
        isOpen={showDraftPrompt}
        onConfirm={handleConfirmDraft}
        onReject={handleRejectDraft}
      />
    </>
  );
};

export default CreatePost;