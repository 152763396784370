import { useNavigate } from 'react-router-dom';
import SignupForm from '../../components/auth/SignupForm';
import { useRegister } from '@/builder/queries/authQueries';
import toast from 'react-hot-toast';

interface SignupParams {
  email: string;
  password: string;
  companyName: string;
  taxId?: string;
  phoneNumber?: string;
  agreedToService: boolean;
  agreedToPrivacy: boolean;
  agreedToMarketing: boolean;
}

const Signup = () => {
  const navigate = useNavigate();
  const { mutate: register, isPending } = useRegister();

  const handleSignup = ({
    email,
    password,
    companyName,
    taxId,
    phoneNumber,
    agreedToService,
    agreedToPrivacy,
    agreedToMarketing
  }: SignupParams) => {
    if (isPending) return;
    const toastId = toast.loading('회원가입 중입니다...');

    register(
      { 
        email, 
        password, 
        companyName,
        taxId,
        phoneNumber,
        agreedToService,
        agreedToPrivacy,
        agreedToMarketing
      },
      {
        onSuccess: () => {
          toast.success('회원가입이 완료되었습니다.', { id: toastId });
          navigate('/auth/login');
        },
        onError: (error: any) => {
          const errorMessage = error.response?.data?.error || '회원가입에 실패했습니다';
          toast.error(errorMessage);
          
          if (errorMessage.includes('already exists')) {
            toast.error('이미 사용 중인 이메일입니다');
          }
        }
      }
    );
  };

  return (
    <section className="px-4 min-h-screen relative flex items-center justify-center">
      <div className="absolute inset-0 overflow-hidden -z-10">
        <img
          src="/images/auth/login.webp"
          alt="signup"
          className="object-cover w-full h-full"
        />
      </div>
      <SignupForm 
        onSubmit={handleSignup}
        isSubmitting={isPending}  // 로딩 상태 전달 (필요한 경우)
      />
    </section>
  );
};

export default Signup;