import { useTemplateStore } from "@/shared/stores/templateStore";
import PageBanner from "@/templates/components/common/PageBanner";
import SectionContainer from "@/templates/components/common/SectionContainer";
import SectionHeader from "@/templates/components/common/SectionHeader";
import PostsList01 from "@/templates/components/posts/PostsList01";

const ProductsHome = () => {
  const templateData = useTemplateStore(state => state.templateData);

  return (
    <div>
      <PageBanner
        {...(templateData.pages.products.common)}
        editablePath="pages.products.common"
      />

      <SectionContainer>
        <SectionHeader
          {...(templateData.pages.products.home.first)}
          editablePath="pages.products.home.first"
        />
        <PostsList01
          posts= {templateData.posts}
          editablePath="posts" // 웹빌더 구분용
        />
      </SectionContainer>
    </div>
  );
};

export default ProductsHome;