import { useTemplateStore } from "@/shared/stores/templateStore";
import SectionContainer from "@/templates/components/common/SectionContainer";
import SectionHeader from "@/templates/components/common/SectionHeader";
import Contact03 from "@/templates/components/contact/Contact03";
import Faq01 from "@/templates/components/faq/Faq01";
import Features03 from "@/templates/components/features/Features03";
import Hero03 from "@/templates/components/hero/Hero03";
import Portfolio01 from "@/templates/components/portfolio/Portfolio01";
import Pricing01 from "@/templates/components/pricing/Pricing01";
import data from "@/templates/template2/config/data.json"


function Home() {
  const templateData = data;
  // const templateData = useTemplateStore(state => state.templateData);

  return (
    <div>
      <Hero03
        {...(templateData.pages.home.hero)}
        // editablePath="pages.home.hero"
      />

      <SectionContainer
        backgroundImage={templateData.pages.home.portfolio.images?.background}
        containerSize="none"
        // containerClassName="!pb-0"
        // editablePath="pages.home.portfolio"
      >
        <div className="max-w-screen-xl mx-auto px-4 md:px-8">
          <SectionHeader
            {...(templateData.pages.home.portfolio)}
            styles='border-none !pb-0'
            // editablePath="pages.home.portfolio"
          />
        </div>
        <Portfolio01
          {...(templateData.pages.home.portfolio)}
          // editablePath="pages.home.portfolio"
        />
      </SectionContainer>

      <SectionContainer
        backgroundImage={templateData.pages.home.features.images?.background}
        // editablePath="pages.home.features"
      >
        <SectionHeader
            {...(templateData.pages.home.features)}
            styles='border-none'
            // editablePath="pages.home.features"
          />
        <Features03
          {...(templateData.pages.home.features)}
        />
      </SectionContainer>

      <SectionContainer
        backgroundImage={templateData.pages.home.pricing.images?.background}
        // editablePath="pages.home.features"
      >
        <SectionHeader
          {...(templateData.pages.home.pricing)}
          styles='border-none !pb-0'
          // editablePath="pages.home.pricing"
        />
        <Pricing01
          {...(templateData.pages.home.pricing)}
          styles='border-none'
          // editablePath="pages.home.pricing"
        />
      </SectionContainer>

      <SectionContainer
        backgroundImage={templateData.pages.home.banner.images?.background}
        // editablePath="pages.home.banner"
      >
        <SectionHeader
          {...(templateData.pages.home.banner)}
          styles='border-none !py-12 !mb-0'
          // editablePath="pages.home.banner"
        />
      </SectionContainer>

      <SectionContainer
        backgroundImage={templateData.pages.home.faq.images?.background}
        // editablePath="pages.home.faq"
      >
        <SectionHeader
          {...(templateData.pages.home.faq)}
          styles='border-none !pb-0'
          // editablePath="pages.home.faq"
        />
        <Faq01
          {...(templateData.pages.home.faq)}
          // editablePath="pages.home.faq"
        />
      </SectionContainer>

      <SectionContainer
        backgroundImage={templateData.pages.home.contact.images?.background}
        containerClassName="!py-24"
        // editablePath="pages.home.contact"
      >
        <Contact03
          {...(templateData.pages.home.contact)}
          // editablePath="pages.home.contact"
        />
      </SectionContainer>


      {/* Footer넣기 */}
    </div>
  );
}

export default Home;