import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Home, Edit, FileText, HelpCircle, LogOut } from 'lucide-react';
import TemplateSelector from '@/builder/components/admin/TemplateSelector';
import { useTemplateStore } from '@/shared/stores/templateStore';

const navigationItems = [
  { 
    name: '홈', 
    path: '/admin', 
    icon: Home,
    type: 'internal'
  },
  { 
    name: '사이트 편집', 
    path: '/admin/templates', 
    icon: Edit,
    type: 'internal'
  },
  { 
    name: '게시물 관리', 
    path: '/admin/posts', 
    icon: FileText,
    type: 'internal'
  },
  { 
    name: '자주묻는질문', 
    path: 'https://husky-coffee-14c.notion.site/FAQ-14bc3ee6db8d802c8728fc6b13199a2f?pvs=4', 
    icon: HelpCircle,
    type: 'external'
  },
  { 
    name: '로그아웃',
    path: '/auth/login',
    icon: LogOut,
    type: 'logout'  // type을 logout으로 변경
  },
];

const Sidebar = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const handleLogout = () => {
    localStorage.removeItem('template-storage')
    localStorage.removeItem('auth-storage');
    localStorage.removeItem('token')
    navigate('/auth/login');
  };

  const getLinkClassName = (path: string) => `w-full flex items-center px-4 py-3 text-gray-700 hover:bg-gray-100 ${
    location.pathname === path ? 'bg-gray-100 text-blue-600' : ''
  }`;

  return (
    <aside className="w-64 h-screen bg-white border-r">
      <div className='p-3 pt-6 border-b'>
        <h1 className="text-xl font-bold">THIS IS WEB</h1>
        <TemplateSelector />
      </div>
      
      <nav className="mt-4">
        {navigationItems.map((item) => {
          if (item.type === 'external') {
            return (
              <a
                key={item.path}
                href={item.path}
                target="_blank"
                rel="noopener noreferrer"
                className={getLinkClassName(item.path)}
              >
                <item.icon className="w-5 h-5 mr-3" />
                <span>{item.name}</span>
              </a>
            );
          }
          
          if (item.type === 'logout') {
            return (
              <button
                key={item.path}
                onClick={handleLogout}
                className={getLinkClassName(item.path)}
              >
                <item.icon className="w-5 h-5 mr-3" />
                <span>{item.name}</span>
              </button>
            );
          }

          return (
            <Link
              key={item.path}
              to={item.path}
              className={getLinkClassName(item.path)}
            >
              <item.icon className="w-5 h-5 mr-3" />
              <span>{item.name}</span>
            </Link>
          );
        })}
      </nav>
    </aside>
  );
};

export default Sidebar;