
// Set codes in tailwind.config.js

const ScrollMouse = () => (
  <div className="absolute animate-bounce">
    <div className="w-11 md:w-14 h-20 md:h-24 border-2 border-white/80 rounded-full flex items-center justify-center">
      <div className="w-2 h-3 bg-white rounded-full animate-scroll" />
    </div>
  </div>
);

export default ScrollMouse;