import { useTemplateStore } from '@/shared/stores/templateStore';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import toast from 'react-hot-toast';
import { templateKeys } from './templateQueries';
import { withAuthInterceptor } from '../utils/api';
import { useMemo } from 'react';
import { useUser } from '../stores/authStore';

interface CreatePostData {
  templateId: string;
  title: string;
  subtitle?: string;
  content: string;
  thumbnailUrl?: string;
}

// useTemplateData를 통해 데이터를 받아오고, usePosts는 이를 가공
export const usePosts = (page?: number, limit?: number) => {
  const api = withAuthInterceptor();
  const user = useUser();
  const templateId = useTemplateStore(state => state.selectedTemplateId);
  
  // templateData에서 데이터 가져오기
  const { data: templateData, isLoading, error } = useQuery({
    queryKey: templateKeys.data(user?.userId || '', templateId || ''),
    // queryKey: templateKeys.data(templateId || ''),
    queryFn: async () => {
      if (!templateId) throw new Error('Template not selected');
      const { data } = await api.get(`/api/templates/${templateId}/data`);
      return data.data;
    },
    enabled: !!templateId // templateId가 없을 때는 쿼리 실행 막기
  });

  // 페이지네이션 처리
  const processedData = useMemo(() => {
    if (!templateData?.posts) return null;

    // 원본 배열을 변경하지 않고 새로운 배열 생성
    const sortedPosts = [...templateData.posts].sort((a, b) => {
      // ID로 정렬 (최신순)
      return b.id - a.id;
    });

    // 단일 게시물 조회 시 (예: 편집 페이지)
    if (!page || !limit) {
      return {
        posts: sortedPosts,
        pagination: undefined
      };
    }

    // 페이지네이션이 필요한 경우 (예: 목록 페이지)
    const totalPosts = sortedPosts.length;
    const totalPages = Math.ceil(totalPosts / limit);
    const startIndex = (page - 1) * limit;
    const endIndex = startIndex + limit;

    return {
      posts: sortedPosts.slice(startIndex, endIndex),
      pagination: {
        currentPage: page,
        totalPages,
        totalPosts,
        hasNextPage: page < totalPages,
        hasPreviousPage: page > 1
      }
    };
  }, [templateData, page, limit]);

  return { 
    data: processedData, 
    isLoading,
    error
  };
};

// export const usePosts = (page: number = 1, limit: number = 5) => {
//   const api = withAuthInterceptor();
//   const templateId = useTemplateStore(state => state.selectedTemplateId);

//   return useQuery({
//     queryKey: ['posts', templateId, page, limit],
//     queryFn: async () => {
//       if (!templateId) throw new Error('Template not selected');
      
//       const { data } = await api.get<PostsResponse>(
//         `/api/posts/${templateId}?page=${page}&limit=${limit}`
//       );

//       return data.data;
//     },
//   });
// };


// 게시물 생성
export const useCreatePost = (templateId: string) => {
  const api = withAuthInterceptor();
  const queryClient = useQueryClient();
  const user = useUser();


  return useMutation({
    mutationFn: async (data: CreatePostData) => {
      const { data: responseData } = await api.post(
        `/api/posts/${templateId}`,
        data
      );
      return responseData.data;
    },
    onSuccess: async () => {
      toast.success('게시물이 작성되었습니다.');
      queryClient.invalidateQueries({ queryKey: templateKeys.data(user?.userId || '', templateId) });
      // queryClient.invalidateQueries({ queryKey: templateKeys.data(templateId) });

      //  // 먼저 쿼리들을 무효화
      // queryClient.invalidateQueries({
      //   queryKey: ['posts', templateId],
      //   exact: false
      // });
      // queryClient.invalidateQueries({
      //   queryKey: templateKeys.data(templateId)
      // });
      
      // // 그리고 강제로 리페치
      // await Promise.all([
      //   queryClient.refetchQueries({
      //     queryKey: ['posts', templateId],
      //     type: 'active',
      //     exact: false
      //   }),
      //   queryClient.refetchQueries({
      //     queryKey: templateKeys.data(templateId),
      //     type: 'active'
      //   })
      // ]);

    },
    onError: (error: any) => {
      toast.error(
        error.response?.data?.error || '게시물 작성에 실패했습니다.'
      );
    }
  });
};


export const useUpdatePost = (templateId: string) => {
  const api = withAuthInterceptor();
  const queryClient = useQueryClient();
  const user = useUser();


  return useMutation({
    mutationFn: async (data: {
      id: number;
      title: string;
      subtitle?: string;
      content: string;
      thumbnailUrl?: string;
    }) => {
      if (!templateId) throw new Error('Template ID is required');
      
      const { id, ...updateData } = data;
      const { data: responseData } = await api.put(
        `/api/posts/${templateId}/${id}`,  // URL 구조 수정
        updateData
      );
      return responseData.data;
    },
    onSuccess: () => {
      toast.success('게시물이 수정되었습니다.');
      queryClient.invalidateQueries({ queryKey: templateKeys.data(user?.userId || '', templateId) });
      // queryClient.invalidateQueries({ queryKey: templateKeys.data(templateId) });

      // queryClient.invalidateQueries({
      //   queryKey: ['posts', templateId],
      //   exact: false
      // });
      // queryClient.invalidateQueries({
      //   queryKey: templateKeys.data(templateId)
      // });
    },
    onError: (error: any) => {
      toast.error(
        error.response?.data?.error || '게시물 수정에 실패했습니다.'
      );
    }
  });
};

export const useDeletePost = () => {
  const api = withAuthInterceptor();
  const queryClient = useQueryClient();
  const templateId = useTemplateStore(state => state.selectedTemplateId);
  const user = useUser();


  return useMutation({
    mutationFn: async (postId: number) => {
      if (!templateId) throw new Error('Template not selected');
      
      const { data } = await api.delete(`/api/posts/${templateId}/${postId}`);
      return data;
    },
    onSuccess: () => {
      toast.success('게시물이 삭제되었습니다.');
      queryClient.invalidateQueries({ queryKey: templateKeys.data(user?.userId || '', templateId as string) });
      // queryClient.invalidateQueries({ queryKey: templateKeys.data(templateId as string) });

      // queryClient.invalidateQueries({ queryKey: ['posts', templateId] });
      // queryClient.invalidateQueries({
      //   queryKey: templateKeys.data(templateId as string)
      // });
    },
    onError: (error: any) => {
      toast.error(error.response?.data?.error || '게시물 삭제에 실패했습니다.');
    }
  });
};