import { useState } from 'react';
import { Loader, Trash2, X } from 'lucide-react';
import { useTemplateStore } from '@/shared/stores/templateStore';
import { useTemplates } from '@/builder/queries/templateQueries';
import { useDeleteImage } from '@/builder/queries/imageQueries';
import toast from 'react-hot-toast';
import ImageProgressBar from './ImageProgressBarProps';

interface LibraryImage {
  id: string;
  path: string;
  size: number;
}

interface LibraryTabProps {
  onSelect: (url: string) => void;
}

const LibraryTab = ({ onSelect }: LibraryTabProps) => {
  const { selectedTemplateId: templateId } = useTemplateStore();
  const { data: templates, isLoading } = useTemplates();
  const deleteImage = useDeleteImage(templateId || '');
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [selectedImageId, setSelectedImageId] = useState<string | null>(null);

  const currentTemplate: any = templates?.find(template => template.templateId === templateId);
  const images = currentTemplate?.images || [];
  const imageDomain = process.env.REACT_APP_R2_PUBLIC_URL || '';

  const handleDeleteClick = (e: React.MouseEvent, imageId: string) => {
    e.stopPropagation();
    setSelectedImageId(imageId);
    setDeleteModalOpen(true);
  };

  const handleDeleteConfirm = async () => {
    if (!selectedImageId) return;

    try {
      await deleteImage.mutateAsync(selectedImageId);
      toast.success('이미지가 삭제되었습니다');
    } catch (error) {
      toast.error('이미지 삭제 중 오류가 발생했습니다');
    }
    setDeleteModalOpen(false);
    setSelectedImageId(null);
  };

  const closeModal = () => {
    setDeleteModalOpen(false);
    setSelectedImageId(null);
  };

  if (isLoading) {
    return (
      <div className="flex justify-center py-8">
        <Loader className="w-6 h-6 animate-spin text-blue-500" />
      </div>
    );
  }

  return (
    <div className="space-y-4">
      <ImageProgressBar
        current={images.length} 
        className="px-2"
      />
      
      {images.length === 0 ? (
        <div className="text-center py-8 text-gray-500">
          업로드된 이미지가 없습니다
        </div>
      ) : (
        <div className="grid grid-cols-3 gap-4">
          {images.map((image: any) => (
            <div
              key={image.id}
              className="relative group"
            >
              <div 
                className="cursor-pointer aspect-video overflow-hidden rounded-lg"
                onClick={() => onSelect(`${imageDomain}/${image.path}`)}
              >
                <img
                  src={`${imageDomain}/${image.path}`}
                  alt=""
                  className="w-full h-full object-cover"
                />
                <div className="absolute inset-0 bg-black/50 opacity-0 group-hover:opacity-100 transition-opacity" />
              </div>
              <button
                className="absolute bottom-2 right-2 p-1.5 bg-red-500 rounded text-white opacity-0 group-hover:opacity-100 transition-opacity hover:bg-red-600"
                onClick={(e) => handleDeleteClick(e, image.id)}
                disabled={deleteImage.isPending}
              >
                <Trash2 size={16} />
              </button>
            </div>
          ))}
        </div>
      )}

      {/* 삭제 확인 모달 */}
      {deleteModalOpen && (
        <>
          {/* 모달 배경 */}
          <div 
            className="fixed inset-0 bg-black/50 z-50"
            onClick={closeModal}
          />
          
          {/* 모달 내용 */}
          <div className="fixed left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 w-full max-w-md bg-white rounded-lg shadow-lg z-50 p-6">
            <div className="flex justify-between items-center mb-4">
              <h3 className="text-lg font-semibold">이미지 삭제</h3>
              <button
                onClick={closeModal}
                className="p-1 hover:bg-gray-100 rounded-full transition-colors"
              >
                <X size={20} />
              </button>
            </div>
            
            <div className="py-4">
              이미지를 삭제하시겠습니까?
              <br />
              홈페이지에서 이미지가 사용중인 경우 보이지 않게 됩니다.
            </div>
            
            <div className="flex justify-end gap-2 mt-4">
              <button
                onClick={closeModal}
                className="px-4 py-2 text-sm text-gray-600 bg-gray-100 rounded hover:bg-gray-200 transition-colors"
                disabled={deleteImage.isPending}
              >
                취소
              </button>
              <button
                onClick={handleDeleteConfirm}
                className="px-4 py-2 text-sm text-white bg-red-500 rounded hover:bg-red-600 transition-colors"
                disabled={deleteImage.isPending}
              >
                {deleteImage.isPending ? '삭제 중...' : '삭제'}
              </button>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default LibraryTab;

// import { useEffect } from 'react';
// import { Loader } from 'lucide-react';
// import { useTemplateStore } from '@/shared/stores/templateStore';
// import { useTemplates } from '@/builder/queries/templateQueries';
// import ImageProgressBar from './ImageProgressBarProps';

// interface LibraryImage {
//   id: string;
//   path: string;
//   size: number;
// }

// interface LibraryTabProps {
//   onSelect: (url: string) => void;
// }

// const LibraryTab = ({ onSelect }: LibraryTabProps) => {
//   const { selectedTemplateId: templateId } = useTemplateStore();
//   const { data: templates, isLoading } = useTemplates();

//   // 현재 선택된 템플릿의 이미지들 찾기
//   const currentTemplate: any = templates?.find(template => template.templateId === templateId);
//   const images = currentTemplate?.images || [];

//   if (isLoading) {
//     return (
//       <div className="flex justify-center py-8">
//         <Loader className="w-6 h-6 animate-spin text-blue-500" />
//       </div>
//     );
//   }

//   if (images.length === 0) {
//     return (
//       <div className="text-center py-8 text-gray-500">
//         업로드된 이미지가 없습니다
//       </div>
//     );
//   }

//   return (
//     <div className="space-y-4">
//       <ImageProgressBar 
//         current={images.length} 
//         className="px-2"
//       />
      
//       {images.length === 0 ? (
//         <div className="text-center py-8 text-gray-500">
//           업로드된 이미지가 없습니다
//         </div>
//       ) : (
//         <div className="grid grid-cols-3 gap-4">
//           {images.map((image: any) => (
//             <div
//               key={image.id}
//               className="relative group cursor-pointer aspect-video overflow-hidden rounded-lg"
//               onClick={() => onSelect(`${process.env.REACT_APP_R2_PUBLIC_URL}/${image.path}`)}
//             >
//               <img
//                 src={`${process.env.REACT_APP_R2_PUBLIC_URL}/${image.path}`}
//                 alt=""
//                 className="w-full h-full object-cover"
//               />
//               <div className="absolute inset-0 bg-black/50 opacity-0 group-hover:opacity-100 transition-opacity" />
//             </div>
//           ))}
//         </div>
//       )}
//     </div>
//   );
// };

// export default LibraryTab;