import ImageWrapper from "@/shared/components/ImageWrapper";
import TextWrapper from "@/shared/components/TextWrapper";
import { useGroupAnimation } from "@/shared/hooks/animation/useGroupAnimation";
import DivWithAnimation from "../common/DivWithAnimation";


const Contact01 = ({
  header,
  content,
  images,
  editablePath
}: any) => {
  const groupId = useGroupAnimation(100);

  return (
      <div className="flex flex-col md:flex-row justify-between gap-12">
        <div className="w-full md:w-[45%] space-y-10">
          <TextWrapper
            content={header.title}
            className="mt-3 md:mt-4"
            editable={editablePath ? {
              type: 'richText',
              path: `${editablePath}.header.title`
            } : undefined}
          />
 
          <ul className="space-y-3">
            {content.items.map((item: any, index: any) => (
              <DivWithAnimation groupId >
                <li 
                  key={index}
                  className="text-base text-gray-500 font-light flex items-center gap-2"
                >
                  <span className="text-gray-500">✓</span>
                  <TextWrapper
                    content={item.description}
                    editable={editablePath ? {
                      type: 'plainText',
                      path: `${editablePath}.content.items[${index}].description`
                    } : undefined}
                    animation="none"
                  />
                </li>
              </DivWithAnimation>
            ))}
          </ul>
 
          <div className="relative w-[95%] md:w-48 h-14">
            <a
              href="/contact"
              className="relative flex justify-center items-center w-full h-full bg-blue-800  text-white text-center font-medium transition-colors duration-200 z-10"
            >
              <TextWrapper
                content={content.button}
                editable={editablePath ? {
                  type: 'plainText',
                  path: `${editablePath}.content.button`
                } : undefined}
                animation="none"
              />
            </a>
            <div className="absolute -bottom-2.5 -right-2.5 w-full h-full bg-blue-300 z-0"></div>
          </div>
        </div>
 
        <div className="w-full md:w-[43%] flex items-center">
          <ImageWrapper
            src={images.main.src}
            styles={images.main.styles}
            alt="hero"
            className="w-full aspect-[16/10] shadow-custom-xl"
            loading="lazy"
            editable={editablePath ? {
              type: 'image',
              path: `${editablePath}.images.main`
            } : undefined}
            animation="none"
          />
        </div>
      </div>
  );
 };

export default Contact01;