import { ChevronDown, ChevronUp } from 'lucide-react';
import { useState } from 'react';
import toast from 'react-hot-toast';

export interface SignupParams {
  email: string;
  password: string;
  companyName: string;
  taxId?: string;
  phoneNumber?: string;
  agreedToService: boolean;
  agreedToPrivacy: boolean;
  agreedToMarketing: boolean;
}

export interface SignupFormProps {
  onSubmit: (params: SignupParams) => void;
  isSubmitting?: boolean;
}

export interface SignupFormState {
  email: string;
  password: string;
  companyName: string;
  taxId: string;
  phoneNumber: string;
  message: string;
  showServiceTerms: boolean;    // false에서 boolean으로 변경
  showPrivacyTerms: boolean;    // false에서 boolean으로 변경
  isAbove14: boolean;
  agreedToService: boolean;
  agreedToPrivacy: boolean;
  agreedToMarketing: boolean;

  isTermsExpanded: boolean;
  agreedToAll: boolean;
}


export type MessageType = 'error' | 'success';


const SignupForm = ({ onSubmit, isSubmitting }: SignupFormProps) => {

  const [formState, setFormState] = useState<SignupFormState>({
    email: '',
    password: '',
    companyName: '',
    taxId: '',
    phoneNumber: '',
    message: '',
    showServiceTerms: false,     // 초기값만 false로 설정
    showPrivacyTerms: false,     // 초기값만 false로 설정
    isAbove14: false,
    agreedToService: false,
    agreedToPrivacy: false,
    agreedToMarketing: false,

    isTermsExpanded: false,
    agreedToAll: false
  });

  const handleAgreeToAll = (checked: boolean) => {
    setFormState(prev => ({
      ...prev,
      agreedToAll: checked,
      agreedToService: checked,
      agreedToPrivacy: checked,
      agreedToMarketing: checked
    }));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (isSubmitting) return;

    setFormState(prev => ({ ...prev, message: '' }));
  
    if (!formState.isAbove14) {
      setFormState(prev => ({ ...prev, message: '만 14세 이상만 가입할 수 있습니다.' }));
      toast.error('만 14세 이상만 가입할 수 있습니다.')
      return;
    }
  
    if (!formState.agreedToService || !formState.agreedToPrivacy) {
      setFormState(prev => ({ ...prev, message: '필수 약관에 동의해주세요.' }));
      toast.error('필수 약관에 동의해주세요.')
      return;
    }
  
    if (!formState.email || !formState.password || !formState.companyName ) {
      setFormState(prev => ({ ...prev, message: '모든 내용을 입력해 주세요.' }));
      toast.error('모든 내용을 입력해 주세요.')
      return;
    }
  
    if (formState.password.length < 8) {
      setFormState(prev => ({ ...prev, message: '비밀번호는 8자 이상이어야 합니다.' }));
      toast.error('비밀번호는 8자 이상이어야 합니다.')
      return;
    }
  
    try {
      await onSubmit({
        email: formState.email,
        password: formState.password,
        companyName: formState.companyName,
        taxId: formState.taxId,
        phoneNumber: formState.phoneNumber,
        agreedToService: formState.agreedToService,
        agreedToPrivacy: formState.agreedToPrivacy,
        agreedToMarketing: formState.agreedToMarketing
      });
      setFormState(prev => ({ ...prev, message: '가입 성공!' }));
    } catch (error) {
      setFormState(prev => ({ ...prev, message: '가입에 실패했습니다.' }));
    }
  };

  const TermsModal = ({ title, content, onClose }: { title: string; content: string; onClose: () => void }) => (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white p-6 rounded-lg w-11/12 max-w-lg max-h-[80vh] overflow-y-auto">
        <h3 className="text-lg font-bold mb-4">{title}</h3>
        <div className="text-sm whitespace-pre-line">{content}</div>
        <button 
          onClick={onClose}
          className="mt-4 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
        >
          닫기
        </button>
      </div>
    </div>
  );

  const serviceTermsContent = `[내용]
  1. 서비스 제공 목적
  2. 회원의 의무
  3. 서비스 이용 규칙
  ...`;

  const privacyTermsContent = `[내용]
  1. 수집하는 개인정보 항목
  2. 개인정보의 수집 및 이용목적
  3. 개인정보의 보유 및 이용기간
  ...`;

  // Terms section component
  const TermsSection = () => (
    <div className="space-y-4">
      {/* Agree to all checkbox */}
      <div className="flex items-center justify-between rounded">
        <div className="flex items-center gap-2">
          <input
            type="checkbox"
            id="agreeToAll"
            checked={formState.agreedToAll}
            onChange={(e) => handleAgreeToAll(e.target.checked)}
            className="rounded"
          />
          <label htmlFor="agreeToAll" className="font-medium">모든 약관에 동의합니다</label>
        </div>
        <button
          type="button"
          onClick={() => setFormState(prev => ({ ...prev, isTermsExpanded: !prev.isTermsExpanded }))}
          className="text-gray-500 hover:text-gray-700"
        >
          {formState.isTermsExpanded ? <ChevronUp size={20} /> : <ChevronDown size={20} />}
        </button>
      </div>

      {/* Collapsible terms content */}
      {formState.isTermsExpanded && (
        <div className="space-y-3 pl-4">
          <div className="flex items-center gap-2">
            <input
              type="checkbox"
              id="serviceTerms"
              checked={formState.agreedToService}
              onChange={(e) => {
                setFormState(prev => ({
                  ...prev,
                  agreedToService: e.target.checked,
                  agreedToAll: e.target.checked && prev.agreedToPrivacy && prev.agreedToMarketing
                }));
              }}
            />
            <label htmlFor="serviceTerms">서비스 이용약관 동의 (필수)</label>
            <a
              href="https://husky-coffee-14c.notion.site/149c3ee6db8d8009bd14f4c2a94b5de9"
              target="_blank"
              className="text-blue-500 hover:underline text-sm"
            >
              [약관보기]
            </a>
          </div>

          <div className="flex items-center gap-2">
            <input
              type="checkbox"
              id="privacyTerms"
              checked={formState.agreedToPrivacy}
              onChange={(e) => {
                setFormState(prev => ({
                  ...prev,
                  agreedToPrivacy: e.target.checked,
                  agreedToAll: e.target.checked && prev.agreedToService && prev.agreedToMarketing
                }));
              }}
            />
            <label htmlFor="privacyTerms">개인정보 처리방침 동의 (필수)</label>
            <a
              href="https://husky-coffee-14c.notion.site/149c3ee6db8d8019a5fcdfb5fa1bff1e"
              target="_blank"
              className="text-blue-500 hover:underline text-sm"
            >
              [약관보기]
            </a>
          </div>

          <div className="flex items-center gap-2">
            <input
              type="checkbox"
              id="marketingTerms"
              checked={formState.agreedToMarketing}
              onChange={(e) => {
                setFormState(prev => ({
                  ...prev,
                  agreedToMarketing: e.target.checked,
                  agreedToAll: e.target.checked && prev.agreedToService && prev.agreedToPrivacy
                }));
              }}
            />
            <label htmlFor="marketingTerms">마케팅 정보 수신 동의 (선택)</label>
          </div>
        </div>
      )}
    </div>
  );


  return (
    <div className="p-8 max-w-sm w-full mx-auto bg-white rounded-xl shadow-lg">
      <h2 className="text-2xl font-bold mb-4">회원가입</h2>
      <form onSubmit={handleSubmit} className="space-y-4">
        <div>
          <label htmlFor="email" className="block text-sm font-medium text-gray-700">이메일</label>
          <input
            id="email"
            type="email"
            value={formState.email}
            onChange={(e) => setFormState(prev => ({ ...prev, email: e.target.value }))}
            required
            className="mt-1 block w-full border-gray-300 rounded-md shadow-sm"
          />
        </div>

        <div>
          <label htmlFor="password" className="block text-sm font-medium text-gray-700">비밀번호 (8자 이상)</label>
          <input
            id="password"
            type="password"
            value={formState.password}
            onChange={(e) => setFormState(prev => ({ ...prev, password: e.target.value }))}
            required
            className="mt-1 block w-full border-gray-300 rounded-md shadow-sm"
          />
        </div>

        <div>
          <label htmlFor="company" className="block text-sm font-medium text-gray-700">회사명</label>
          <input
            id="company"
            type="text"
            value={formState.companyName}
            onChange={(e) => setFormState(prev => ({ ...prev, companyName: e.target.value }))}
            required
            className="mt-1 block w-full border-gray-300 rounded-md shadow-sm"
          />
        </div>

        <div>
          <label htmlFor="company" className="block text-sm font-medium text-gray-700">연락처</label>
          <input
            id="phoneNumber"
            type="number"
            value={formState.phoneNumber}
            onChange={(e) => setFormState(prev => ({ ...prev, phoneNumber: e.target.value }))}
            required
            placeholder="010-0000-0000"
            className="mt-1 block w-full border-gray-300 rounded-md shadow-sm placeholder:text-sm placeholder:font-light"
          />
        </div>

        <div>
          <label htmlFor="company" className="block text-sm font-medium text-gray-700">사업자등록번호</label>
          <input
            id="phoneNumber"
            type="string"
            value={formState.taxId}
            onChange={(e) => setFormState(prev => ({ ...prev, taxId: e.target.value }))}
            placeholder="세금계산서 발행이 필요한 경우 작성"
            className="mt-1 block w-full border-gray-300 rounded-md shadow-sm placeholder:text-sm placeholder:font-light"
          />
        </div>


        <div className='text-sm font-light space-y-2'>
          {/* 14세 이상 체크박스 추가 */}
          <div className="flex items-center gap-2">
            <input
              type="checkbox"
              id="ageCheck"
              checked={formState.isAbove14}
              onChange={(e) => setFormState(prev => ({ ...prev, isAbove14: e.target.checked }))}
            />
            <label htmlFor="ageCheck">만 14세 이상입니다</label>
          </div>

          <TermsSection />
        </div>

        {/* {formState.message && (
          <p className={`${formState.message.includes('성공') ? 'text-green-500' : 'text-red-500'}`}>
            {formState.message}
          </p>
        )} */}

        {/* <button
          type="submit"
          className="w-full bg-blue-500 text-white py-2 px-4 rounded-md hover:bg-blue-600"
        >
          가입하기
        </button> */}

        <button
          type="submit"
          disabled={isSubmitting}
          className={`w-full py-2 px-4 rounded-md text-white
            ${isSubmitting 
              ? 'bg-blue-300 cursor-not-allowed' 
              : 'bg-blue-500 hover:bg-blue-600'
            }`}
        >
          {isSubmitting ? '가입 처리중...' : '가입하기'}
        </button>
      </form>


      {/* {formState.showServiceTerms && (
        <TermsModal
          title="서비스 이용약관"
          content={serviceTermsContent}
          onClose={() => setFormState(prev => ({ ...prev, showServiceTerms: false }))}
        />
      )}

      {formState.showPrivacyTerms && (
        <TermsModal
          title="개인정보 처리방침"
          content={privacyTermsContent}
          onClose={() => setFormState(prev => ({ ...prev, showPrivacyTerms: false }))}
        />
      )} */}
    </div>
  );
};

export default SignupForm;