import TextWrapper from "@/shared/components/TextWrapper";
import { useGroupAnimation } from "@/shared/hooks/animation/useGroupAnimation";
import DOMPurify from "dompurify";

export interface SectionHeaderProps {
  header: {
    title: string;
    subtitle: string;
  }
  styles?: string;
  editablePath?: string;
}

const SectionHeader = ({ header, styles, editablePath }: SectionHeaderProps) => {

  const groupId = useGroupAnimation(100);

  return (
    <div className={`mb-10 md:mb-12 pb-10 md:pb-12 border-b border-slate-200 tracking-tighter ${styles || ''}`}>
      <TextWrapper
        content={header.title}
        editable={editablePath ? {
          type: 'richText',
          path: `${editablePath}.header.title`
        } : undefined}
        animation={{
          type: 'slideUp',
          groupId: groupId,
        }}
      />

      <TextWrapper
        content={header.subtitle}
        className="mt-2"
        editable={editablePath ? {
          type: 'richText',
          path: `${editablePath}.header.subtitle`
        } : undefined}
        animation={{
          type: 'slideUp',
          groupId: groupId,
        }}
      />
    </div>
  );
 };

export default SectionHeader;
