import { useImageUpload } from '@/builder/queries/imageQueries';
import { useTemplateStore } from '@/shared/stores/templateStore';
import React, { useState } from 'react';

interface FooterCompany {
  name?: {
    korean?: string;
    english?: string;
  };
  ceo?: string;
  registration?: string;
  privacy_officer?: string;
  address?: string;
  contact?: {
    phone?: string;
    email?: string;
  };
  logo?: {
    white?: string;
    black?: string;
  };
}

interface FooterData {
  company: FooterCompany;
}

interface FooterEditorProps {
  content?: {
    company?: FooterCompany;
  };
  onChange?: (updatedContent: FooterData) => void;
}


const FooterEditor: React.FC<FooterEditorProps> = ({ content = { company: {} }, onChange }) => {
  const [footerData, setFooterData] = useState<FooterData>({
    company: content?.company || {}
  });
  const { selectedTemplateId: templateId } = useTemplateStore();
  const imageUpload = useImageUpload(templateId || '');
  // const imageUpload = useImageUpload();


  const handleChange = (field: string, value: string) => {
    const paths = field.split('.');
    const updatedData = { ...footerData };

    // Ensure company exists
    if (!updatedData.company) {
      updatedData.company = {};
    }
    
    let current: any = updatedData;

    for (let i = 0; i < paths.length - 1; i++) {
      if (!current[paths[i]]) {
        current[paths[i]] = {};
      }
      current = current[paths[i]];
    }

    const lastPath = paths[paths.length - 1];
    if (value.trim() === '') {
      delete current[lastPath];
    } else {
      current[lastPath] = value;
    }

    // Clean up empty objects
    if (Object.keys(current).length === 0) {
      let temp: any = updatedData;
      for (let i = 0; i < paths.length - 1; i++) {
        if (Object.keys(temp[paths[i]]).length === 0) {
          delete temp[paths[i]];
        }
        temp = temp[paths[i]];
      }
    }

    if (onChange) {
      onChange(updatedData);
      setFooterData(updatedData);
    }
  };

  const handleLogoUpload = async (e: React.ChangeEvent<HTMLInputElement>, type: 'white' | 'black') => {
    const file = e.target.files?.[0];
    if (!file) return;
  
    try {
      const result = await imageUpload.mutateAsync(file);
      if (result?.url) {
        // R2 업로드 URL 사용
        const r2Path = result.url;  // KV에서 반환된 R2 URL 사용
        
        // 데이터 업데이트
        handleChange(`company.logo.${type}`, r2Path);
      }
    } catch (error) {
      console.error('Logo upload error:', error);
    }
  };


  return (
    <div className="w-full space-y-4 p-4 border rounded-lg bg-gray-50">
      <div className="space-y-4">

      <div className="bg-white p-4 rounded shadow-sm border">
          <label className="block text-sm font-medium text-gray-700 mb-4">
            로고 이미지
          </label>
          
          {/* 화이트 버전 로고 */}
          <div className="mb-4">
            <label className="block text-sm text-gray-600 mb-2">
              로고 (화이트버전 - 헤더에 적용)
            </label>
            <div className="space-y-2">
              <input
                type="file"
                accept="image/*"
                onChange={(e) => handleLogoUpload(e, 'white')}
                className="hidden"
                id="whiteLogo"
              />
              <label
                htmlFor="whiteLogo"
                className="inline-block px-4 py-2 bg-blue-500 text-white rounded cursor-pointer hover:bg-blue-600 disabled:opacity-50"
              >
                {imageUpload.isPending ? '업로드 중...' : '업로드'}
              </label>
              {footerData.company.logo?.white && (
                <div className="mt-2">
                  <img
                    src={footerData.company.logo.white}
                    alt="화이트 로고 미리보기"
                    className="max-h-20 object-contain bg-gray-800 p-2 rounded"
                  />
                  <button
                    type="button"
                    onClick={() => handleChange('company.logo.white', '')}
                    className="mt-2 px-3 py-1 text-sm text-red-500 hover:text-red-600"
                  >
                    이미지 제거
                  </button>
                </div>
              )}
            </div>
          </div>

          {/* 블랙 버전 로고 */}
          <div>
            <label className="block text-sm text-gray-600 mb-2">
              로고 (블랙버전 - 푸터에 적용)
            </label>
            <div className="space-y-2">
              <input
                type="file"
                accept="image/*"
                onChange={(e) => handleLogoUpload(e, 'black')}
                className="hidden"
                id="blackLogo"
              />
              <label
                htmlFor="blackLogo"
                className="inline-block px-4 py-2 bg-blue-500 text-white rounded cursor-pointer hover:bg-blue-600 disabled:opacity-50"
              >
                {imageUpload.isPending ? '업로드 중...' : '업로드'}
              </label>
              {footerData.company.logo?.black && (
                <div className="mt-2">
                  <img
                    src={footerData.company.logo.black}
                    alt="블랙 로고 미리보기"
                    className="max-h-20 object-contain bg-white p-2 rounded"
                  />
                  <button
                    type="button"
                    onClick={() => handleChange('company.logo.black', '')}
                    className="mt-2 px-3 py-1 text-sm text-red-500 hover:text-red-600"
                  >
                    이미지 제거
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="bg-white p-4 rounded shadow-sm border">
          <label className="block text-sm font-medium text-gray-700 mb-2">
            회사명 (한글)
          </label>
          <input
            type="text"
            value={footerData.company?.name?.korean || ''}
            onChange={(e) => handleChange('company.name.korean', e.target.value)}
            className="w-full py-2 px-3 border rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
            placeholder="회사명을 입력하세요"
          />
        </div>

        <div className="bg-white p-4 rounded shadow-sm border">
          <label className="block text-sm font-medium text-gray-700 mb-2">
            회사명 (영문)
          </label>
          <input
            type="text"
            value={footerData.company?.name?.english || ''}
            onChange={(e) => handleChange('company.name.english', e.target.value)}
            className="w-full py-2 px-3 border rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
            placeholder="영문 회사명을 입력하세요"
          />
        </div>

        <div className="bg-white p-4 rounded shadow-sm border">
          <label className="block text-sm font-medium text-gray-700 mb-2">
            대표자명
          </label>
          <input
            type="text"
            value={footerData.company?.ceo || ''}
            onChange={(e) => handleChange('company.ceo', e.target.value)}
            className="w-full py-2 px-3 border rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
            placeholder="대표자명을 입력하세요"
          />
        </div>

        <div className="bg-white p-4 rounded shadow-sm border">
          <label className="block text-sm font-medium text-gray-700 mb-2">
            사업자등록번호
          </label>
          <input
            type="text"
            value={footerData.company?.registration || ''}
            onChange={(e) => handleChange('company.registration', e.target.value)}
            className="w-full py-2 px-3 border rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
            placeholder="사업자등록번호를 입력하세요"
          />
        </div>

        <div className="bg-white p-4 rounded shadow-sm border">
          <label className="block text-sm font-medium text-gray-700 mb-2">
            개인정보 보호책임자
          </label>
          <input
            type="text"
            value={footerData.company?.privacy_officer || ''}
            onChange={(e) => handleChange('company.privacy_officer', e.target.value)}
            className="w-full py-2 px-3 border rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
            placeholder="개인정보 보호책임자를 입력하세요"
          />
        </div>

        <div className="bg-white p-4 rounded shadow-sm border">
          <label className="block text-sm font-medium text-gray-700 mb-2">
            주소
          </label>
          <input
            type="text"
            value={footerData.company?.address || ''}
            onChange={(e) => handleChange('company.address', e.target.value)}
            className="w-full py-2 px-3 border rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
            placeholder="주소를 입력하세요"
          />
        </div>

        <div className="bg-white p-4 rounded shadow-sm border">
          <label className="block text-sm font-medium text-gray-700 mb-2">
            전화번호
          </label>
          <input
            type="text"
            value={footerData.company?.contact?.phone || ''}
            onChange={(e) => handleChange('company.contact.phone', e.target.value)}
            className="w-full py-2 px-3 border rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
            placeholder="전화번호를 입력하세요"
          />
        </div>

        <div className="bg-white p-4 rounded shadow-sm border">
          <label className="block text-sm font-medium text-gray-700 mb-2">
            이메일
          </label>
          <input
            type="text"
            value={footerData.company?.contact?.email || ''}
            onChange={(e) => handleChange('company.contact.email', e.target.value)}
            className="w-full py-2 px-3 border rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
            placeholder="이메일을 입력하세요"
          />
        </div>
      </div>
    </div>
  );
};

export default FooterEditor;