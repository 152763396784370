import React, { useState } from 'react';
import { Plus, Trash2, PlusCircle, MinusCircle } from 'lucide-react';

export interface HistoryContent {
  items: HistoryItem[];
}

export interface HistoryItem {
  year: number;
  events: string[];
}

interface HistoryEditorProps {
  initialData?: HistoryItem[];
  onSave: (items: HistoryItem[]) => void;
}

const HistoryEditor = ({ initialData = [], onSave }: HistoryEditorProps) => {
  const [historyItems, setHistoryItems] = useState<HistoryItem[]>(initialData);

  const addYear = () => {
    const newYear = {
      year: new Date().getFullYear(),
      events: ['새로운 이벤트를 입력하세요']
    };
    setHistoryItems([...historyItems, newYear]);
  };

  const removeYear = (yearIndex: number) => {
    setHistoryItems(historyItems.filter((_, index) => index !== yearIndex));
  };

  const updateYear = (yearIndex: number, newYear: string) => {
    const updated = historyItems.map((item, index) => 
      index === yearIndex ? { ...item, year: parseInt(newYear) || new Date().getFullYear() } : item
    );
    setHistoryItems(updated);
  };

  const addEvent = (yearIndex: number) => {
    const updated = historyItems.map((item, index) => 
      index === yearIndex 
        ? { ...item, events: [...item.events, '새로운 이벤트를 입력하세요'] }
        : item
    );
    setHistoryItems(updated);
  };

  const removeEvent = (yearIndex: number, eventIndex: number) => {
    const updated = historyItems.map((item, index) => 
      index === yearIndex 
        ? { ...item, events: item.events.filter((_, eIndex) => eIndex !== eventIndex) }
        : item
    );
    setHistoryItems(updated);
  };

  const updateEvent = (yearIndex: number, eventIndex: number, newText: string) => {
    const updated = historyItems.map((item, index) => 
      index === yearIndex 
        ? {
            ...item,
            events: item.events.map((event, eIndex) => 
              eIndex === eventIndex ? newText : event
            )
          }
        : item
    );
    setHistoryItems(updated);
  };

  const handleSave = () => {
    onSave(historyItems);
  };

  return (
    <div className="w-full space-y-4">
      <div className="space-y-4">
        {historyItems.map((item, yearIndex) => (
          <div key={yearIndex} className="border rounded-lg p-2 bg-white shadow-sm">
            <div className="flex items-center gap-4 mb-4">
              <input
                type="number"
                value={item.year}
                onChange={(e) => updateYear(yearIndex, e.target.value)}
                className="w-24 py-1 px-2 border rounded"
              />
              <button
                onClick={() => removeYear(yearIndex)}
                className="p-2 text-red-600 hover:bg-red-50 rounded"
                title="연도 삭제"
              >
                <Trash2 className="w-5 h-5" />
              </button>
            </div>

            <div className="space-y-3 pl-4">
              {item.events.map((event, eventIndex) => (
                <div key={eventIndex} className="flex items-center gap-2">
                  <input
                    type="text"
                    value={event}
                    onChange={(e) => updateEvent(yearIndex, eventIndex, e.target.value)}
                    className="flex-1 py-1 px-2 border rounded"
                    placeholder="이벤트 내용을 입력하세요"
                  />
                  <button
                    onClick={() => removeEvent(yearIndex, eventIndex)}
                    className="p-1 text-red-600 hover:bg-red-50 rounded"
                    title="이벤트 삭제"
                  >
                    <MinusCircle className="w-4 h-4" />
                  </button>
                </div>
              ))}
              <button
                onClick={() => addEvent(yearIndex)}
                className="flex items-center gap-2 text-blue-600 hover:text-blue-700"
              >
                <PlusCircle className="w-4 h-4" />
                <span>이벤트 추가</span>
              </button>
            </div>
          </div>
        ))}

        <button
          onClick={addYear}
          className="flex items-center gap-2 text-blue-600 hover:text-blue-700 mt-4"
        >
          <Plus className="w-5 h-5" />
          <span>새로운 연도 추가</span>
        </button>
      </div>

      <div className="flex justify-end">
        <button
          onClick={handleSave}
          className="px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700"
        >
          임시저장
        </button>
      </div>
    </div>
  );
};

export default HistoryEditor;