import { usePageStore } from '@/shared/stores/pageStore'
import { EditableComponentType } from '@/shared/types/common';

export const useEditableComponent = () => {
  const { setSelectedComponent } = usePageStore();

  const handleComponentSelect = (data: {
    type: EditableComponentType;
    path: string;
    content: string;
    styles?: any;
  }) => {
    if (!data) return;
    
    setSelectedComponent({
      id: data.path,
      type: data.type,
      path: data.path,
      content: data.content,
      styles: data.styles
    });
  };

  return { handleComponentSelect };
};