import { useTemplateStore } from "@/shared/stores/templateStore";
import CardGrid01 from "@/templates/components/cardgrid/CardGrid01";
import SectionContainer from "@/templates/components/common/SectionContainer";
import SectionHeader from "@/templates/components/common/SectionHeader";
import Contact01 from "@/templates/components/contact/Contact01";
import Features02 from "@/templates/components/features/Features02";
import Bubble from "@/templates/components/hero/Bubble01";
import Hero02 from "@/templates/components/hero/Hero02";


function Home() {
  const templateData = useTemplateStore(state => state.templateData);

  return (
    <div>
      <Hero02
        {...(templateData.pages.home.hero)}
        editablePath="pages.home.hero"
      />
      
      <div className="overflow-hidden">
        <Bubble
          {...(templateData.pages.home.bubble)}
          editablePath="pages.home.bubble"
        />
      </div>

      <SectionContainer
        backgroundImage={templateData.pages.home.features.images?.background}
        editablePath="pages.home.features"
      >
        <SectionHeader
          {...(templateData.pages.home.features)}
          styles='border-none !pb-0'
          editablePath="pages.home.features"
        />
        <CardGrid01
          {...(templateData.pages.home.features)}
          editablePath="pages.home.features"
          variant='transparent'
        />
      </SectionContainer>

      <SectionContainer>
        <SectionHeader
          {...(templateData.pages.home.overview)}
          styles='border-none !pb-0'
          editablePath="pages.home.overview"
        />
        <Features02
          {...(templateData.pages.home.overview)}
          editablePath="pages.home.overview"
        />
      </SectionContainer>

      <SectionContainer>
        <Contact01
          {...(templateData.pages.home.contact)}
          editablePath="pages.home.contact"
        />
      </SectionContainer>

    </div>
  );
}

export default Home;