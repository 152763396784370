import ImageWrapper from "@/shared/components/ImageWrapper";
import TextWrapper from "@/shared/components/TextWrapper";
import { useGroupAnimation } from "@/shared/hooks/animation/useGroupAnimation";
import DivWithAnimation from "../common/DivWithAnimation";



const Features02 = ({
  content,
  images,
  editablePath
}: any) => {
  const groupId = useGroupAnimation(100);

  return (
    <div className="break-keep">
      <div className="mt-10">
        <ImageWrapper
          src={images.main.src}
          styles={images.main.styles}
          alt="hero"
          className="w-full aspect-[16/10] md:aspect-[16/7] shadow-custom-lg"
          loading="lazy"
          editable={editablePath ? {
            type: 'image',
            path: `${editablePath}.images.main`
          } : undefined}
          animation="none"
        />
      </div>
 
      <div className="mt-10 flex flex-col md:flex-row justify-between items-center md:items-start gap-8">
        {content.items.map((item: any, index: any) => (
          <DivWithAnimation
            key={index}
            className="md:w-[45%]"
            groupId={groupId}
          >
          {/* <div key={index} className="md:w-[45%]"> */}
            <TextWrapper
              content={item.title}
              editable={editablePath ? {
                type: 'richText',
                path: `${editablePath}.content.items[${index}].title`
              } : undefined}
              animation="none"
            />

            <TextWrapper
              content={item.description}
              className="mt-3 md:mt-4"
              editable={editablePath ? {
                type: 'richText',
                path: `${editablePath}.content.items[${index}].description`
              } : undefined}
              animation="none"
            />
          {/* </div> */}
          </DivWithAnimation>

        ))}
      </div>
    </div>
  );
 };

export default Features02;