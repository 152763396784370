import React, { useState } from 'react';

interface ContactEditorProps {
  content?: {
    kakao?: string;
    instagram?: string;
    youtube?: string;
    phone?: string;
    naver?: string;
  };
  onChange?: (updatedContent: any) => void;
}

const ContactEditor: React.FC<ContactEditorProps> = ({ content = {}, onChange }) => {
  const [contacts, setContacts] = useState(content);

  // URL 업데이트 핸들러
  const handleUrlChange = (type: string, value: string) => {
    let finalValue = value.trim();

    // phone 타입일 때 특별 처리
    if (type === 'phone' && finalValue) {
      finalValue = `tel:${finalValue.replace('tel:', '')}`;
    }
    
    const updatedContacts = {
      ...contacts,
      [type]: finalValue || undefined
    };
    
    if (onChange) {
      onChange(updatedContacts);
      setContacts(updatedContacts);
    }
  };


  return (
    <div className="w-full space-y-4 p-4 border rounded-lg bg-gray-50">
      <div className="space-y-4">
        <div className="bg-white p-4 rounded shadow-sm border">
          <label className="block text-sm font-medium text-gray-700 mb-2">
            카카오톡 상담 링크
          </label>
          <input
            type="text"
            value={contacts.kakao || ''}
            onChange={(e) => handleUrlChange('kakao', e.target.value)}
            className="w-full py-2 px-3 border rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
            placeholder="카카오톡 채널 URL을 입력하세요"
          />
        </div>

        <div className="bg-white p-4 rounded shadow-sm border">
          <label className="block text-sm font-medium text-gray-700 mb-2">
            인스타그램 링크
          </label>
          <input
            type="text"
            value={contacts.instagram || ''}
            onChange={(e) => handleUrlChange('instagram', e.target.value)}
            className="w-full py-2 px-3 border rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
            placeholder="인스타그램 프로필 URL을 입력하세요"
          />
        </div>

        <div className="bg-white p-4 rounded shadow-sm border">
          <label className="block text-sm font-medium text-gray-700 mb-2">
            유튜브 링크
          </label>
          <input
            type="text"
            value={contacts.youtube || ''}
            onChange={(e) => handleUrlChange('youtube', e.target.value)}
            className="w-full py-2 px-3 border rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
            placeholder="유튜브 채널 URL을 입력하세요"
          />
        </div>

        <div className="bg-white p-4 rounded shadow-sm border">
          <label className="block text-sm font-medium text-gray-700 mb-2">
            전화번호
          </label>
          <input
            type="text"
            value={(contacts.phone || '').replace('tel:', '')}
            onChange={(e) => handleUrlChange('phone', e.target.value)}
            className="w-full py-2 px-3 border rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
            placeholder="전화번호를 입력하세요 (예: 02-1234-5678)"
          />
        </div>

        <div className="bg-white p-4 rounded shadow-sm border">
          <label className="block text-sm font-medium text-gray-700 mb-2">
            네이버 플레이스 링크
          </label>
          <input
            type="text"
            value={contacts.naver || ''}
            onChange={(e) => handleUrlChange('naver', e.target.value)}
            className="w-full py-2 px-3 border rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
            placeholder="네이버 플레이스 URL을 입력하세요"
          />
        </div>
      </div>
    </div>
  );
};

export default ContactEditor;