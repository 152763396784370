// src/shared/stores/draftStore.ts
import { create } from 'zustand';
import { persist } from 'zustand/middleware';

type ContentType = 'template' | 'post';

interface DraftData {
  id: string;           // templateId
  type: ContentType;    // 컨텐츠 타입
  data: any;           // 실제 저장할 데이터
  lastModified: number; // 마지막 수정 시간
}

interface DraftStore {
  drafts: Record<string, DraftData>;  // key: `${templateId}-${type}`
  setDraft: (templateId: string, type: ContentType, data: any) => void;
  getDraft: (templateId: string, type: ContentType) => DraftData | null;
  removeDraft: (templateId: string, type: ContentType) => void;
  clearAllDrafts: () => void;
}

export const useDraftStore = create<DraftStore>()(
  persist(
    (set, get) => ({
      drafts: {},
      
      setDraft: (templateId: string, type: ContentType, data: any) => {
        set((state) => ({
          drafts: {
            ...state.drafts,
            [`${templateId}-${type}`]: {
              id: templateId,
              type,
              data,
              lastModified: Date.now(),
            },
          },
        }));
      },

      getDraft: (templateId: string, type: ContentType) => {
        const key = `${templateId}-${type}`;
        return get().drafts[key] || null;
      },

      removeDraft: (templateId: string, type: ContentType) => {
        set((state) => {
          const newDrafts = { ...state.drafts };
          delete newDrafts[`${templateId}-${type}`];
          return { drafts: newDrafts };
        });
      },

      clearAllDrafts: () => set({ drafts: {} }),
    }),
    {
      name: 'draft-storage',
    }
  )
);