import React, { useState, useMemo } from 'react';
import { ChevronLeft, ChevronRight } from 'lucide-react';
import { useIsMobile } from '@/shared/hooks/useIsMobile';
import Pagination from './Pagination';
import toast from 'react-hot-toast';

interface Post {
  id: number;
  title: string;
  subtitle: string;
  thumbnailUrl: string;
  editablePath?: string;
}

interface PostsListProps {
  posts: Post[];
  editablePath?: string;
}

const PostItem = ({ id, title, subtitle, thumbnailUrl, editablePath }: Post) => {
  const handleClick = (e: React.MouseEvent) => {
    if (editablePath) {
      e.preventDefault();
      toast.error('컨텐츠 확인 및 관리는 게시물 관리를 이용해주세요');
      return;
    }
  };

  return(
    <div className="mx-auto relative">
      <a
        href={`/products/${id}`}
        className="block"
        onClick={handleClick}
      >
        <img
          src={thumbnailUrl}
          alt={title}
          className="object-cover max-w-[320px] w-full aspect-[5/4] rounded-xl shadow-md shadow-black cursor-pointer"
        />
        <div className="mt-4 px-8 text-center cursor-pointer">
          <p className="font-semibold text-gray-800 line-clamp-1">{title}</p>
          <h4 className="mt-1 text-sm font-light text-gray-600 tracking-tight line-clamp-1">{subtitle}</h4>
        </div>
      </a>
    </div>
  )
};

const PostsList01 = ({ posts, editablePath }: PostsListProps) => {
  const isMobile = useIsMobile();
  const [currentPage, setCurrentPage] = useState(1);
  
  const itemsPerPage = isMobile ? 6 : 8;
  
  const currentPosts = useMemo(() => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    return posts.reverse().slice(startIndex, startIndex + itemsPerPage);
  }, [posts, currentPage, itemsPerPage]);

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  if (!posts || posts.length === 0) {
    return <div className="text-center py-8">등록된 게시물이 없습니다.</div>;
  }

  return (
    <div>
      <div className="w-full grid grid-cols-1 md:grid-cols-4 gap-8 md:gap-x-6 md:gap-y-6">
        {currentPosts.map((post) => (
          <PostItem
            key={post.id}
            {...post}
            editablePath={editablePath}
          />
        ))}
      </div>
      
      <Pagination
        totalItems={posts.length}
        itemsPerPage={itemsPerPage}
        currentPage={currentPage}
        onPageChange={handlePageChange}
        maxVisiblePages={5}
      />
    </div>
  );
};

export default PostsList01;