// src/queries/imageQueries.ts
import { useMutation, useQueryClient } from '@tanstack/react-query';
import toast from 'react-hot-toast';
import { withAuthInterceptor } from '../utils/api';
import { templateKeys } from './templateQueries';
import { useUser } from '../stores/authStore';


interface ApiResponse<T = any> {
  success: boolean;
  data?: T;
  error?: string;
}

export const useImageUpload = (templateId: string) => {
  const api = withAuthInterceptor();
  const queryClient = useQueryClient();
  const user = useUser();


  return useMutation({
    mutationFn: async (file: File) => {
      const formData = new FormData();
      formData.append('file', file);

      const { data } = await api.post<any>(
        '/api/media/upload',
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            'X-Template-Id': templateId
          }
        }
      );

      if (!data.success) {
        throw new Error(data.error || 'Upload failed');
      }

      return data.data;
    },
    onSuccess: () => {
      toast.success('이미지가 성공적으로 업로드되었습니다.');
      queryClient.invalidateQueries({ queryKey: templateKeys.list(user?.userId || '') });
      // queryClient.invalidateQueries({ queryKey: templateKeys.list });
    },
    onError: (error: Error) => {
      toast.error(error.message || '이미지 업로드에 실패했습니다.');
    }
  });
};


export const useDeleteImage = (templateId: string) => {
  const api = withAuthInterceptor();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (imageId: string) => {
      const { data } = await api.delete<ApiResponse>(
        `/api/media/delete/${imageId}`,
        {
          headers: {
            'X-Template-Id': templateId
          }
        }
      );

      if (!data.success) {
        throw new Error(data.error || 'Delete failed');
      }

      return data.data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: templateKeys.list });
    },
    onError: (error: Error) => {
      toast.error(error.message || '이미지 삭제에 실패했습니다.');
    }
  });
};