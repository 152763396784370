import { useMutation } from '@tanstack/react-query';
import { api } from '../utils/api';

interface RegisterRequest {
 email: string;
 password: string;
 companyName: string;
 taxId?: string;
 phoneNumber?: string;
 terms: {
   serviceAgreement: boolean;
   privacyAgreement: boolean;
   marketingAgreement: boolean;
 };
}

interface AuthResponse {
  token: string;
  data: {
    token: string;
    user: any;
  }
}

export const useRegister = () => {
 return useMutation({
   mutationFn: async ({ 
     email, 
     password, 
     companyName,
     taxId,
     phoneNumber,
     agreedToService,
     agreedToPrivacy,
     agreedToMarketing
   }: { 
     email: string;
     password: string;
     companyName: string;
     taxId?: string;
     phoneNumber?: string;
     agreedToService: boolean;
     agreedToPrivacy: boolean;
     agreedToMarketing: boolean;
   }) => {
     const requestData: RegisterRequest = {
       email,
       password,
       companyName,
       taxId,
       phoneNumber,
       terms: {
         serviceAgreement: agreedToService,
         privacyAgreement: agreedToPrivacy,
         marketingAgreement: agreedToMarketing
       }
     };

     const { data } = await api.post<AuthResponse>('/api/auth/register', requestData);
     return data;
   }
 });
};

export const useLogin = () => {
  return useMutation({
    mutationFn: async ({ email, password }: { 
      email: string; 
      password: string; 
    }) => {
      const { data } = await api.post<AuthResponse>('/api/auth/login', {
        email,
        password
      });
      // 로그인 성공 시 토큰 저장
      localStorage.setItem('token', data.data.token);
      return data;
    }
  });
};


// // axios 인스턴스 생성
// const api = axios.create({
//   baseURL: 'https://web-builder-api.quokkawoo.workers.dev',
//   headers: {
//     'Content-Type': 'application/json'
//   }
// });


// interface AuthResponse {
//   token: string;
// }

// export const useRegister = () => {
//   return useMutation({
//     mutationFn: async ({ email, password, company }: { 
//       email: string; 
//       password: string; 
//       company: string; 
//     }) => {
//       const { data } = await api.post<AuthResponse>('/api/auth/register', {
//         email,
//         password,
//         company
//       });
//       return data;
//     }
//   });
// };

// export const useLogin = () => {
//   return useMutation({
//     mutationFn: async ({ email, password }: { 
//       email: string; 
//       password: string; 
//     }) => {
//       const { data } = await api.post<AuthResponse>('/api/auth/login', {
//         email,
//         password
//       });
//       // 로그인 성공 시 토큰 저장
//       localStorage.setItem('token', data.token);
//       return data;
//     }
//   });
// };