// src/stores/templateStore.ts
import { create } from 'zustand';
import { persist, PersistOptions } from 'zustand/middleware';

export interface Template {
 templateId: string;
 name: string;
 templateType: string;
 createdAt: string;
 url: string;
}

interface TemplateState {
  templateData: any | null;
  selectedTemplateId: string | null;
  templates: Template[] | null;
  setTemplateData: (data: any) => void;
  setSelectedTemplateId: (id: string | null) => void;
  setTemplates: (templates: Template[] | null) => void;
  reset: any
}

type TemplatePersist = Pick<TemplateState, "selectedTemplateId">;

const persistConfig: PersistOptions<TemplateState, TemplatePersist> = {
 name: 'template-storage',
 partialize: (state) => ({ 
   selectedTemplateId: state.selectedTemplateId,
 }),
};

export const useTemplateStore = create<TemplateState>()(
 persist(
  (set) => ({
    templateData: null,
    selectedTemplateId: null,
    templates: null,
    setTemplateData: (data) => set({ templateData: data }),
    setSelectedTemplateId: (id) => set({ selectedTemplateId: id }),
    setTemplates: (templates) => set({ templates }),
    reset: () => set({ templateData: null, selectedTemplateId: null, templates: null })
  }),
   persistConfig
 )
);