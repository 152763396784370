import React, { useState } from 'react';
import { usePageStore } from '@/shared/stores/pageStore';
import { ChevronDown, EyeOff, Eye, Edit2, Check, X } from 'lucide-react';
import { AnimatePresence, motion } from 'framer-motion';
import { useTemplateStore } from '@/shared/stores/templateStore';
import { findCurrentPath, generatePathText } from '@/shared/utils/findCurrentPath';

interface MenuItem {
  id: string;
  name: string;
  path: string;
  visible: boolean;
  children?: MenuItem[];
}

interface NavigationMenuProps {
  menu: MenuItem[];
}

const NavigationMenu: React.FC<NavigationMenuProps> = () => {
  const [isOpen, setIsOpen] = React.useState(false);
  const [editingId, setEditingId] = useState<string | null>(null);  // path 대신 id 사용
  const [editingName, setEditingName] = React.useState('');
  const currentPage = usePageStore((state) => state.currentPage);
  const setCurrentPage = usePageStore((state) => state.setCurrentPage);
  const templateData = useTemplateStore((state) => state.templateData);
  const setTemplateData = useTemplateStore((state) => state.setTemplateData);

  const menu = templateData.essential.navigation.menu;

  const homeMenuItem = React.useMemo(() => {
    return menu.find((item: MenuItem) => item.path === '/') || { 
      name: 'Home', 
      path: '/', 
      visible: true, 
      id: 'nav_home' 
    };
  }, [menu]);


  // 현재 경로 계산
  const currentPath = React.useMemo(() => {
    return findCurrentPath(menu, `/${currentPage}`, homeMenuItem);
  }, [menu, currentPage, homeMenuItem]);

  // 경로 표시 텍스트 생성
  const pathText = React.useMemo(() => {
    return generatePathText(currentPath, homeMenuItem);
  }, [currentPath, homeMenuItem]);

  const handlePageSelect = (path: string) => {
    if (editingId) return;
    setCurrentPage(path.replace('/', ''));
    setIsOpen(false);
  };


  const handleVisibilityToggle = (e: React.MouseEvent, itemToUpdate: MenuItem) => {
    e.stopPropagation();
    
    const updateMenuVisibility = (items: MenuItem[]): MenuItem[] => {
      return items.map(item => {
        if (item.id === itemToUpdate.id) {
          const newVisible = !item.visible;
          return {
            ...item,
            visible: newVisible,
            children: item.children
              ? item.children.map(child => ({
                  ...child,
                  visible: newVisible
                }))
              : undefined
          };
        }
        
        if (item.children) {
          return {
            ...item,
            children: updateMenuVisibility(item.children)
          };
        }
        
        return item;
      });
    };

    const updatedMenu = updateMenuVisibility([...menu]);
    const newTemplateData = {
      ...templateData,
      essential: {
        ...templateData.essential,
        navigation: {
          ...templateData.essential.navigation,
          menu: updatedMenu
        }
      }
    };

    setTemplateData(newTemplateData);
  };

  const startEditing = (e: React.MouseEvent, item: MenuItem) => {
    e.stopPropagation();
    setEditingId(item.id);  // path 대신 id 저장
    setEditingName(item.name);
  };

  const saveEdit = (e: React.MouseEvent, itemToUpdate: MenuItem) => {
    e.stopPropagation();
    
    const updateName = (items: MenuItem[]): MenuItem[] => {
      return items.map(item => {
        if (item.id === itemToUpdate.id) {
          return { ...item, name: editingName };
        }
        if (item.children) {
          return {
            ...item,
            children: updateName(item.children)
          };
        }
        return item;
      });
    };

    const updatedMenu = updateName([...menu]);
    const newTemplateData = {
      ...templateData,
      essential: {
        ...templateData.essential,
        navigation: {
          ...templateData.essential.navigation,
          menu: updatedMenu
        }
      }
    };

    setTemplateData(newTemplateData);
    setEditingId(null);
  };

  const cancelEdit = (e: React.MouseEvent) => {
    e.stopPropagation();
    setEditingId(null);
  };

  const renderMenuItem = (item: MenuItem, depth: number = 0) => {
    const isCurrentPath = item.path === `/${currentPage}`;

    return (
      <div key={item.id} className="w-full">
      <div
        className={`flex items-center justify-between px-4 py-2 hover:bg-gray-100 ${
          isCurrentPath ? 'bg-blue-50' : ''
        }`}
        style={{ paddingLeft: `${(depth + 1) * 16}px` }}
      >
        {editingId === item.id ? (  // path 대신 id로 비교
          <div className="flex items-center flex-grow gap-2" onClick={e => e.stopPropagation()}>
            <input
              type="text"
              value={editingName}
              onChange={(e) => setEditingName(e.target.value)}
              className="flex-grow px-2 py-1 border rounded"
              autoFocus
            />
            <button
              onClick={(e) => saveEdit(e, item)}
              className="p-1 hover:bg-green-100 rounded-full text-green-600"
              title="저장"
            >
              <Check size={14} />
            </button>
            <button
              onClick={cancelEdit}
              className="p-1 hover:bg-red-100 rounded-full text-red-600"
              title="취소"
            >
              <X size={14} />
            </button>
          </div>
        ) : (
            <>
              <button
                onClick={() => handlePageSelect(item.path)}
                className={`text-left flex-grow ${isCurrentPath ? 'text-blue-600' : ''}`}
              >
                {item.name}
              </button>

              {item.path !== '/' && (
                <div className="flex items-center gap-1">
                  <button
                    onClick={(e) => startEditing(e, item)}
                    className={`p-1 hover:bg-gray-200 rounded-full transition-opacity ${
                      editingId ? 'opacity-0' : 'opacity-50 hover:opacity-100'
                    }`}
                    title="메뉴명 수정"
                  >
                    <Edit2 size={14} />
                  </button>
                  <button
                    onClick={(e) => handleVisibilityToggle(e, item)}
                    className={`p-1 hover:bg-gray-200 rounded-full transition-opacity ${
                      editingId ? 'opacity-0' : 'opacity-50 hover:opacity-100'
                    }`}
                    title={`${item.name} 메뉴 ${item.visible ? '숨기기' : '표시'}`}
                  >
                    {item.visible ? <Eye size={14} /> : <EyeOff size={14} />}
                  </button>
                </div>
              )}
            </>
          )}
        </div>
        {item.children?.map(child => renderMenuItem(child, depth + 1))}
      </div>
    );
  };

  return (
    <div className="relative">
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="flex items-center space-x-2 px-4 py-2 hover:bg-gray-50 rounded"
      >
        <span className="text-gray-800">{pathText}</span>
        <ChevronDown
          className={`w-4 h-4 transition-transform ${isOpen ? 'rotate-180' : ''}`}
        />
      </button>

      <AnimatePresence>
        {isOpen && (
          <motion.div
            initial={{ opacity: 0, y: -10 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -10 }}
            className="absolute left-0 top-full mt-1 w-80 bg-white rounded-md shadow-lg border z-50"
          >
            {menu.map((item: MenuItem) => renderMenuItem(item, 0))}
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default NavigationMenu;