 // src/queries/templateQueries.ts
import { TemplateResponse } from '@/builder/types/template';
import { useQuery, useMutation, useQueryClient, useQueries, UseQueryOptions } from '@tanstack/react-query';
import toast from 'react-hot-toast';
import { withAuthInterceptor } from '../utils/api';
import { useNavigate } from 'react-router-dom';
import { AxiosError } from 'axios';
import { useUser } from '../stores/authStore';


export const templateKeys = {
  all: (userId: string) => ['templates', userId] as const,
  list: (userId: string) => ['templates', userId, 'list'] as const,
  data: (userId: string, id: string) => ['templates', userId, id, 'data'] as const,
  // all: ['templates'] as const,
  // list: ['templates', 'list'] as const,
  // data: (id: string) => ['templates', id, 'data'] as const,
}


export const useTemplates = () => {
  const api = withAuthInterceptor();
  const user = useUser();  // 현재 사용자 정보 가져오기

  return useQuery({
    queryKey: templateKeys.list(user?.userId || ''),
    // queryKey: templateKeys.list,
    queryFn: async () => {
      const { data } = await api.get<TemplateResponse>('/api/templates');
      return data.data.templates;
    },
    enabled: !!user?.userId  // 유저id가 있을 때만 쿼리 실행
  });
};



export const useTemplateData = (templateId: string) => {
  const api = withAuthInterceptor();
  const user = useUser();  // 현재 사용자 정보 가져오기

  const { data: queryData, isLoading, error } = useQuery({
    queryKey: templateKeys.data(user?.userId || '', templateId),
    // queryKey: templateKeys.data(templateId),
    queryFn: async () => {
      const { data } = await api.get(`/api/templates/${templateId}/data`);
      return data.data;
    },
    enabled: !!user?.userId  // 유저id가 있을 때만 쿼리 실행
  });

  return {
    data: queryData,
    isLoading,
    error
  };
};


export const useCreateTemplate = () => {
  const api = withAuthInterceptor();
  const queryClient = useQueryClient();
  const user = useUser();  // 현재 사용자 정보 가져오기
  
  return useMutation({
    mutationFn: async ({
      name,
      templateType
    }: { 
      name: string; 
      templateType: string; 
    }) => {
      const { data } = await api.post('/api/templates', { name, templateType });
      return data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: templateKeys.list(user?.userId || '') });
      // queryClient.invalidateQueries({ queryKey: templateKeys.list });
    },
  });
};


// 템플릿 데이터 수정
export const useUpdateTemplate = (templateId: string) => {
  const api = withAuthInterceptor();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const user = useUser();  // 현재 사용자 정보 가져오기

  return useMutation({
    mutationFn: async ({ type, data }: { 
      type: 'data'; 
      // type: 'essential' | 'pages'; 
      data: any; 
    }) => {
      const { data: responseData } = await api.put(`/api/templates/${templateId}/${type}`, data);
      return responseData;
    },
    onSuccess: (_, variables) => {
      queryClient.invalidateQueries({ queryKey: templateKeys.data(user?.userId || '', templateId) });
      // queryClient.invalidateQueries({ queryKey: templateKeys.data(templateId) });
    },
    onError: (error) => {
      if (error instanceof AxiosError) {
        if (error.response?.status === 401) {
          toast.error('로그인이 만료되었습니다. 작성중인 내용은 임시저장되었습니다.');
          
          localStorage.removeItem('template-storage');
          localStorage.removeItem('auth-storage');
          localStorage.removeItem('token');
          
          navigate('/auth/login');
          return;
        }
      }
      
      toast.error('저장 중 오류가 발생했습니다');
      console.error('Save error:', error);
    }
  });
};