import { useTemplateStore } from '@/shared/stores/templateStore';
import PageBanner from '@/templates/components/common/PageBanner';
import SectionContainer from '@/templates/components/common/SectionContainer';
import SectionHeader from '@/templates/components/common/SectionHeader';
import History01 from '@/templates/components/history/History01';


const AboutHistory = () => {
  const templateData = useTemplateStore(state => state.templateData);

  return (
    <div>
      <PageBanner
        {...(templateData.pages.about.common)}
        editablePath="pages.about.common"
      />
      
      <SectionContainer>
        <SectionHeader
          {...(templateData.pages.about.history.first)}
          editablePath="pages.about.history.first"
        />
        <History01
          {...(templateData.pages.about.history.first)}
          editablePath="pages.about.history.first"
        />
      </SectionContainer>
      {/* <SectionContainer>
        <SectionHeader
          title={[
            { text: "기업연혁"},
          ]}
          subtitle={[
            { text: "우리가 걸어온 길을 소개합니다" },
          ]}
        />
        <div className='flex flex-col-reverse md:flex-row gap-12'>
          <img
            src="/images/verticalBg.jpg"
            alt="history"
            loading='lazy'
            className="max-w-[450px] w-full object-contain"
          />
          <HistoryList data={historyData} /> 
        </div>
      </SectionContainer> */}

    </div>
  );
};

export default AboutHistory;