// src/utils/findCurrentPath.tsx
import React from 'react';
import { Home } from 'lucide-react';

interface MenuItem {
  id?: string;          // Optional for backward compatibility
  name: string;
  path: string;
  visible?: boolean;    // Optional for backward compatibility
  children?: MenuItem[];
}

export const findCurrentPath = (
  items: MenuItem[],
  targetPath: string,
  homeMenuItem: MenuItem = { name: 'Home', path: '/' },
  currentPath: MenuItem[] = []
): MenuItem[] => {
  if (targetPath === '/' || targetPath === '') {
    return [homeMenuItem];
  }

  for (const item of items) {
    if (item.path === targetPath) {
      if (item.children?.length && item.children[0].path === item.path) {
        return [...currentPath, item, item.children[0]];
      }
      return [...currentPath, item];
    }

    if (item.children?.length) {
      const found = findCurrentPath(
        item.children,
        targetPath,
        homeMenuItem,
        [...currentPath, item]
      );
      if (found.length > 0) return found;
    }
  }

  return [];
};

// Path display component used in PageBanner
export const PathDisplay: React.FC<{ 
  menu: MenuItem[]; 
  currentPage: string; 
}> = ({ menu, currentPage }) => {
  const currentPath = findCurrentPath(menu, `/${currentPage}`);
  
  return (
    <div className="flex justify-end items-center gap-2 md:gap-4 text-slate-200/90">
      <Home className="w-3 h-4 md:w-4 md:h-5" />
      <span>{'>'}</span> 
      <div className="flex items-center gap-2 md:gap-4">
        {currentPath.map((item, index) => (
          <React.Fragment key={item.path}>
            <span>{item.name}</span>
            {index < currentPath.length - 1 && <span>{'>'}</span>}
          </React.Fragment>
        ))}
      </div>
    </div>
  );
};

// Helper function to generate path text (used in NavigationMenu)
export const generatePathText = (
  currentPath: MenuItem[],
  homeMenuItem: MenuItem
): string => {
  if (!currentPath?.length || (currentPath.length === 1 && currentPath[0]?.path === '/')) {
    return homeMenuItem.name;
  }
  
  const pathNames = [homeMenuItem.name];
  currentPath.forEach(item => {
    if (item && item.name && item.path !== '/') {
      pathNames.push(item.name);
    }
  });
  
  return pathNames.join(' \u00A0\u00A0>\u00A0\u00A0 ');
};