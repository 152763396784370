import { usePageStore } from "@/shared/stores/pageStore";
import ScrollMouse from "../indicators/mouse/ScrollMouse";
import TextAnimation from "../indicators/text/TextAnimation";
import { EDITABLE_HOVER_CLASSES } from "@/shared/constants/styles";
import ImageWrapper from "@/shared/components/ImageWrapper";

const Hero02 = ({
  content,
  images,
  editablePath
}: any) => {
  const setSelectedComponent = usePageStore(state => state.setSelectedComponent);
  const handleHistoryClick = () => {
    if (editablePath) {
      const editorConfig: any = {
        type: 'list',
        path: `${editablePath}.content`,
        content: content
      };
  
      setSelectedComponent({
        ...editorConfig
      });
    }
  };

  return (
    <section  className="h-screen relative">
      <ImageWrapper
        src={images.background.src}
        styles={images.background.styles}
        alt="hero"
        className="!absolute inset-0 w-full"
        loading="eager"
        editable={editablePath ? {
          type: 'image',
          path: `${editablePath}.images.background`
        } : undefined}
        animation="none"
      />
      
      <div className="absolute top-[12%] md:top-[20%] left-[5%] mx-auto">
        <div 
          onClick={handleHistoryClick}
          className={editablePath ? EDITABLE_HOVER_CLASSES : ''}
        >
          <TextAnimation
            data={content}
            className="text-white tracking-tight"
          />
        </div>
      </div>
 
      <div className="absolute top-[80%] md:top-[55%] right-[16%] md:right-[8%]">
        <ScrollMouse />
      </div>
    </section>
  );
 };

 export default Hero02;