import TextWrapper from "@/shared/components/TextWrapper";

const Contact03 = ({
  content,
  editablePath
}: any) => {

  return (
      <div className="flex flex-col items-center">
        <TextWrapper
        content={content.title}
        className= 'mt-8'
        editable={editablePath ? {
          type: 'richText',
          path: `${editablePath}.header.subtitle`
        } : undefined}
      />

      <a
        href={content.button.link}
        className="mt-8 px-10 py-2 bg-red-600 text-white hover:bg-red-900 transition-colors">
        <TextWrapper
          content={content.button.text}
          editable={editablePath ? {
            type: 'plainText',
            path: `${editablePath}.header.title`
          } : undefined}
          animation="none"
        />
      </a>
      </div>
  );
 };

export default Contact03;