import { useState, useCallback } from 'react';
import { useDraftStore } from '../stores/draftStore';

interface UseDraftManagerOptions {
  templateId: string;
  type: 'template' | 'post';
  onConfirm?: () => void;
  onReject?: () => void;
}

export const useDraftManager = ({
  templateId,
  type,
  onConfirm,
  onReject,
}: UseDraftManagerOptions) => {
  const [showPrompt, setShowPrompt] = useState(false);
  const { getDraft, setDraft, removeDraft } = useDraftStore();

  const checkDraft = useCallback(() => {
    const draft = getDraft(templateId, type);
    if (draft) {
      setShowPrompt(true);
      return true;
    }
    return false;
  }, [templateId, type, getDraft]);

  const saveDraft = useCallback(
    (data: any) => {
      setDraft(templateId, type, data);
    },
    [templateId, type, setDraft]
  );

  const clearDraft = useCallback(() => {
    removeDraft(templateId, type);
  }, [templateId, type, removeDraft]);

  const handleConfirm = useCallback(() => {
    setShowPrompt(false);
    onConfirm?.();
  }, [onConfirm]);

  const handleReject = useCallback(() => {
    setShowPrompt(false);
    clearDraft();
    onReject?.();
  }, [clearDraft, onReject]);

  return {
    showPrompt,
    checkDraft,
    saveDraft,
    clearDraft,
    handleConfirm,
    handleReject,
    getDraft: () => getDraft(templateId, type),
  };
};