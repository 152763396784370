import { useState } from 'react';
import ImageEditor from '../ImageEditor';
import { ContactContent, SelectedComponent } from "@/shared/types/common";
import TextEditor from '@/builder/components/editor/TextEditor/TextEditor';
import HistoryEditor, { HistoryContent } from './HistoryEditor';
import ListEditor from './ListEditor';
import MapEditor from './MapEditor';
import ContactEditor from './ContactEditor';
import FooterEditor from './FooterEditor';

export interface EditorModalProps {
  component: SelectedComponent;
  onClose: () => void;
  onChange: (content: any) => void;
}

export interface ImageSettings {
  backgroundColor?: {
    color: string;
    opacity: number;  // 0-100
  };
  borderRadius?: number;
  position: {
    x: number; 
    y: number; 
  };
  objectFit: 'cover' | 'contain';
 }

const EditorModal = ({ 
  component,
  onClose,
  onChange
}: EditorModalProps) => {
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [isDragging, setIsDragging] = useState(false);
  const [offset, setOffset] = useState({ x: 0, y: 0 });

  if (!component) return null;

  const handleDragStart = (e: React.MouseEvent) => {
    if (e.target === e.currentTarget) {
      setIsDragging(true);
      setOffset({
        x: e.clientX - position.x,
        y: e.clientY - position.y
      });
    }
  };
 
  const handleDrag = (e: React.MouseEvent) => {
    if (isDragging) {
      setPosition({
        x: e.clientX - offset.x,
        y: e.clientY - offset.y
      });
    }
  };

  const getModalTitle = (type: string) => {
    switch(type) {
      case 'image':
        return '이미지/배경 수정';
      case 'richText':
        return '텍스트 스타일 수정';
      case 'plainText':
        return '텍스트 수정';
      case 'history':
        return '연혁 수정';
      case 'map':
        return '지도 수정';
      case 'contact':
        return '문의하기 수정'
      default:
        return '수정';
    }
  };

  const renderEditor = () => {
    switch (component.type) {
      case 'image':
        if (typeof component.content !== 'string') {
          return null;
        }
        return (
          <ImageEditor 
            content={component.content}
            styles={component.styles}
            onChange={onChange}
          />
        );
      case 'history':
        const historyContent = component.content as HistoryContent;
        if (!historyContent || !historyContent.items) {
          return null;
        }
        return (
          <HistoryEditor
            initialData={historyContent.items}
            onSave={(newItems) => {
              onChange({
                items: newItems
              });
              onClose();
            }}
          />
        );
      case 'list':
        // ListEditor가 list 타입을 처리
        if (!Array.isArray(component.content)) {
          return null;
        }
        return (
          <ListEditor
            listItems={component.content}
            onChange={onChange}
          />
        );
      case 'map':
        if (typeof component.content !== 'string') {
          return null;
        }
        return (
          <MapEditor
            content={component.content}
            onChange={onChange}
          />
        );
      case 'contact':
        const contactContent = component.content as ContactContent;
        return (
          <ContactEditor
            content={contactContent}
            onChange={onChange}
          />
        );
      case 'footer':
        const footerContent = component.content as any;
        return (
          <FooterEditor
            content={footerContent}
            onChange={onChange}
          />
        );
      case 'plainText':
      case 'richText':
        if (typeof component.content !== 'string') {
          return null;
        }
        return (
          <TextEditor
            content={component.content}
            type={component.type}
            onChange={onChange}
          />
        );
      default:
        return null;
    }
  };

  return (
    <div 
      className="fixed right-0 bottom-0 flex items-center justify-center"
      onMouseMove={handleDrag}
      onMouseUp={() => setIsDragging(false)}
    >
      <div 
        className="bg-white rounded-lg w-[550px] border border-slate-200 shadow-xl"
        // className="bg-white rounded-lg w-[550px] max-h-[600px] overflow-scroll border border-slate-200 shadow-xl"
        style={{
          transform: `translate(${position.x}px, ${position.y}px)`
        }}
      >
        <div 
          className="p-4 cursor-move border-b flex justify-between"
          onMouseDown={handleDragStart}
        >
          <h2>{getModalTitle(component.type)}</h2>
          <button onClick={onClose}>✕</button>
        </div>
 
        <div className='p-4 max-h-[600px] overflow-scroll'>
          {renderEditor()}
          {/* {component.type === 'image' ? (
            <ImageEditor 
              content={component.content || ''}
              styles={component.styles}
              onChange={onChange}
            />
          ) : (
            <TextEditor
              content={component.content || ''}
              type={component.type}  // plainText 또는 richText
              onChange={onChange}
            />
          )} */}
        </div>
      </div>
    </div>
  );
};

export default EditorModal;