import React, { useState } from 'react';
import { Plus, Trash2 } from 'lucide-react';

interface ListEditorProps {
  listItems?: string[];
  onChange?: (updatedList: string[]) => void;
}

const ListEditor: React.FC<ListEditorProps> = ({ listItems = [], onChange }) => {
  const [content, setcontent] = useState<string[]>(listItems);

  // 항목 추가
  const addItem = () => {
    if (onChange) {
      onChange([...content, '새로운 항목']);
      setcontent([...content, '새로운 항목']);
    }
  };

  // 항목 삭제
  const removeItem = (index: number) => {
    if (onChange) {
      onChange(content.filter((_, i) => i !== index));
      setcontent(content.filter((_, i) => i !== index));
    }
  };

  // 항목 업데이트
  const updateItem = (index: number, newValue: string) => {
    const updatedList = content.map((item, i) => (i === index ? newValue : item));
    if (onChange) {
      onChange(updatedList);
      setcontent(updatedList);
    }
  };


  return (
    <div className="w-full space-y-4 p-4 border rounded-lg bg-gray-50">
      <div className="space-y-2">
        {content.map((item, index) => (
          <div
            key={index}
            className="flex items-center gap-4 bg-white p-2 rounded shadow-sm border"
          >
            <input
              type="text"
              value={item}
              onChange={(e) => updateItem(index, e.target.value)}
              className="flex-1 py-1 px-2 border rounded"
              placeholder="항목 내용을 입력하세요"
            />
            <button
              onClick={() => removeItem(index)}
              className="p-2 text-red-600 hover:bg-red-50 rounded"
              title="항목 삭제"
            >
              <Trash2 className="w-5 h-5" />
            </button>
          </div>
        ))}
      </div>

      <div className="flex items-center justify-between mt-4">
        <button
          onClick={addItem}
          className="flex items-center gap-2 text-blue-600 hover:bg-blue-50 px-3 py-2 rounded"
        >
          <Plus className="w-5 h-5" />
          항목 추가
        </button>
      </div>
    </div>
  );
};

export default ListEditor;
