import { useCallback, useEffect, useState } from 'react';
import ImagePicker from './ImagePicker';
import { useImageUpload } from '@/builder/queries/imageQueries';
import { ImageWrapperStyles } from "@/shared/types/common";
import { useParams } from 'react-router-dom';
import { useTemplateStore } from '@/shared/stores/templateStore';

export interface ImageEditorProps {
  content: string;
  styles?: ImageWrapperStyles;
  onChange: (content: any) => void;
}

 
export interface ImageEditorSaveData {
  url: string;
  settings: ImageSettings;
}

export interface ImageSettings {
  backgroundColor: {  // optional (?) 제거
    color: string;
    opacity: number;
  };
  position: {
    x: number;
    y: number;
  };
  borderRadius: number;
  objectFit: 'cover' | 'contain';
}



const DEFAULT_SETTINGS: ImageWrapperStyles = {
  objectFit: 'cover',
  position: { x: 50, y: 50 },
  borderRadius: 0,
  height: 'auto'
};

const ImageEditor = ({ 
  content,
  styles,
  onChange
}: ImageEditorProps) => {
  const [tempImage, setTempImage] = useState(content);
  const [tempSettings, setTempSettings] = useState<ImageWrapperStyles>(styles || DEFAULT_SETTINGS);
  const [isImageRemoved, setIsImageRemoved] = useState(content === '');
  const [showImagePicker, setShowImagePicker] = useState(false);

  const { selectedTemplateId: templateId } = useTemplateStore();
  const imageUpload = useImageUpload(templateId || '');


  // 변경사항을 부모에게 전달하는 함수
  const updateParent = useCallback((image: string, settings: ImageWrapperStyles) => {
    onChange({
      src: image,
      styles: settings
    });
  }, [onChange]);

  // 설정 변경 핸들러
  const handleSettingsChange = useCallback((newSettings: ImageWrapperStyles) => {
    setTempSettings(newSettings);
    updateParent(isImageRemoved ? '' : tempImage, newSettings);
  }, [isImageRemoved, tempImage, updateParent]);

  // 이미지 선택 핸들러
  const handleImageSelect = useCallback((url: string) => {
    setTempImage(url);
    setIsImageRemoved(false);
    setShowImagePicker(false);
    updateParent(url, tempSettings);
  }, [tempSettings, updateParent]);

  // 이미지 삭제 핸들러
  const handleImageRemove = useCallback(() => {
    setIsImageRemoved(true);
    updateParent('', tempSettings);
  }, [tempSettings, updateParent]);

  // 초기 설정이 변경될 때만 상태 업데이트
  useEffect(() => {
    setTempImage(content);
    setTempSettings(styles || DEFAULT_SETTINGS);
    setIsImageRemoved(content === '');
  }, [content, styles]);


  return (
    <div className="space-y-4">
      {!showImagePicker ? (
        <div className="h-[180px] relative group flex justify-center">
          {!isImageRemoved ? (
            <img 
              src={tempImage} 
              alt="preview" 
              className="h-full object-contain"
            />
          ) : (
            <div className="h-full w-full flex items-center justify-center bg-gray-100 text-gray-500">
              이미지 없음
            </div>
          )}
          
          <div className="absolute inset-0 flex items-center justify-center opacity-0 group-hover:opacity-100 bg-black/50 transition-opacity">
            <div className="flex gap-2">
              <button 
                className="px-3 py-1.5 bg-white rounded text-sm hover:bg-gray-100"
                onClick={() => setShowImagePicker(true)}
                disabled={imageUpload.isPending}
              >
                {imageUpload.isPending ? '업로드 중...' : (isImageRemoved ? '이미지 추가' : '이미지 변경')}
              </button>
              {!isImageRemoved && (
                <button 
                  className="px-3 py-1.5 bg-red-500 text-white rounded text-sm hover:bg-red-600"
                  onClick={handleImageRemove}
                  disabled={imageUpload.isPending}
                >
                  삭제
                </button>
              )}
            </div>
          </div>
        </div>
      ) : (
        <ImagePicker 
          onSelect={handleImageSelect}
          onClose={() => setShowImagePicker(false)}
        />
      )}

      {/* 스타일 컨트롤러들 */}
      {!isImageRemoved && (
        <div className="space-y-3">
          {/* objectFit 컨트롤 */}
          <div className="flex items-center">
            <label className="w-24 text-sm font-medium">이미지 맞춤</label>
            <select
              value={tempSettings.objectFit}
              onChange={(e) => {
                handleSettingsChange({
                  ...tempSettings,
                  objectFit: e.target.value as 'cover' | 'contain'
                });
              }}
              className="flex-1 p-1.5 border rounded"
            >
              <option value="cover">채우기</option>
              <option value="contain">맞추기</option>
            </select>
          </div>

          {/* 위치 컨트롤 */}
          <div className="flex items-center">
            <label className="w-24 text-sm font-medium">이미지 위치</label>
            <div className="flex-1 grid grid-cols-2 gap-4">
              <div>
                <span className="text-xs text-gray-500">가로</span>
                <input
                  type="range"
                  min="0"
                  max="100"
                  value={tempSettings.position?.x ?? 50}
                  onChange={(e) => {
                    handleSettingsChange({
                      ...tempSettings,
                      position: {
                        ...tempSettings.position!,
                        x: Number(e.target.value)
                      }
                    });
                  }}
                  className="w-full"
                />
              </div>
              <div>
                <span className="text-xs text-gray-500">세로</span>
                <input
                  type="range"
                  min="0"
                  max="100"
                  value={tempSettings.position?.y ?? 50}
                  onChange={(e) => {
                    handleSettingsChange({
                      ...tempSettings,
                      position: {
                        ...tempSettings.position!,
                        y: Number(e.target.value)
                      }
                    });
                  }}
                  className="w-full"
                />
              </div>
            </div>
          </div>

          {/* 테두리 둥글기 */}
          <div className="flex items-center">
            <label className="w-24 text-sm font-medium">테두리</label>
            <input
              type="range"
              min="0"
              max="50"
              value={tempSettings.borderRadius || 0}
              onChange={(e) => {
                handleSettingsChange({
                  ...tempSettings,
                  borderRadius: Number(e.target.value)
                });
              }}
              className="flex-1"
            />
          </div>
        </div>
      )}

      {/* 오버레이 설정 */}
      <div className="flex items-center">
        <label className="w-24 text-sm font-medium">배경 오버레이</label>
        <div className="flex-1 flex items-center gap-8">
          <input
            type="color"
            value={tempSettings.overlay?.color || '#000000'}
            onChange={(e) => {
              handleSettingsChange({
                ...tempSettings,
                overlay: {
                  ...tempSettings.overlay,
                  color: e.target.value,
                  opacity: tempSettings.overlay?.opacity || 50
                }
              });
            }}
            className="w-28"
          />
          <div className="flex-1">
            <span className="text-xs text-gray-500">투명도</span>
            <input
              type="range"
              min="0"
              max="100"
              value={tempSettings.overlay?.opacity || 0}
              onChange={(e) => {
                handleSettingsChange({
                  ...tempSettings,
                  overlay: {
                    ...tempSettings.overlay,
                    color: tempSettings.overlay?.color || '#000000',
                    opacity: Number(e.target.value)
                  }
                });
              }}
              className="w-full"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ImageEditor;