import { cva } from 'class-variance-authority';

interface ImageProgressBarProps {
  current: number;
  max?: number;
  className?: string;
}

const progressBarStyles = cva(
  "h-2 rounded-full transition-all duration-300", 
  {
    variants: {
      status: {
        normal: "bg-green-500",
        warning: "bg-yellow-500",
        full: "bg-red-500"
      }
    }
  }
);

const ImageProgressBar = ({ current, max = 50, className = "" }: ImageProgressBarProps) => {
  const percentage = (current / max) * 100;
  const getStatus = () => {
    if (current >= max) return 'full';
    if (percentage >= 80) return 'warning';
    return 'normal';
  };

  return (
    <div className={`space-y-1 ${className}`}>
      <div className="flex justify-between items-center text-sm">
        <span className="font-medium">이미지 사용량</span>
        <span className={`${current >= max ? 'text-red-500' : 'text-gray-600'}`}>
          {current} / {max}
        </span>
      </div>
      <div className="w-full bg-gray-200 rounded-full h-2">
        <div
          className={progressBarStyles({ status: getStatus() })}
          style={{ width: `${Math.min(percentage, 100)}%` }}
        />
      </div>
      {current >= max && (
        <p className="text-xs text-red-500">
          이미지 업로드 한도에 도달했습니다
        </p>
      )}
    </div>
  );
};

export default ImageProgressBar;