import { createBrowserRouter, Navigate, Outlet } from 'react-router-dom';

import RootLayout from '@/shared/layouts/RootLayout';
import AdminLayout from '@/builder/layouts/AdminLayout';
import EditorLayout from '@/builder/layouts/EditorLayout';

import Login from '@/builder/pages/auth/Login';
import Signup from '@/builder/pages/auth/Signup';
import ProtectedRoute from '@/builder/routes/ProtectedRoute';
import TemplateList from '@/builder/pages/templates/TemplateList';
import PostList from '@/builder/pages/posts/PostList';
import CreatePost from '@/builder/pages/posts/CreatePost';
import EditPost from '@/builder/pages/posts/EditPost';
import Dashboard from '@/builder/pages/admin/Dashboard';
import Home from '@/templates/template2/pages/home/Home';


export const router = createBrowserRouter([
  {
    path: '/',
    element: <RootLayout />,
    children: [
      { path: '', element: <Home /> },
      // { path: '', element: <Navigate to="/admin" replace /> },  // 이 줄을 추가
      {
        path: 'auth',
        children: [
          { path: 'login', element: <Login /> },
          { path: 'signup', element: <Signup /> },
        ]
      },
      {
        path: 'admin',
        element: <ProtectedRoute><Outlet /></ProtectedRoute>,
        children: [
          // 사이트 편집을 제외한 admin 페이지들 (AdminLayout 포함)
          {
            element: <AdminLayout />,
            children: [
              { path: '', element: <Dashboard /> },
              { path: 'templates', element: <TemplateList /> },
              { 
                path: 'posts',
                children: [
                  { index: true, element: <PostList /> },
                  { path: 'create', element: <CreatePost /> },
                  { path: ':postId/edit', element: <EditPost /> }
                ]
              }
              // { path: 'faq', element: <FAQ /> }
            ]
          },
          // 사이트 편집 페이지 (AdminLayout 제외)
          {
            path: 'templates/:templateId',
            element: <EditorLayout />
          }
        ]
      }
    ]
  }
]);
