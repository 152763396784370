// src/components/admin/posts/PostTable.tsx
import { ChevronLeft, ChevronRight, Pencil, Trash2 } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import { Post } from '@/builder/types/post';
import { useDeletePost } from '@/builder/queries/postQueries';
import { POSTS_PER_PAGE } from '@/builder/pages/posts/PostList';

interface PostTableProps {
  posts: Post[];
  pagination?: {
    currentPage: number;
    totalPages: number;
    totalPosts: number;
    hasNextPage: boolean;
    hasPreviousPage: boolean;
  };
  isLoading: boolean;
  currentPage: number;
  onPageChange: (page: number) => void;
}

const PostTable = ({
  posts,
  pagination,
  isLoading,
  currentPage,
  onPageChange
}: PostTableProps) => {
  const navigate = useNavigate();
  const deletePost = useDeletePost();
  const postsPerPage = 5;

  const totalPosts = pagination?.totalPosts || posts.length;
  const totalPages = Math.ceil(totalPosts / postsPerPage);

  const handleEdit = (id: number) => {
    navigate(`/admin/posts/${id}/edit`);
  };

  const handleDelete = async (id: number) => {
    const confirmed = window.confirm('정말로 이 게시글을 삭제하시겠습니까?');
    if (confirmed) {
      try {
        await deletePost.mutateAsync(id);
      } catch (error) {
        console.error('Delete post error:', error);
      }
    }
  };

  // 페이지 번호 배열 생성
  const pageNumbers = [];
  const maxPageNumbers = POSTS_PER_PAGE;
  let startPage = Math.max(1, currentPage - Math.floor(maxPageNumbers / 2));
  let endPage = Math.min(totalPages, startPage + maxPageNumbers - 1);

  if (endPage - startPage + 1 < maxPageNumbers) {
    startPage = Math.max(1, endPage - maxPageNumbers + 1);
  }

  for (let i = startPage; i <= endPage; i++) {
    pageNumbers.push(i);
  }

  if (isLoading) {
    return <div className="text-center py-8">로딩 중...</div>;
  }

  return (
    <div className="space-y-4">
      <div className="overflow-x-auto">
        <table className="w-full">
          <thead className="bg-gray-50">
            <tr>
              <th className="px-6 py-3 text-xs font-medium text-gray-500 uppercase tracking-wider w-16 min-w-20 text-center">
                번호
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                제목
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider w-32">
                작성일
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider w-32">
                설정
              </th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {posts.map((post, index) => (
              <tr key={post.id} className="hover:bg-gray-50">
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 text-center">
                  {totalPosts - ((currentPage - 1) * postsPerPage + index)}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                  {post.title}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                  {new Date(post.date).toLocaleDateString()}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                  <div className="flex space-x-4">
                    <button
                      onClick={() => handleEdit(post.id)}
                      className="text-blue-600 hover:text-blue-800"
                    >
                      <Pencil className="w-4 h-4" />
                    </button>
                    <button
                      onClick={() => handleDelete(post.id)}
                      className="text-red-600 hover:text-red-800 cursor-pointer"
                      disabled={deletePost.isPending}
                    >
                      <Trash2 className="w-4 h-4 cursor-pointer" />
                    </button>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* Pagination */}
      {totalPages > 1 && (
        <div className="flex justify-center items-center space-x-2 py-4">
          <button
            onClick={() => onPageChange(currentPage - 1)}
            disabled={currentPage === 1}
            className="p-2 rounded-lg hover:bg-gray-100 disabled:opacity-50 disabled:cursor-not-allowed"
          >
            <ChevronLeft className="w-5 h-5" />
          </button>
          
          {startPage > 1 && (
            <>
              <button
                onClick={() => onPageChange(1)}
                className={`px-3 py-2 rounded-lg hover:bg-gray-100 ${
                  currentPage === 1 ? 'bg-blue-500 text-white hover:bg-blue-600' : ''
                }`}
              >
                1
              </button>
              {startPage > 2 && <span className="px-2">...</span>}
            </>
          )}

          {pageNumbers.map(number => (
            <button
              key={number}
              onClick={() => onPageChange(number)}
              className={`px-3 py-2 rounded-lg hover:bg-gray-100 ${
                currentPage === number ? 'bg-blue-500 text-white hover:bg-blue-600' : ''
              }`}
            >
              {number}
            </button>
          ))}

          {endPage < totalPages && (
            <>
              {endPage < totalPages - 1 && <span className="px-2">...</span>}
              <button
                onClick={() => onPageChange(totalPages)}
                className={`px-3 py-2 rounded-lg hover:bg-gray-100 ${
                  currentPage === totalPages ? 'bg-blue-500 text-white hover:bg-blue-600' : ''
                }`}
              >
                {totalPages}
              </button>
            </>
          )}

          <button
            onClick={() => onPageChange(currentPage + 1)}
            disabled={currentPage === totalPages}
            className="p-2 rounded-lg hover:bg-gray-100 disabled:opacity-50 disabled:cursor-not-allowed"
          >
            <ChevronRight className="w-5 h-5" />
          </button>
        </div>
      )}
    </div>
  );
};

export default PostTable;