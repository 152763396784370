import { useCallback, useEffect, useRef, useState } from 'react';
import ReactQuill, { Quill } from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { Moon, Sun } from 'lucide-react'; // 아이콘 사용
import toast from 'react-hot-toast';
import ImagePicker from '../ImageEditor/ImagePicker';

export interface TextEditorProps {
  content: string;
  type: 'plainText' | 'richText';
  onChange: (content: string) => void;
  enableImages?: boolean;  // 옵션으로 추가, 기본값은 undefined(false)
}


// 커스텀 Clipboard Module 정의
class CustomClipboard {
  quill: any;
  options: any;

  constructor(quill: any, options: any) {
    this.quill = quill;
    this.options = options;
    
    this.quill.root.addEventListener('paste', this.handlePaste.bind(this));
  }

  handlePaste(e: ClipboardEvent) {
    const clipboardData = e.clipboardData;
    const items = clipboardData?.items;
    
    if (items) {
      for (let i = 0; i < items.length; i++) {
        if (items[i].type.indexOf('image') !== -1) {
          e.preventDefault();
          toast.error('이미지는 상단 이미지 버튼을 통해 업로드해주세요.');
          return;
        }
      }
    }

    const html = clipboardData?.getData('text/html');
    if (html && html.includes('data:image')) {
      e.preventDefault();
      toast.error('이미지는 상단 이미지 버튼을 통해 업로드해주세요.');
    }
  }
}
Quill.register('modules/customClipboard', CustomClipboard);


// 커스텀 이미지 모듈 정의
class ImageUploader {
  quill: any;
  options: any;
  openImagePicker: () => void;

  constructor(quill: any, options: any) {
    this.quill = quill;
    this.options = options;
    this.openImagePicker = options.openImagePicker;
    
    // 이미지 핸들러 등록
    const toolbar = quill.getModule('toolbar');
    toolbar.addHandler('image', this.selectImage.bind(this));
  }

  selectImage() {
    this.openImagePicker();
  }
}

// Quill에 모듈 등록
Quill.register('modules/imageUploader', ImageUploader);

// Quill 설정
const Parchment = Quill.import('parchment');
const Size = Quill.import('attributors/style/size');
Quill.register(Size, true);

const SIZE_MAP: any = {
  'xs': 'clamp(0.625rem, 2vw, 0.75rem)',
  'sm': 'clamp(0.75rem, 2.5vw, 0.875rem)',
  'base': 'clamp(0.875rem, 3vw, 1rem)',
  'lg': 'clamp(1rem, 3.5vw, 1.125rem)',
  'xl': 'clamp(1.125rem, 4vw, 1.25rem)',
  '2xl': 'clamp(1.25rem, 4.5vw, 1.5rem)',
  '3xl': 'clamp(1.5rem, 5vw, 1.875rem)',
  '4xl': 'clamp(1.875rem, 5.5vw, 2.25rem)',
  '5xl': 'clamp(2.25rem, 6vw, 3rem)',
  '6xl': 'clamp(2.75rem, 6.5vw, 3.5rem)'
};

const CustomSize = new Parchment.Attributor.Style('size', 'font-size', {
  scope: Parchment.Scope.INLINE,
  whitelist: Object.values(SIZE_MAP)
});
Quill.register(CustomSize, true);


// 핸들러 추가
const sizeHandler = function(this: any, value: string) {
  if (value === '크기') return;
  const quill = this.quill;
  const range = quill.getSelection();
  if (range) {
    quill.format('size', SIZE_MAP[value], 'user');
  }
};


let LineHeight = new Parchment.Attributor.Style('lineHeight', 'line-height', {
  scope: Parchment.Scope.BLOCK,
  whitelist: ['1', '1.2', '1.5', '1.8', '2.0']
});
Quill.register(LineHeight, true);

const FontWeight = new Parchment.Attributor.Style('weight', 'font-weight', {  // 첫 번째 인자를 'weight'로 변경
  scope: Parchment.Scope.INLINE,
  whitelist: ['300', '400', '500', '600', '700', '800']
});
Quill.register(FontWeight, true);

const LetterSpacing = new Parchment.Attributor.Style('letterSpacing', 'letter-spacing', {
  scope: Parchment.Scope.INLINE,
  whitelist: ['-1em', '-0.05em', '-0.025em', '0', '0.025em', '0.05em', '0.1em']
});
Quill.register(LetterSpacing, true);

const TextAlign = new Parchment.Attributor.Style('textAlign', 'text-align', {
  scope: Parchment.Scope.BLOCK,
  whitelist: ['left', 'center', 'right']
});
Quill.register(TextAlign, true);




const TextEditor = ({
  content,
  type,
  onChange,
  enableImages = false  // 기본값 false로 설정
}: TextEditorProps) => {
  const [value, setValue] = useState(content);
  const [isDarkMode, setIsDarkMode] = useState(false);
  // test
  const [showImagePicker, setShowImagePicker] = useState(false);
  const quillRef = useRef<ReactQuill>(null);

  // useEffect도 enableImages prop에 따라 조건부 실행
  useEffect(() => {
    if (enableImages && quillRef.current) {
      const imageButton = document.querySelector('.ql-image');
      
      if (imageButton) {
        const handleClick = (e: Event) => {
          e.preventDefault();
          e.stopPropagation();
          setShowImagePicker(true);
        };

        imageButton.removeEventListener('click', handleClick, true);
        imageButton.addEventListener('click', handleClick, true);

        return () => {
          imageButton.removeEventListener('click', handleClick, true);
        };
      }
    }
  }, [enableImages]);  // enableImages를 의존성 배열에 추가


  useEffect(()=>{
    onChange(value);
  }, [value])

  const toggleDarkMode = (e: React.MouseEvent) => {
    e.preventDefault();  // 추가
    setIsDarkMode(prev => !prev);
  };

  const handleImageSelect = (url: string) => {
    const editor = quillRef.current?.getEditor();
    if (editor) {
      const range = editor.getSelection(true);  // true를 넣어 현재 커서 위치를 강제로 가져옵니다
      const length = editor.getLength();
      
      // 범위가 없거나 잘못된 범위일 경우 문서 끝에 삽입
      const index = range ? range.index : length;
      
      editor.insertEmbed(index, 'image', url);
      editor.setSelection(index + 1, 0);  // 이미지 다음으로 커서 이동
    }
    setShowImagePicker(false);
  };


  const handleColor = useCallback(() => {
    const toolbar: any = document.querySelector('.ql-toolbar');
    const colorButton = toolbar?.querySelector('.ql-color');
    
    if (!colorButton) return;
    
    const input = document.createElement('input');
    input.type = 'color';
    input.style.position = 'absolute';
    input.style.opacity = '0';
    input.style.height = '0';
    
    const buttonRect = colorButton.getBoundingClientRect();
    const toolbarRect = toolbar.getBoundingClientRect();
    
    input.style.left = `${buttonRect.left - toolbarRect.left}px`;
    input.style.top = `${buttonRect.height}px`;
    input.style.zIndex = '1000';
    
    toolbar.appendChild(input);
    
    setTimeout(() => {
      input.style.visibility = 'visible';
      input.click();
    }, 0);
    
    const handleChange = () => {
      const quill = quillRef.current?.getEditor();
      const range = quill?.getSelection();
      
      if (range && quill) {
        quill.format('color', input.value);
      }
      input.remove();
    };

    input.addEventListener('change', handleChange);
  }, []);

  // 공통 formats
  const formats = [
    'weight',
    // 'bold',
    'italic', 'underline',
    'size', 'lineHeight', 'letterSpacing', 'color', 'background', 'align', 'textAlign',
    'image' // 이미지 format 추가
  ];

  const getToolbarOptions = () => {
    const baseOptions = [
      ['italic', 'underline'],
      [{ 'weight': ['굵기', '300', '400', '500', '600', '700', '800'] }],
      [{ 'size': ['크기', ...Object.keys(SIZE_MAP)] }],
      [{ 'lineHeight': ['높이', '1', '1.2', '1.5', '1.8', '2.0'] }],
      [{ 'letterSpacing': ['자간', '-1em', '-0.05em', '-0.025em', '0', '0.025em', '0.05em', '0.1em'] }],
      ['color'],
      [{ 'textAlign': ['정렬', 'left', 'center', 'right'] }],
    ];

    if (enableImages) {
      baseOptions.push(['image']);
    }

    return baseOptions;
  };

  // modules 설정에 추가
const modules = {
  toolbar: type === 'plainText' 
    ? false
    : {
        container: getToolbarOptions(),
        handlers: {
          color: handleColor,
          size: sizeHandler,
        },
      },
  customClipboard: true  // 커스텀 클립보드 모듈 활성화
};
  // // modules 설정 수정
  // const modules = {
  //   toolbar: type === 'plainText' 
  //     ? false
  //     : {
  //         container: getToolbarOptions(),
  //         handlers: {
  //           color: handleColor,
  //           size: sizeHandler,
  //         },
  //       }
  // };

  // modules 설정을 type에 따라 다르게 적용
  // const modules = {
  //   toolbar: type === 'plainText' 
  //     ? false
  //     : {
  //         container: [
  //           ['italic', 'underline'],
  //           [{ 'weight': ['굵기', '300', '400', '500', '600', '700', '800'] }], // font-weight -> weight로 변경
  //           [{ 'size': ['크기', ...Object.keys(SIZE_MAP)] }],
  //           [{ 'lineHeight': ['높이', '1', '1.2', '1.5', '1.8', '2.0'] }],
  //           [{ 'letterSpacing': ['자간', '-1em', '-0.05em', '-0.025em', '0', '0.025em', '0.05em', '0.1em'] }],
  //           ['color'],
  //           // [{ 'align': [] }],
  //           [{ 'textAlign': ['정렬', 'left', 'center', 'right'] }],
  //           ['image'],
  //         ],
  //         handlers: {
  //           color: handleColor,
  //           size: sizeHandler,
  //         },
  //       },
  // };

  return (
    <div className="w-full">
      <style>{darkModeStyles}</style>
      <div className="relative">
        <button 
          onClick={toggleDarkMode}
          className="dark-mode-toggle"
          title={isDarkMode ? "라이트 모드로 전환" : "다크 모드로 전환"}
        >
          {isDarkMode ? 
            <Sun className="w-5 h-5 text-white" /> : 
            <Moon className="w-5 h-5" />
          }
        </button>
        <ReactQuill 
          ref={quillRef}
          theme='snow'
          value={value} 
          onChange={setValue}
          modules={modules}
          formats={type === 'plainText' ? [] : formats}  // 빈 문자열 배열 대신 빈 배열 사용
          style={{ height: type === 'plainText' ? '150px' : '375px' }}
          className={`editor-container ${isDarkMode ? 'dark-mode' : ''}`}
          // className='editor-container'
          preserveWhitespace
        />

        {/* 이미지 선택 모달 */}
        {showImagePicker && (
          <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
            <ImagePicker
              onSelect={handleImageSelect}
              onClose={() => setShowImagePicker(false)}
            />
          </div>
        )}
      </div>
    </div>
  );
};




const darkModeColors = {
  background: '#1f2937',
  toolbar: '#374151',
  border: '#4b5563',
  uiText: '#e5e7eb',  // UI 요소용 텍스트 색상
  hover: '#60a5fa'
};

// 에디터 스타일
const darkModeStyles = `
  /* Color Picker 관련 스타일 추가 */
  .ql-toolbar input[type="color"] {
    padding: 0;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    height: 30px;
    width: 40px;
  }

  .ql-toolbar input[type="color"]::-webkit-color-swatch-wrapper {
    padding: 0;
  }

  .ql-toolbar input[type="color"]::-webkit-color-swatch {
    border: none;
    border-radius: 4px;
  }

  .editor-container.dark-mode {
    background-color: ${darkModeColors.background};
    transition: all 0.2s ease;
  }
  
  .editor-container.dark-mode .ql-toolbar {
    background-color: ${darkModeColors.toolbar};
    border-color: ${darkModeColors.border} !important;
  }
  
  .editor-container.dark-mode .ql-container {
    background-color: ${darkModeColors.background};
    border-color: ${darkModeColors.border} !important;
  }

  /* 툴바 UI 요소들 */
  .editor-container.dark-mode .ql-snow .ql-stroke {
    stroke: ${darkModeColors.uiText};
  }

  .editor-container.dark-mode .ql-snow .ql-fill {
    fill: ${darkModeColors.uiText};
  }

  .editor-container.dark-mode .ql-picker {
    color: ${darkModeColors.uiText};
  }

  .editor-container.dark-mode .ql-picker-options {
    background-color: ${darkModeColors.toolbar};
  }

  /* 호버 효과 */
  .editor-container.dark-mode .ql-toolbar button:hover,
  .editor-container.dark-mode .ql-toolbar .ql-picker:hover {
    color: ${darkModeColors.hover};
  }

  .editor-container.dark-mode .ql-toolbar button:hover .ql-stroke,
  .editor-container.dark-mode .ql-toolbar .ql-picker:hover .ql-stroke {
    stroke: ${darkModeColors.hover};
  }

  /* 토글 버튼 */
  .dark-mode-toggle {
    position: absolute;
    top: 7px;
    right: 5px;
    z-index: 10;
    padding: 5px;
    border-radius: 5px;
    cursor: pointer;
    transition: all 0.2s ease;
  }

  .dark-mode .dark-mode-toggle {
    color: ${darkModeColors.uiText};
  }

  .dark-mode-toggle:hover {
    background-color: rgba(0, 0, 0, 0.1);
  }

  .dark-mode .dark-mode-toggle:hover {
    background-color: rgba(255, 255, 255, 0.1);
  }
`;

export default TextEditor;