import ImageWrapper from "@/shared/components/ImageWrapper";
import HistoryList01 from "./HistoryList01";
import { usePageStore } from '@/shared/stores/pageStore';
import { EDITABLE_HOVER_CLASSES } from "@/shared/constants/styles";

interface History01Props {
  content: {
    items: Array<{
      year: number;
      events: string[];
    }>;
  };
  images: {
    main: {
      src: string;
      styles?: any;
    };
  };
  editablePath: string;
} 

const generateId = () => {
  return `history_${Math.random().toString(36).substr(2, 9)}`;
};

const History01 = ({ 
  content,
  images,
  editablePath
}: History01Props) => {
  const setSelectedComponent = usePageStore(state => state.setSelectedComponent);

  const handleHistoryClick = () => {
    if (editablePath) {
      const editorConfig: any = {
        type: 'history',
        path: `${editablePath}.content`,
        content: content
      };
  
      setSelectedComponent({
        id: generateId(),
        ...editorConfig
      });
    }
  };

  return (
    <div className='flex flex-col-reverse md:flex-row gap-12'>
      <ImageWrapper
        src={images.main.src}
        styles={images.main.styles}
        alt="history"
        className="max-w-[450px] w-full"
        loading="lazy"
        editable={editablePath ? {
          type: 'image',
          path: `${editablePath}.images.main`
        } : undefined}
        animation="none"
      />
      <div 
        onClick={handleHistoryClick}
        className={editablePath ? EDITABLE_HOVER_CLASSES : ''}
      >
        <HistoryList01 content={content} /> 
      </div>
    </div>
  );
};
 
export default History01;