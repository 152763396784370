import ImageWrapper from "@/shared/components/ImageWrapper";


const ImageSection = ({ 
  images,
  editablePath
}: any) => {
  return (
    <section className="h-screen relative flex items-center justify-center">
      <ImageWrapper
        src={images.main.src}
        styles={images.main.styles}
        alt="hero"
        className="!absolute inset-0 w-full"
        loading="lazy"
        editable={editablePath ? {
          type: 'image',
          path: `${editablePath}.images.main`
        } : undefined}
      />
    </section>
  );
 };
 
 export default ImageSection;