import { PlusCircle } from "lucide-react";

const TemplateCreator = ({onClick}: any) => {
  
  return (
    <div className="flex flex-col items-center justify-center bg-white rounded-xl shadow-lg px-8 py-24 text-center">
      <PlusCircle className="w-16 h-16 text-blue-500 mb-4" />
      <h2 className="text-xl font-bold mb-2">템플릿 생성하기</h2>
      <p className="text-gray-600 mb-6">
        새로운 템플릿을 생성하여 웹사이트 제작을 시작해보세요
      </p>
      <button
        onClick={onClick}
        className="px-6 py-3 bg-blue-500 text-white rounded-lg hover:bg-blue-600 transition-colors flex items-center space-x-2 disabled:opacity-50 disabled:cursor-not-allowed"
      >
        <PlusCircle className="w-5 h-5" />
        <span>템플릿 생성하기</span>
      </button>
    </div>
  );
};

export default TemplateCreator;


