import { useState } from 'react';
import { useImageUpload } from '@/builder/queries/imageQueries';
import { Upload, Image as ImageIcon, Loader, FolderOpen } from 'lucide-react';
import toast from 'react-hot-toast';
import PexelsTab from './PexelsTab';
import { useTemplateStore } from '@/shared/stores/templateStore';
import LibraryTab from './LibraryTab';
import { useTemplates } from '@/builder/queries/templateQueries';
import ImageProgressBar from './ImageProgressBarProps';

export interface ImagePickerProps {
  onSelect: (url: string) => void;
  onClose?: () => void;
}

const ALLOWED_MIME_TYPES = ['image/jpeg', 'image/jpg', 'image/png', 'image/webp', 'image/gif'];
const MAX_FILE_SIZE = 2 * 1024 * 1024;

const ImagePicker = ({ onSelect, onClose }: ImagePickerProps) => {
  const [activeTab, setActiveTab] = useState<'upload' | 'library' | 'free'>('free');
  const [dragActive, setDragActive] = useState(false);
  const { selectedTemplateId: templateId } = useTemplateStore();
  const imageUpload = useImageUpload(templateId || '');
  const { data: templates } = useTemplates();

  // 현재 템플릿의 이미지 개수 계산
  const currentTemplate: any = templates?.find(template => template.templateId === templateId);
  const currentImagesCount = currentTemplate?.images?.length || 0;
  const isLimitReached = currentImagesCount >= 50;

  const handleUpload = async (file: File) => {
    if (isLimitReached) {
      toast.error('이미지 업로드 한도(50개)에 도달했습니다. 라이브러리에서 사용하지 않는 이미지를 정리해주세요.');
      return;
    }

    if (!ALLOWED_MIME_TYPES.includes(file.type)) {
      toast.error('지원하지 않는 파일 형식입니다. (JPEG, PNG, WebP, GIF만 가능)');
      return;
    }

    if (file.size > MAX_FILE_SIZE) {
      toast.error('파일 크기가 2MB를 초과합니다.');
      return;
    }

    try {
      const result = await imageUpload.mutateAsync(file);
      if (result?.url) {
        onSelect(result.url);
      }
    } catch (error) {
      console.error('Image upload error:', error);
    }
    // try {
    //     const result = await imageUpload.mutateAsync(file);
    //     if (result?.url) {
    //       // 현재 타임스탬프를 버전으로 사용
    //       const timestamp = Date.now();
    //       const versionedUrl = `${result.url}?v=${timestamp}`;
    //       onSelect(versionedUrl);
    //     }
    //   } catch (error) {
    //     console.error('Image upload error:', error);
    //   }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      handleUpload(file);
    }
  };

  const handleDrop = (e: React.DragEvent) => {
    e.preventDefault();
    setDragActive(false);
    const file = e.dataTransfer.files?.[0];
    if (file) handleUpload(file);
  };

  const renderUploadTab = () => (
    <div className="space-y-4">
      <ImageProgressBar 
        current={currentImagesCount}
        className="px-2"
      />
      <div
        className={`
          border-2 border-dashed rounded-lg p-8 text-center
          ${dragActive ? 'border-blue-500 bg-blue-50' : 'border-gray-300'}
          ${imageUpload.isPending ? 'opacity-50' : ''}
          ${currentImagesCount >= 50 ? 'opacity-50 cursor-not-allowed' : ''}
        `}
        onDrop={handleDrop}
        onDragOver={(e) => {
          e.preventDefault();
          setDragActive(true);
        }}
        onDragLeave={() => setDragActive(false)}
      >
        <input
          type="file"
          accept={ALLOWED_MIME_TYPES.join(',')}
          onChange={handleChange}
          className="hidden"
          id="imageUpload"
          disabled={imageUpload.isPending || currentImagesCount >= 50}
        />
        <label
          htmlFor="imageUpload"
          className={`block ${currentImagesCount >= 50 ? 'cursor-not-allowed' : 'cursor-pointer'}`}
        >
          {imageUpload.isPending ? (
            <div className="flex flex-col items-center gap-2">
              <Loader className="w-6 h-6 animate-spin text-blue-500" />
              <span>업로드 중...</span>
            </div>
          ) : (
            <div className="space-y-2">
              <div>{currentImagesCount >= 50 ? '이미지 업로드 불가' : '클릭하여 이미지 업로드'}</div>
              <div className="text-sm text-gray-500">
                {currentImagesCount >= 50 ? '이미지 한도에 도달했습니다' : '또는 이미지를 여기로 끌어다 놓으세요'}
              </div>
              <div className="text-xs text-gray-400">
                최대 2MB, JPEG/PNG/WebP/GIF
              </div>
            </div>
          )}
        </label>
      </div>
    </div>
  );

  return (
    <div className="w-full max-w-3xl max-h-[600px] overflow-scroll bg-white rounded-lg shadow-xl">
      <div className="flex border-b">
        <button
          className={`flex items-center gap-2 px-4 py-2 text-sm ${
            activeTab === 'free' ? 'border-b-2 border-blue-500' : 'text-gray-500'
          }`}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            setActiveTab('free')
          }}
        >
          <ImageIcon size={16} />
          무료 이미지
        </button>
        <button
          className={`flex items-center gap-2 px-4 py-2 text-sm ${
            activeTab === 'library' ? 'border-b-2 border-blue-500' : 'text-gray-500'
          }`}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            setActiveTab('library')
          }}
        >
          <FolderOpen size={16} />
          라이브러리
        </button>
        <button
          className={`flex items-center gap-2 px-4 py-2 text-sm ${
            activeTab === 'upload' ? 'border-b-2 border-blue-500' : 'text-gray-500'
          }`}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            setActiveTab('upload')
          }}
        >
          <Upload size={16} />
          직접 업로드
        </button>
      </div>

      <div className="p-4">
        {activeTab === 'upload' ? (
          renderUploadTab()
        ) : activeTab === 'library' ? (
          <LibraryTab onSelect={onSelect} />
        ) : (
          <PexelsTab onSelect={onSelect} />
        )}
      </div>

      <div className="border-t p-4 flex justify-end">
        <button
          onClick={onClose}
          className="px-4 py-2 text-sm text-gray-600 hover:text-gray-800"
          disabled={imageUpload.isPending}
        >
          취소
        </button>
      </div>
    </div>
  );
};

//   return (
//     <div className="w-full max-w-3xl bg-white rounded-lg shadow-xl">
//       <div className="flex border-b">
//         <button
//           className={`flex items-center gap-2 px-4 py-2 text-sm ${
//             activeTab === 'free' ? 'border-b-2 border-blue-500' : 'text-gray-500'
//           }`}
//           onClick={() => setActiveTab('free')}
//         >
//           <ImageIcon size={16} />
//           무료 이미지
//         </button>
//         <button
//           className={`flex items-center gap-2 px-4 py-2 text-sm ${
//             activeTab === 'library' ? 'border-b-2 border-blue-500' : 'text-gray-500'
//           }`}
//           onClick={() => setActiveTab('library')}
//         >
//           <FolderOpen size={16} />
//           라이브러리
//         </button>
//         <button
//           className={`flex items-center gap-2 px-4 py-2 text-sm ${
//             activeTab === 'upload' ? 'border-b-2 border-blue-500' : 'text-gray-500'
//           }`}
//           onClick={() => setActiveTab('upload')}
//         >
//           <Upload size={16} />
//           직접 업로드
//         </button>
//       </div>

//       <div className="p-4">
//         {activeTab === 'upload' ? (
//           <div
//             className={`
//               border-2 border-dashed rounded-lg p-8 text-center
//               ${dragActive ? 'border-blue-500 bg-blue-50' : 'border-gray-300'}
//               ${imageUpload.isPending ? 'opacity-50' : ''}
//             `}
//             onDrop={handleDrop}
//             onDragOver={(e) => {
//               e.preventDefault();
//               setDragActive(true);
//             }}
//             onDragLeave={() => setDragActive(false)}
//           >
//             <input
//               type="file"
//               accept={ALLOWED_MIME_TYPES.join(',')}
//               onChange={handleChange}
//               className="hidden"
//               id="imageUpload"
//               disabled={imageUpload.isPending}
//             />
//             <label
//               htmlFor="imageUpload"
//               className="cursor-pointer block"
//             >
//               {imageUpload.isPending ? (
//                 <div className="flex flex-col items-center gap-2">
//                   <Loader className="w-6 h-6 animate-spin text-blue-500" />
//                   <span>업로드 중...</span>
//                 </div>
//               ) : (
//                 <div className="space-y-2">
//                   <div>클릭하여 이미지 업로드</div>
//                   <div className="text-sm text-gray-500">
//                     또는 이미지를 여기로 끌어다 놓으세요
//                   </div>
//                   <div className="text-xs text-gray-400">
//                     최대 2MB, JPEG/PNG/WebP/GIF
//                   </div>
//                 </div>
//               )}
//             </label>
//           </div>
//         ) : activeTab === 'library' ? (
//           <LibraryTab onSelect={onSelect} />
//         ) : (
//           <PexelsTab onSelect={onSelect} />
//         )}
//       </div>

//       <div className="border-t p-4 flex justify-end">
//         <button
//           onClick={onClose}
//           className="px-4 py-2 text-sm text-gray-600 hover:text-gray-800"
//           disabled={imageUpload.isPending}
//         >
//           취소
//         </button>
//       </div>
//     </div>
//   );
// };

export default ImagePicker;