export const searchKeywordMap: Record<string, string> = {
  "책": "book",
  "영화": "movie",

  // 직업/전문
  "공장": "factory",
  "사무실": "office",
  "회사": "company",
  "부동산": "real estate",
  "아파트": "apartment",
  "변호사": "lawyer",
  "법": "law",
  "특허": "patent",
  "의사": "doctor",
  "병원": "hospital",
  "약국": "pharmacy",
  "치과": "dental",
  "한의원": "oriental medicine",
  "회계": "accounting",
  "은행": "bank",
  "증권": "securities",
  "보험": "insurance",
  "교수": "professor",
  "연구": "research",
  "실험": "laboratory",
  "창고": "warehouse",

  // 비즈니스
  "회의": "meeting",
  "상담": "consultation",
  "계약": "contract",
  "인터뷰": "interview",
  "교육": "education",
  "세미나": "seminar",
  "협업": "collaboration",

  // 서비스
  "식당": "restaurant",
  "카페": "cafe",
  "베이커리": "bakery",
  "호텔": "hotel",
  "리조트": "resort",
  "쇼핑몰": "mall",
  "마트": "supermarket",
  "편의점": "store",
  "서점": "bookstore",
  "미용": "beauty salon",
  "네일": "nail art",
  "스파": "spa",
  "헬스": "fitness",
  "요가": "yoga",
  "수영": "swimming",
  "골프": "golf",
  "테니스": "tennis",

  // 인물
  "여자": "woman",
  "남자": "man",
  "미남": "handsome man",
  "미녀": "beautiful woman",
  "커플": "couple",
  "부부": "married couple",
  "가족": "family",
  "아기": "baby",
  "어린이": "child",
  "청소년": "teenager",
  "청년": "young adult",
  "중년": "middle aged",
  "노인": "elderly",
  "직장인": "worker",
  "사업가": "entrepreneur",
  "전문가": "expert",
  "학생": "student",
  "선생": "teacher",

  // 자연/풍경
  "자연": "nature",
  "산": "mountain",
  "바다": "sea",
  "강": "river",
  "숲": "forest",
  "폭포": "waterfall",
  "호수": "lake",
  "해변": "beach",
  "섬": "island",
  "하늘": "sky",
  "구름": "cloud",
  "비": "rain",
  "눈": "snow",
  "안개": "fog",
  "일몰": "sunset",
  "일출": "sunrise",

  // 계절
  "봄": "spring",
  "여름": "summer",
  "가을": "autumn",
  "겨울": "winter",
  
  // IT/기술
  "웹사이트": "website",
  "홈페이지": "homepage",
  "모바일": "mobile",
  "앱": "app",
  "프로그램": "program",
  "소프트웨어": "software",
  "데이터": "data",
  "보안": "security",
  "네트워크": "network",
  "서버": "server",
  "클라우드": "cloud",
  "인공지능": "AI",

  // 디자인
  "로고": "logo",
  "배너": "banner",
  "포스터": "poster",
  "명함": "business card",
  "브로셔": "brochure",
  "패키지": "package",
  "인테리어": "interior",
  "간판": "signboard",
  "전시": "exhibition",
  "디스플레이": "display",
  
  // 비즈니스 공간
  "로비": "lobby",
  "회의실": "meeting room",
  "휴게실": "lounge",
  "카페테리아": "cafeteria",
  "주차장": "parking",
  "리셉션": "reception",
  "강당": "auditorium",
  "전시장": "exhibition hall",
  "작업실": "workshop",
  "매장": "store",

  // 전문 서비스
  "세무": "tax",
  "법무": "legal",
  "인증": "certification",
  "컨설팅": "consulting",
  "마케팅": "marketing",
  "광고": "advertising",
  "홍보": "PR",
  "번역": "translation",
  "통역": "interpretation",

  // 의료/건강
  "재활": "rehabilitation",
  "물리치료": "physiotherapy",
  "검진": "checkup",
  "진단": "diagnosis",
  "상담실": "counseling",
  "응급실": "emergency",
  "임상": "clinical",
  "간호": "nursing",
  "요양": "care",
  "치료": "therapy",

  // 교육
  "강의": "lecture",
  "과외": "tutoring",
  "실습": "practice",
  "도서관": "library",
  "연구실": "laboratory",
  "강의실": "classroom",
  "학습": "learning",
  "교재": "textbook",
  "시험": "exam",
  "자격증": "certificate",

  // 상업/판매
  "판매": "sales",
  "유통": "distribution",
  "재고": "inventory",
  "납품": "delivery",
  "주문": "order",
  "결제": "payment",
  "카운터": "counter",
  "진열": "display",
  "포장": "packaging",

  // 인물/상황
  "상담원": "consultant",
  "영업사원": "salesperson",
  "고객": "customer",
  "방문객": "visitor",
  "관리자": "manager",
  "직원": "employee",
  "대표": "CEO",
  "팀장": "team leader",
  "비서": "secretary",
  "인턴": "intern",

  "도시": "city",
  "거리": "street",
  "광장": "square",
  "야경": "night view",
  "스카이라인": "skyline",
  "교차로": "intersection",
  "지하철": "subway",
  "터미널": "terminal",
  "기차역": "train station",
  "공항": "airport",

  // 도시 요소
  "건물": "building",
  "고층빌딩": "skyscraper",
  "다리": "bridge",
  "터널": "tunnel",
  "지하도": "underpass",
  "육교": "overpass",
  "보도": "sidewalk",
  "횡단보도": "crosswalk",
  "신호등": "traffic light",
  "가로등": "street light",

  // 자연 풍경
  "전원": "countryside",
  "초원": "meadow",
  "들판": "field",
  "목장": "ranch",
  "논": "rice field",
  "밭": "farm",
  "과수원": "orchard",
  "포도원": "vineyard",
  "허브농장": "herb farm",
  "정원": "garden",

  // 산/계곡
  "산길": "mountain path",
  "등산로": "hiking trail",
  "봉우리": "peak",
  "절벽": "cliff",
  "바위": "rock",
  "동굴": "cave",
  "계곡": "valley",
  "협곡": "canyon",
  "산맥": "mountain range",

  // 해변/바다
  "해안": "coast",
  "방파제": "breakwater",
  "등대": "lighthouse",
  "포구": "port",
  "항구": "harbor",
  "부두": "dock",
  "방벽": "seawall",
  "모래사장": "sandy beach",
  "조개껍질": "seashell",
  "파도": "wave",

  // 계절 풍경
  "벚꽃": "cherry blossom",
  "단풍": "autumn leaves",
  "설경": "snow scene",
  "서리": "frost",
  "황혼": "twilight",
  "새벽": "dawn",
  "밤하늘": "night sky",
  "별": "star",
  "달": "moon",

  // 랜드마크
  "성당": "cathedral",
  "절": "temple",
  "궁전": "palace",
  "박물관": "museum",
  "미술관": "art gallery",
  "기념관": "memorial",
  "분수대": "fountain",
  "동상": "statue",
  "기념비": "monument",
  "탑": "tower",

  "개발": "development",
  "코딩": "coding",
  "프로그래밍": "programming",
  "웹개발": "web development",
  "앱개발": "app development",
  "게임개발": "game development",
  "프론트엔드": "frontend",
  "백엔드": "backend",
  "풀스택": "fullstack",

  // 기술/도구
  "컴퓨터": "computer",
  "노트북": "laptop",
  "데이터베이스": "database",
  "알고리즘": "algorithm",
  "코드": "code",
  "버그": "bug",
  "디버깅": "debugging",
  "테스트": "testing",
  "배포": "deployment",

  // 사이버 보안
  "해커": "hacker",
  "방화벽": "firewall",
  "암호화": "encryption",
  "바이러스": "virus",
  "멀웨어": "malware",
  "해킹": "hacking",
  "백신": "antivirus",
  "취약점": "vulnerability",
  "패치": "patch",

  // 데이터/AI
  "빅데이터": "big data",
  "머신러닝": "machine learning",
  "딥러닝": "deep learning",
  "신경망": "neural network",
  "로봇": "robot",
  "자동화": "automation",
  "분석": "analysis",

  // 하드웨어
  "회로": "circuit",
  "칩": "chip",
  "프로세서": "processor",
  "메모리": "memory",
  "하드웨어": "hardware",
  "센서": "sensor",
  "케이블": "cable",
  "배터리": "battery",
  "모니터": "monitor",
  "키보드": "keyboard",

  // 현대 기술
  "블록체인": "blockchain",
  "메타버스": "metaverse",
  "가상현실": "virtual reality",
  "증강현실": "augmented reality",
  "사물인터넷": "IoT",
  "드론": "drone",
  "양자컴퓨터": "quantum computer",
  "웨어러블": "wearable",
  "스마트홈": "smart home",

  // 개발 환경
  "스타트업": "startup",
  "공유오피스": "coworking space",
  "개발실": "development room",
  "서버실": "server room",
  "테스트실": "testing room",

  // 의료/보건
  "간호사": "nurse",
  "수의사": "veterinarian",
  "물리치료사": "physiotherapist",
  "방사선사": "radiologist",
  "약사": "pharmacist",
  "영양사": "nutritionist",
  "응급구조사": "paramedic",
  "임상병리사": "medical technologist",
  "치위생사": "dental hygienist",
  "간병인": "caregiver",

  // 기술/공학
  "전기기사": "electrician",
  "기계공": "mechanic",
  "토목기사": "civil engineer",
  "건축가": "architect",
  "설비기사": "facility engineer",
  "용접공": "welder",
  "배관공": "plumber",
  "측량사": "surveyor",
  "제도사": "technical drawer",
  "품질관리사": "quality controller",

  // 교육
  "보육교사": "childcare teacher",
  "특수교사": "special educator",
  "체육교사": "physical education teacher",
  "음악교사": "music teacher",
  "미술교사": "art teacher",
  "강사": "instructor",
  "교육컨설턴트": "education consultant",
  "진로상담사": "career counselor",
  "독서지도사": "reading instructor",
  "방과후교사": "after-school teacher",

  // 금융/경영
  "재무분석가": "financial analyst",
  "투자상담사": "investment advisor",
  "보험설계사": "insurance planner",
  "세무사": "tax accountant",
  "경영컨설턴트": "management consultant",
  "자산관리사": "asset manager",
  "펀드매니저": "fund manager",
  "리스크매니저": "risk manager",
  "애널리스트": "analyst",
  "트레이더": "trader",

  // 예술/문화
  "일러스트레이터": "illustrator",
  "웹디자이너": "web designer",
  "패션디자이너": "fashion designer",
  "무대디자이너": "stage designer",
  "큐레이터": "curator",
  "음향엔지니어": "sound engineer",
  "조명디자이너": "lighting designer",
  "무용가": "dancer",
  "안무가": "choreographer",
  "작곡가": "composer",

  // 서비스
  "여행상담사": "travel consultant",
  "이벤트플래너": "event planner",
  "결혼플래너": "wedding planner",
  "바리스타": "barista",
  "조리사": "chef",
  "소믈리에": "sommelier",
  "바텐더": "bartender",
  "메이크업아티스트": "makeup artist",
  "피부관리사": "skin care specialist",
  "플로리스트": "florist",

  // 미디어/엔터테인먼트
  "방송작가": "broadcast writer",
  "아나운서": "announcer",
  "리포터": "reporter",
  "촬영감독": "cinematographer",
  "영상편집자": "video editor",
  "성우": "voice actor",
  "캐스팅디렉터": "casting director",
  "특수효과감독": "special effects director",
  "게임기획자": "game designer",
  "스포츠해설가": "sports commentator",

  // 산업/제조
  "공정관리자": "process manager",
  "생산기술자": "production engineer",
  "품질검사원": "quality inspector",
  "자동차정비사": "auto mechanic",
  "조선기사": "shipbuilding engineer",
  "항공정비사": "aircraft mechanic",
  "식품가공기술자": "food technologist",
  "섬유디자이너": "textile designer",
  "인쇄기술자": "printing technician",
  "포장디자이너": "packaging designer",

  "결혼": "wedding",
  "신랑": "groom",
  "신부": "bride",
  "웨딩드레스": "wedding dress",
  "웨딩부케": "wedding bouquet",
  "결혼식장": "wedding hall",
  "결혼반지": "wedding ring",
  "청첩장": "wedding invitation",
  "웨딩케이크": "wedding cake",
  "결혼피로연": "wedding reception",

  // 돌잔치/생일
  "돌잔치": "first birthday",
  "돌상": "birthday table",
  "생일": "birthday",
  "생일파티": "birthday party",
  "생일케이크": "birthday cake",
  "생일선물": "birthday gift",
  "파티장식": "party decoration",
  "풍선": "balloon",
  "폭죽": "firework",
  "파티용품": "party supplies",

  // 연말/기념일
  "송년회": "year end party",
  "신년회": "new year party",
  "크리스마스": "christmas",
  "할로윈": "halloween",
  "발렌타인": "valentine",
  "기념일": "anniversary",
  "파티룸": "party room",
  "축하공연": "celebration performance",
  "이벤트홀": "event hall",
  "연회장": "banquet hall",

  // 사회/비즈니스
  "컨퍼런스": "conference",
  "시상식": "awards ceremony",
  "전시회": "exhibition",
  "개업식": "opening ceremony",
  "입학식": "entrance ceremony",
  "졸업식": "graduation ceremony",
  "창립기념": "foundation anniversary",
  "기업행사": "corporate event",
  "신제품발표": "product launch",

  // 문화/예술
  "콘서트": "concert",
  "뮤지컬": "musical",
  "연극": "theater",
  "페스티벌": "festival",
  "갤러리": "gallery",
  "공연장": "performance hall",
  "무대": "stage",
  "관객석": "audience seats",
  "리허설": "rehearsal",

  // 스포츠/레저
  "운동회": "sports day",
  "마라톤": "marathon",
  "체육대회": "athletic meet",
  "경기": "game",
  "시합": "match",
  "토너먼트": "tournament",
  "챔피언십": "championship",
  "개막식": "opening ceremony",
  "폐막식": "closing ceremony",

  "연인": "lovers",
  "친구들": "friends",
  "동료": "colleagues",
  "가족모임": "family gathering",
  "팀워크": "teamwork",
  "모임": "gathering",
  "데이트": "dating",
  "남매": "siblings",
  "자매": "sisters",
  "형제": "brothers",
  "사람": "human",
  "유아": "infant",
  "여성": "woman",
  "남성": "man",
  "노부부": "elderly couple",

  // 학업
  "수학": "mathematics",
  "과학": "science",
  "영어": "english",
  "물리": "physics",
  "화학": "chemistry",
  "생물": "biology",
  "역사": "history",
  "지리": "geography",
  "문학": "literature",
  "철학": "philosophy",

  // 학습 도구/환경
  "책상": "desk",
  "책장": "bookshelf",
  "필통": "pencil case",
  "연필": "pencil",
  "볼펜": "pen",
  "노트": "notebook",
  "교과서": "textbook",
  "독서실": "study room",

  // 시험/평가
  "퀴즈": "quiz",
  "수능": "SAT",
  "토익": "TOEIC",
  "토플": "TOEFL",
  "아이엘츠": "IELTS",
  "면허": "license",
  "성적표": "report card",
  "답안지": "answer sheet",

  // 과외/학원
  "학원": "academy",
  "인강": "online lecture",
  "스터디": "study group",
  "과제": "assignment",
  "숙제": "homework",
  "발표": "presentation",
  "토론": "debate",

  // 유럽
  "프랑스": "france",
  "영국": "england",
  "이탈리아": "italy",
  "스페인": "spain",
  "독일": "germany",
  "네덜란드": "netherlands",
  "스위스": "switzerland",
  "오스트리아": "austria",
  "그리스": "greece",
  "스웨덴": "sweden",
  "노르웨이": "norway",
  "덴마크": "denmark",
  "핀란드": "finland",
  "포르투갈": "portugal",
  "벨기에": "belgium",

  // 아시아
  "한국": "korea",
  "일본": "japan",
  "중국": "china",
  "대만": "taiwan",
  "홍콩": "hong kong",
  "싱가포르": "singapore",
  "태국": "thailand",
  "베트남": "vietnam",
  "말레이시아": "malaysia",
  "인도": "india",
  "인도네시아": "indonesia",
  "필리핀": "philippines",
  "터키": "turkey",
  "이스라엘": "israel",
  "두바이": "dubai",

  // 북미/남미
  "미국": "usa",
  "캐나다": "canada",
  "멕시코": "mexico",
  "브라질": "brazil",
  "아르헨티나": "argentina",
  "칠레": "chile",
  "페루": "peru",
  "콜롬비아": "colombia",
  "쿠바": "cuba",
  "하와이": "hawaii",

  // 오세아니아
  "호주": "australia",
  "뉴질랜드": "new zealand",
  "괌": "guam",
  "사이판": "saipan",
  "피지": "fiji",

  // 국가 대표 상징물
  "에펠탑": "eiffel tower",
  "빅벤": "big ben",
  "콜로세움": "colosseum",
  "만리장성": "great wall",
  "자유의여신상": "statue of liberty",
  "시드니오페라하우스": "sydney opera house",
  "타지마할": "taj mahal",
  "피사의사탑": "leaning tower of pisa",
  "크레믈린궁": "kremlin",
  "동대문": "dongdaemun",

  // 도시
  "파리": "paris",
  "런던": "london",
  "로마": "rome",
  "베니스": "venice",
  "바르셀로나": "barcelona",
  "베를린": "berlin",
  "암스테르담": "amsterdam",
  "취리히": "zurich",
  "비엔나": "vienna",
  "아테네": "athens",
  "스톡홀름": "stockholm",
  "오슬로": "oslo",
  "코펜하겐": "copenhagen",
  "헬싱키": "helsinki",
  "리스본": "lisbon",

  // 자연/관광
  "알프스": "alps",
  "나이아가라": "niagara",
  "그랜드캐니언": "grand canyon",
  "사하라": "sahara",
  "아마존": "amazon",
  "히말라야": "himalaya",
  "후지산": "mount fuji",
  "앙코르와트": "angkor wat",
  "산토리니": "santorini",
  
  // 아시아 도시
  "서울": "seoul",
  "도쿄": "tokyo", 
  "베이징": "beijing",
  "상하이": "shanghai",
  "방콕": "bangkok",
  "하노이": "hanoi",
  "자카르타": "jakarta",
  "쿠알라룸푸르": "kuala lumpur",
  "마닐라": "manila",
  "뭄바이": "mumbai",
  "델리": "delhi",
  "교토": "kyoto",
  "오사카": "osaka",
  "타이페이": "taipei",
  "마카오": "macau",

  // 아시아 관광지
  "하롱베이": "halong bay",
  "불국사": "bulguksa",
  "센소지": "sensoji",
  "자금성": "forbidden city",
  "페트로나스": "petronas",
  "마리나베이": "marina bay",
  "술탄모스크": "sultan mosque",
  
  // 중동
  "아부다비": "abu dhabi",
  "도하": "doha",
  "리야드": "riyadh",
  "이스탄불": "istanbul",
  "예루살렘": "jerusalem",
  "바그다드": "baghdad",
  "테헤란": "tehran",
  "무스카트": "muscat",
  "메카": "mecca",

  // 유럽 도시
  "마드리드": "madrid",
  "밀라노": "milan",
  "프라하": "prague",
  "부다페스트": "budapest",
  "브뤼셀": "brussels",
  "에든버러": "edinburgh",
  "제네바": "geneva",
  "뮌헨": "munich",
  "프랑크푸르트": "frankfurt",

  // 아프리카
  "이집트": "egypt",
  "남아공": "south africa",
  "모로코": "morocco",
  "케냐": "kenya",
  "탄자니아": "tanzania",
  "카이로": "cairo",
  "케이프타운": "cape town",
  "마라케시": "marrakesh",
  "나이로비": "nairobi",
  "알렉산드리아": "alexandria",

  // 아메리카 도시
  "뉴욕": "new york",
  "로스앤젤레스": "los angeles",
  "샌프란시스코": "san francisco",
  "시카고": "chicago",
  "토론토": "toronto",
  "밴쿠버": "vancouver",
  "상파울루": "sao paulo",
  "리우": "rio de janeiro",
  "부에노스아이레스": "buenos aires",
  "산티아고": "santiago",

  // 유명 건축물/랜드마크
  "부르즈칼리파": "burj khalifa",
  "엠파이어스테이트": "empire state",
  "금문교": "golden gate bridge",
  "백악관": "white house",
  "타임스퀘어": "times square",
  "센트럴파크": "central park",
  "루브르박물관": "louvre museum",
  "바티칸": "vatican",
  "사그라다파밀리아": "sagrada familia",
  "베르사유궁전": "palace of versailles",

  // 외모/스타일
  "소녀": "girl",
  "숙녀": "lady",
  "아가씨": "young lady",
  "여대생": "college girl",
  "모델": "model",
  "미인": "beautiful woman",
  "패셔니스타": "fashionista",

  // 직업
  "직장여성": "career woman",
  "여교사": "female teacher",
  "여의사": "female doctor",
  "승무원": "flight attendant",
  "디자이너": "designer",
  "여배우": "actress",
  "여가수": "female singer",

  // 가족관계
  "어머니": "mother",
  "아내": "wife",
  "할머니": "grandmother",
  "언니": "sister",
  "여동생": "younger sister",
  "딸": "daughter",
  "며느리": "daughter in law",
  "주부": "housewife",
  "예비맘": "expecting mother",
  "워킹맘": "working mom",

  // 라이프스타일
  "쇼핑": "shopping",
  "메이크업": "makeup",
  "네일아트": "nail art",
  "헤어스타일": "hairstyle",
  "피부관리": "skincare",
  "필라테스": "pilates",
  "에스테틱": "aesthetic",
  "다이어트": "diet",
  "홈트레이닝": "home training",

  // 외모/스타일
  "소년": "boy",
  "젊은이": "young man",
  "신사": "gentleman",
  "남성모델": "male model",
  "댄디": "dandy",

  // 직업
  "남교사": "male teacher",
  "엔지니어": "engineer",
  "소방관": "firefighter",
  "경찰": "police",
  "군인": "soldier",
  "운동선수": "athlete",

  // 가족관계
  "아버지": "father",
  "남편": "husband",
  "할아버지": "grandfather",
  "형": "older brother",
  "남동생": "younger brother",
  "아들": "son",
  "사위": "son in law",
  "독신": "bachelor",

  // 취미/라이프
  "운동": "exercise",
  "등산": "hiking",
  "자전거": "cycling",
  "캠핑": "camping",
  "낚시": "fishing",
  "스포츠": "sports",
  "게임": "gaming",

  // 패션
  "정장": "suit",
  "셔츠": "shirt",
  "넥타이": "tie",
  "수트": "formal suit",
  "캐주얼": "casual wear",
  "데님": "denim",
  "운동복": "sportswear",
  "비즈니스룩": "business look",
  "스트릿": "street fashion",
  "클래식": "classic style"
};

export default searchKeywordMap;