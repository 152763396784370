import { create } from 'zustand';
import { AnimationGroup } from '../types/animation';

interface AnimationStore {
  groups: Record<string, AnimationGroup>;
  customOrders: Record<string, number>;
  globalBaseDelay: number;
  
  createGroup: (id: string, baseDelay?: number) => void;
  removeGroup: (id: string) => void;
  setGroupDelay: (groupId: string, delay: number) => void;
  setGlobalBaseDelay: (delay: number) => void;
  setCustomOrder: (groupId: string, elementId: string, order: number) => void;
  getCustomOrder: (groupId: string, elementId: string) => number;
  registerItemToGroup: (groupId: string) => number;
}

export const useAnimationStore = create<AnimationStore>((set, get) => ({
  groups: {},
  customOrders: {},
  globalBaseDelay: 200,

  createGroup: (id: string, baseDelay = 200) => {
    if (get().groups[id]) return;
    
    set(state => ({
      groups: {
        ...state.groups,
        [id]: {
          id,
          baseDelay,
          itemCount: 0
        }
      }
    }));
  },

  removeGroup: (id: string) => {
    if (!get().groups[id]) return;
    
    set(state => {
      const { [id]: removed, ...rest } = state.groups;
      return { groups: rest };
    });
  },

  setGroupDelay: (groupId: string, delay: number) => {
    set((state) => ({
      groups: {
        ...state.groups,
        [groupId]: {
          ...state.groups[groupId],
          baseDelay: delay
        }
      }
    }));
  },

  setGlobalBaseDelay: (delay: number) => {
    set({ globalBaseDelay: delay });
  },

  setCustomOrder: (groupId: string, elementId: string, order: number) => {
    set(state => ({
      customOrders: {
        ...state.customOrders,
        [`${groupId}-${elementId}`]: order
      }
    }));
  },

  getCustomOrder: (groupId: string, elementId: string) => {
    return get().customOrders[`${groupId}-${elementId}`] ?? 0;
  },

  registerItemToGroup: (groupId: string) => {
    const group = get().groups[groupId];
    if (!group) return 0;

    const order = group.itemCount;
    
    set((state) => ({
      groups: {
        ...state.groups,
        [groupId]: {
          ...group,
          itemCount: group.itemCount + 1
        }
      }
    }));

    return order;
  },
}));