import { useTemplateStore } from '@/shared/stores/templateStore';
import ImageSection from '@/templates/components/common/ImageSection';
import PageBanner from '@/templates/components/common/PageBanner';
import SectionContainer from '@/templates/components/common/SectionContainer';
import SectionHeader from '@/templates/components/common/SectionHeader';


const AboutTeam = () => {
  const templateData = useTemplateStore(state => state.templateData);

  return (
    <div>
      <PageBanner
        {...(templateData.pages.about.common)}
        editablePath="pages.about.common"
      />
      
      <SectionContainer>
        <SectionHeader
          {...(templateData.pages.about.team.first)}
          editablePath="pages.about.team.first"
        />
        <ImageSection
          {...(templateData.pages.about.team.first)}
          editablePath="pages.about.team.first"
        />
        {/* <img
          // src={teamImageUrl}
          alt="team"
          loading='lazy'
          className="w-full object-contain"
        /> */}
      </SectionContainer>

    </div>
  );
};

export default AboutTeam;