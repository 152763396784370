import GoogleMap from "./GoogleMap";
import { usePageStore } from '@/shared/stores/pageStore';
import { EDITABLE_HOVER_CLASSES } from "@/shared/constants/styles";
import { useTemplateStore } from "@/shared/stores/templateStore";
import toast from "react-hot-toast";

const InfoItem = ({ label, value }: any) => (
  <div className='flex gap-4 text-sm md:text-lg text-white items-center'>
    <div className="min-w-24 md:w-32 h-8 md:h-10 bg-blue-600 flex items-center justify-center rounded-3xl">
      {label}
    </div>
    <p>{value}</p>
  </div>
);

const generateId = () => {
  return `map_${Math.random().toString(36).substr(2, 9)}`;
};

const Location01 = ({
  map,
  editablePath
}: any) => {
  const setSelectedComponent = usePageStore(state => state.setSelectedComponent);
  const templateData = useTemplateStore(state => state.templateData);
  const locationInfos = [
    {label: "주소", value: templateData.essential.footer.company.address},
    {label: "전화번호", value: templateData.essential.footer.company.contact.phone}
  ]

  const handleMapClick = () => {
    if (editablePath) {
      const editorConfig: any = {
        type: 'map',
        path: `${editablePath}.map.src`,
        content: map.src
      };
  
      setSelectedComponent({
        id: generateId(),
        ...editorConfig
      });
    }
  };

  const handleInfoClick = () => {
    toast.success("홈페이지 맨 아래에 있는 푸터에서 주소와 전화번호를 수정해주세요.")
  }

  return (
    <div className='relative'>
      <div 
        onClick={handleMapClick}
        className={editablePath ? EDITABLE_HOVER_CLASSES : ''}
      >
        <GoogleMap
          src={map.src}
          className={editablePath ? 'pointer-events-none' : ''}
          editablePath={editablePath}
        />
      </div>
      <div
        className='absolute bottom-0 w-full h-36 md:h-28 p-4 flex flex-col md:flex-row gap-4 justify-center md:items-center md:justify-around bg-slate-900/90'
        onClick={handleInfoClick}
      >
        {locationInfos.map((item: any, index: any) => (
          <InfoItem key={index} {...item} />
        ))}
      </div>
    </div>
  );
};

export default Location01;