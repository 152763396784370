import { useEffect } from 'react';
import { useAnimationStore } from '@/shared/stores/animationStore';

export const useGroupAnimation = (delay: number = 300) => {
  const createGroup = useAnimationStore(state => state.createGroup);
  const groupId = `group1-${Math.random()}`;

  useEffect(() => {
    createGroup(groupId, delay);
  }, [createGroup, groupId, delay]);

  return groupId;
};