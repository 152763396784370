import { DEFAULT_ANIMATION } from "@/shared/constants/styles";
import { useAnimation } from "@/shared/hooks/animation/useAnimation";


const DivWithAnimation = ({
  children,
  groupId,
  className
}: any) => {

  const [ref, animationStyles] = useAnimation(
    {
      groupId,
      ...DEFAULT_ANIMATION
    }
  );
 

  return (
    <div
      ref={ref}
      style={animationStyles}
      className={className}
    >
      {children}
    </div>
);
};

export default DivWithAnimation;