import axios, { AxiosInstance, InternalAxiosRequestConfig } from 'axios';

const API_BASE = 'https://web-builder-api.quokkawoo.workers.dev';

// 기본 API 클라이언트
const api = axios.create({
  baseURL: API_BASE,
});

// 인터셉터 추가 여부를 추적하는 플래그
let isAuthInterceptorAdded = false;

/**
 * 인터셉터가 추가된 API 클라이언트를 반환
 * @param client AxiosInstance
 * @returns AxiosInstance
 */
export const withAuthInterceptor = (client: AxiosInstance = api): AxiosInstance => {
  if (!isAuthInterceptorAdded) {
    client.interceptors.request.use(
      (config: InternalAxiosRequestConfig) => {
        const token = localStorage.getItem('token');
        if (token) {
          config.headers.set('Authorization', `Bearer ${token}`); // headers.set 사용
        }
        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
    );
    isAuthInterceptorAdded = true; // 인터셉터 추가 완료
  }
  return client;
};

export { api };
