import { EditableConfig, EditableWrapperProps } from "@/shared/types/common";
import React from "react";
import { usePageStore } from "@/shared/stores/pageStore";



const EditableWrapper = ({ children, isEditMode = false } : EditableWrapperProps) => {
  const setSelectedComponent = usePageStore((state) => state.setSelectedComponent);


  const handleEdit = (config: EditableConfig) => {
    if (!isEditMode) return;

    setSelectedComponent({
      id: config.path,
      type: config.type, 
      path: config.path,
      content: config.content || '',
      styles: config.styles || ''
    });
  };


  const handleClick = (e: React.MouseEvent) => {
    const editable = (e.target as HTMLElement).closest('[data-editable="true"]');
    if (!editable || !isEditMode) return;

    const stylesAttr = editable.getAttribute('data-styles');
    
    handleEdit({
      type: editable.getAttribute('data-type') as EditableConfig['type'],
      path: editable.getAttribute('data-path') || '',
      content: editable.getAttribute('data-content') || '',
      styles: stylesAttr ? JSON.parse(stylesAttr) : {}
    });
  };


  return (
    <div onClick={handleClick}>
      {children}
    </div>
  );
};

export default EditableWrapper;