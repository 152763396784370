import Header from "../admin/Header";

const NotFoundTemplate = () => {  
  return (
    <div className="flex flex-col items-center justify-center bg-white rounded-xl shadow-lg p-8 text-center">
      <h2 className="text-xl font-bold mb-2">템플릿을 선택해주세요</h2>
      <p className="text-gray-600">
        좌측 상단의 템플릿 선택 메뉴에서 편집할 템플릿을 선택해주세요
      </p>
    </div>
  );
};

export default NotFoundTemplate;





