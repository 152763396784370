import { Monitor } from 'lucide-react';

const MobileBlocker = () => {
  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-50 p-4">
      <div className="text-center">
        <Monitor className="w-16 h-16 mx-auto text-gray-400 mb-4" />
        <h1 className="text-xl font-bold text-gray-800 mb-2">
          PC 환경에서 이용해주세요
        </h1>
        <p className="text-gray-600">
          관리자 페이지는 PC에서만 사용 가능합니다
        </p>
      </div>
    </div>
  );
};

export default MobileBlocker;