import { useTemplateStore } from '@/shared/stores/templateStore';
import PageBanner from '@/templates/components/common/PageBanner';
import SectionContainer from '@/templates/components/common/SectionContainer';
import SectionHeader from '@/templates/components/common/SectionHeader';
import Contact02 from '@/templates/components/contact/Contact02';


const ContactHome = () => {
  const templateData = useTemplateStore(state => state.templateData);
  
  return (
    <div>
      <PageBanner
        {...(templateData.pages.contact.common)}
        editablePath="pages.contact.common"
      />
      
      <SectionContainer>
        <SectionHeader
          {...(templateData.pages.contact.home.first)}
          editablePath="pages.contact.home.first"
        />

      <Contact02
        {...(templateData.pages.contact.home.first)}
        editablePath="pages.contact.home.first"
      />
    </SectionContainer>
  </div>
  );
};

export default ContactHome;