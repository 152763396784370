// src/pages/admin/TemplateList.tsx
import Header from '@/builder/components/admin/Header';
import { useTemplateStore } from '@/shared/stores/templateStore';
import TemplateCard from '@/builder/components/templates/TemplateCard';
import NotFoundTemplate from '../../components/templates/NotFoundTemplate';
import { useAuthStore } from '@/builder/stores/authStore';

const TemplateList = () => {
  const selectedTemplateId = useTemplateStore((state) => state.selectedTemplateId);


  if (!selectedTemplateId) {
    return(      
      <div className="space-y-8 max-w-4xl mx-auto">
        <Header
          title="사이트 편집"
          subtitle="디자인이 최적화된 홈페이지를 편집하세요"
        />
        <NotFoundTemplate />
      </div>
    )
  }

  return (
    <div className="space-y-8 max-w-4xl mx-auto">
      <Header
        title="사이트 편집"
        subtitle="디자인이 최적화된 홈페이지를 편집하세요"
      />
      <div className="w-full">
        <TemplateCard templateId={selectedTemplateId} />
      </div>
    </div>
  );
};

export default TemplateList;