import React from 'react';
import EditableWrapper from '@/builder/components/editor/EditableWrapper';
import * as PageComponents from '@/templates/template1/pages';
import { usePageStore } from '@/shared/stores/pageStore';

const PageWrapper = ({ children }: { children: React.ReactNode }) => (
  <EditableWrapper isEditMode={true}>
    <div className="flex flex-col min-h-screen">
      <div className="flex-grow">
        {children}
      </div>
      <PageComponents.Footer />
    </div>
  </EditableWrapper>
);

const PageRenderer = () => {
  const currentPage = usePageStore((state) => state.currentPage);

  const renderPage = () => {
    switch (currentPage) {
      case '':
        return (
          <PageWrapper>
            <PageComponents.Home />
          </PageWrapper>
        );

      case 'about':
        return (
          <PageWrapper>
            <PageComponents.AboutHome />
          </PageWrapper>
        );

      case 'about/team':
        return (
          <PageWrapper>
            <PageComponents.AboutTeam />
          </PageWrapper>
        );

      case 'about/history':
        return (
          <PageWrapper>
            <PageComponents.AboutHistory />
          </PageWrapper>
        );

      case 'about/location':
        return (
          <PageWrapper>
            <PageComponents.AboutLocation />
          </PageWrapper>
        );

      case 'services':
        return (
          <PageWrapper>
            <PageComponents.ServicesHome />
          </PageWrapper>
        );

      case 'products':
        return (
          <PageWrapper>
            <PageComponents.ProductsHome />
          </PageWrapper>
        );

      case 'contact':
        return (
          <PageWrapper>
            <PageComponents.ContactHome />
          </PageWrapper>
        );

      default:
        return <div>페이지를 찾을 수 없습니다.</div>;
    }
  };

  return renderPage();
};

export default PageRenderer;